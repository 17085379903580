import React from "react";
import ModalBody from "./modal-body";
import { AuthenFlow } from "lib/enums";
import { ErrorType } from "features/app/type";
import LoginForm from "components/Form/login-form";
import LoginThirdParty from "components/Form/LoginThirdParty";
import { useTracking } from "context/trackers";
import {useAppSelector} from "../store";
import {IUserInfo, UserType} from "../features/user/type";
import {useRouter} from "next/router";

interface Props {
  handleClose: () => void;
  changeFlow: (toFlow: AuthenFlow) => void;
}

const Login: React.FC<Props> = ({ handleClose, changeFlow }) => {
  const router = useRouter();
  const handleLoginThirdPartyFailure = (type?: string) => {
    if (type && type === ErrorType.ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED) {
      changeFlow(AuthenFlow.REGISTER_STEP_TWO);
    }
  };

  const handleLoginSuccess = (user?: IUserInfo) => {
    handleClose();
    const isKOL = (user?.userRole ?? '') === UserType.KOL;
    if(isKOL){
      router.replace("/quan-ly");
    } else {
      router.replace("/");
    }
  };

  /* Tracking GA modal open */
  const { modalView } = useTracking();
  React.useEffect(() => {
    modalView("/login");
  }, []);
  /* End Tracking GA modal open */

  return (
    <ModalBody
      title="Đăng nhập"
      size="s"
      showCloseIcon
      onClose={handleClose}
      className="bg-white p-5 pt-8"
    >
      <LoginForm onSuccess={handleLoginSuccess} />
      <div
        className="mb-4 text-center text-xs text-grey-80 cursor-pointer hover:text-greyish-brown"
        onClick={() => {
          changeFlow(AuthenFlow.FORGOT_PASSWORD);
        }}
      >
        Quên mật khẩu
      </div>
      <LoginThirdParty
        onLoginSuccess={handleLoginSuccess}
        onLoginFailure={handleLoginThirdPartyFailure}
      />
      <div className="text-center text-xs text-grey-80">
        Bạn chưa có tài khoản?{" "}
        <span
          className="text-medium-blue underline cursor-pointer hover:text-bright-blue"
          onClick={() => changeFlow(AuthenFlow.REGISTER)}
        >
          Đăng ký ngay
        </span>
      </div>
    </ModalBody>
  );
};

export default Login;
