import React from "react";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";
import Select, { Props as SelectProps } from "./select";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props extends Omit<SelectProps, "options"> {
  valueField?: string;
  labelField?: string;
  options: any[];
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface ItemProps extends Omit<MuiMenuItemProps, "button"> {}

const useMenuItemStyle = makeStyles({
  root: {
    position: "relative",
    "&:focus": {
      background: "#f5f7fa",
      "& .MuiListItemIcon-root": {
        color: "#808080",
      },
      "& .MuiListItemText-primary": {
        color: "#808080",
      },
    },
    "&.Mui-selected": {
      background: "#f5f7fa",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
  },
});

const SelectItem = React.forwardRef<HTMLLIElement, ItemProps>(
  ({ ...others }, ref) => {
    const classes = useMenuItemStyle();
    return (
      <MuiMenuItem ref={ref} classes={{ root: classes.root }} {...others} />
    );
  }
);

SelectItem.displayName = "SelectItem";
const MultiSelect: React.FC<Props> = ({
  options,
  multiple,
  value = [],
  onChange,
  labelField = "label",
  valueField = "value",
  ...others
}) => {
  const _optionsPair = React.useMemo<{ [key: string]: any }>(() => {
    const result: { [key: string]: any } = {};
    options.forEach((i) => {
      const value = get(i, `${valueField}`);
      if (value !== undefined) {
        result[value] = i[labelField];
      }
    });
    return result;
  }, [options]);

  const onChangeHandler = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    n: React.ReactNode
  ) => {
    const onChangeInput = get(others, "inputProps.onChange");
    if (onChangeInput && typeof onChangeInput === "function") {
      onChangeInput(event);
    }
    if (onChange && typeof onChange === "function") {
      onChange(event, n);
    }
  };

  return (
    <Select
      multiple
      disableNative
      options={options}
      valueField={valueField}
      labelField={labelField}
      value={value}
      onChange={onChangeHandler}
      renderValue={(selected) =>
        (selected as string[]).map((i) => get(_optionsPair, i)).join(", ")
      }
      renderChild={(option) => {
        return (
          <SelectItem key={option[valueField]} value={option[valueField]}>
            {(value as string[]).indexOf(option[valueField]) > -1 ? (
              <CheckCircleOutlineIcon
                className="mr-2"
                style={{ fontSize: "1rem", color: "#01334c" }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                className="mr-2"
                style={{ fontSize: "1rem", color: "rgba(0,0,0,.15)" }}
              />
            )}
            <span className="text-grey-80 text-sm">{option[labelField]}</span>
            {/* <Checkbox checked={_value.indexOf(option.value) > -1} /> */}
            {/* <ListItemText primary={option.label} /> */}
          </SelectItem>
        );
      }}
      {...others}
    />
  );
};

export default MultiSelect;
