import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Phone: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.531 15.633v3.941a2.017 2.017 0 01-.687 1.531 2.012 2.012 0 01-1.61.473A18.048 18.048 0 013.621 5.898a1.998 1.998 0 01.473-1.609 1.994 1.994 0 011.523-.687h3.88a.998.998 0 011.003.89 10.648 10.648 0 001.004 3.79c.2.468.016 1.011-.422 1.265l-.863.492a1.007 1.007 0 00-.332 1.465 14.191 14.191 0 003.703 3.7c.23.167.523.226.805.163.28-.066.52-.246.66-.496l.488-.863c.27-.446.836-.61 1.305-.38 1.191.563 2.476.903 3.789 1 .511.06.894.49.894 1.005zm0 0"
        fill="none"
        strokeWidth={1.7999999999999998}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </SvgIcon>
  );
};
export default Phone;
