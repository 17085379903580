import React from "react";
import Cropper from "react-cropper";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PhotoCameraIcon from "components/icons/PhotoCamera";
import MuiModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalBody from "components/modal-body";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "cropperjs/dist/cropper.css";
import { uploadFile } from "features/common/api";
import { makeRandomId } from "lib/utils";

interface Props {
  src: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const PreviewImage: React.FC<Props> = ({ src }) => {
  const classes = useStyles();
  const [showPreviewModal, setShowPreviewModal] = React.useState<boolean>(
    false
  );
  const handleClose = () => {
    setShowPreviewModal(false);
  };

  return (
    <>
      <img
        className="rounded-xl cursor-pointer"
        src={src}
        onClick={() => setShowPreviewModal(true)}
      />
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showPreviewModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={showPreviewModal}>
          <ModalBody onClose={handleClose} className="bg-transparent relative">
            <img className="rounded-xl w-full" src={src} />
          </ModalBody>
        </Fade>
      </MuiModal>
    </>
  );
};

export default PreviewImage;
