import { makeStyles } from "@material-ui/core/styles";
const useInputStyles = makeStyles({
  input: {
    color: "#4d4d4d",
    background: "#f5f7fa",
    borderRadius: "0.75rem",
    border: "1px solid transparent",
    fontSize: "0.875rem",
    "& .MuiFilledInput-input": {
      paddingLeft: 20,
    },
    "& .MuiFilledInput-input:-webkit-autofill": {
      borderRadius: "0.75rem",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
    "&:focus": {
      background: "#f5f7fa",
    },
    "&:active": {
      background: "#f5f7fa",
    },
    "&.Mui-focused": {
      background: "#f5f7fa",
    },
    "&.Mui-error": {
      borderColor: "#f53126",
    },
    "&.Mui-disabled": {
      background: "#f5f7fa",
    },
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
  disabled: {
    opacity: "0.5",
    background: "#f5f7fa",
  },
  label: {
    transform: "translate(20px, 20px)",
    fontSize: "0.875rem",
    color: "#808080",
    "&.Mui-focused": {
      color: "#808080",
      transform: "translate(20px, 10px) scale(0.75)",
    },
    "&.MuiInputLabel-filled.MuiInputLabel-shrink": {
      transform: "translate(20px, 10px) scale(0.75)",
    },
    "&.Mui-error": {
      color: "#808080",
    },
  },
  control: {
    width: "100%",
  },
  helperText: {
    position: "absolute",
    bottom: "0.75rem",
    right: "0.75rem",
    marginRight: "0",
    marginLeft: "0",
  },
});

export default useInputStyles;
