import React from "react";
import { isMobile } from "react-device-detect";
import withReduxSaga from "next-redux-saga";
import { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import { v1 as uuid } from "uuid";
import NProgress from "nprogress";
import lottie, { AnimationConfigWithPath } from "lottie-web";
import { useAppDispatch, useAppSelector, wrapper } from "store";
import isEmpty from "lodash/isEmpty";
import ThemeProvider from "context/ThemeProvider";
import { TrackingProvider } from "context/trackers";
import {
  appInit,
  appSetDevice,
  appSetInitialHistoryPoint,
  setPopstate,
} from "features/app/appSlice";
import { getComponentDisplayName, getDeviceID } from "lib/common";
import DefaultLayout from "layout/defaultLayout";
import { sendWebVitals } from "lib/analytics/ga";
import { NextWebVitalsMetrics } from "../types/nextjs/NextWebVitalsMetrics";
import { NextWebVitalsMetricsReport } from "../types/nextjs/NextWebVitalsMetricsReport";
import { NextSeo, DefaultSeo } from "next-seo";
import Meta from "components/meta";
import SeoStructure from "components/seo-structure";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "nprogress/nprogress.css";
import "../../styles/index.css";

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 800,
  showSpinner: false,
});

/**
 * Global variable meant to keep all metrics together, until there are enough to send them in batch as a single report
 */
const globalWebVitalsMetric: NextWebVitalsMetricsReport = {
  reportId: uuid(),
  metrics: {},
  reportedCount: 0,
};

/**
 * Will be called once for every metric that has to be reported.
 *
 * There are, at minimum, 3 metrics being received (Next.js-hydration, FCP and TTFB)
 * Then, 2 other metrics can be received optionally (FID, LCP)
 *
 * @param metrics
 * @see https://web.dev/vitals/ Essential metrics for a healthy site
 * @see https://nextjs.org/blog/next-9-4#integrated-web-vitals-reporting Initial release notes
 */

export function reportWebVitals(metrics: NextWebVitalsMetrics): void {
  const { name } = metrics;
  const count = globalWebVitalsMetric.reportedCount;
  globalWebVitalsMetric.metrics[
    name as keyof NextWebVitalsMetricsReport["metrics"]
  ] = metrics;
  const keysLength = Object.keys(globalWebVitalsMetric.metrics).length;

  // Temporise analytics API calls by waiting for at least 5 metrics to be received before sending the first report
  // (because 3 metrics will be received upon initial page load, and then 2 more upon first click)
  // Then, send report every 2 metrics (because each client-side redirection will generate 2 metrics)
  if ((count === 0 && keysLength === 5) || (count > 0 && keysLength === 2)) {
    sendWebVitals(globalWebVitalsMetric);

    // Reset and prepare next metrics to be reported
    globalWebVitalsMetric.metrics = {};
    globalWebVitalsMetric.reportedCount++;
  }
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  Component.displayName = `WithMyApp(${getComponentDisplayName(
    Component as React.FC
  )})`;
  const [isCompletedLottie, setIsCompletedLottie] = React.useState(false);
  const lottieRef = React.useRef<HTMLDivElement>(null);
  const isLoaded = useAppSelector((state) => state.app.isLoaded);

  React.useEffect(() => {
    const isDisableLottie =
      !!sessionStorage.getItem("isDisableLottie") ||
      !!localStorage.getItem("isDisableLottie") ||
      isMobile;
    if (lottieRef.current && !isDisableLottie) {
      const options: AnimationConfigWithPath = {
        container: lottieRef.current,
        path: "https://assets8.lottiefiles.com/packages/lf20_4utw6nh1.json", // required
        renderer: "svg",
        loop: false,
        autoplay: true,
        name: "Preloader",
      };
      document.body.style.width = "100vw";
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
      const lottieItem = lottie.loadAnimation(options);
      const lottieComplete = () => {
        document.body.style.width = "";
        document.body.style.height = "";
        document.body.style.overflow = "";
        setIsCompletedLottie(true);
      };
      lottieItem.addEventListener("complete", lottieComplete);
      lottieItem.addEventListener("data_failed", lottieComplete);
    } else {
      setIsCompletedLottie(true);
      sessionStorage.removeItem("isDisableLottie");
    }
  }, []);

  React.useEffect(() => {
    dispatch(appInit());
    dispatch(appSetDevice(getDeviceID()));
    dispatch(appSetInitialHistoryPoint(window.history.length));
  }, []);

  React.useEffect(() => {
    const start = () => {
      NProgress.start();
    };
    const end = () => {
      NProgress.done();
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  /* Detect popstate (when history.back and when not) */
  const onHistoryPopFlag = React.useRef<boolean>(false);
  React.useEffect(() => {
    const popState = () => {
      onHistoryPopFlag.current = true;
    };
    const popStateSetter = () => {
      if (onHistoryPopFlag.current) {
        dispatch(setPopstate(true));
      } else {
        dispatch(setPopstate(false));
      }
      onHistoryPopFlag.current = false;
    };

    window.addEventListener("popstate", popState);
    Router.events.on("routeChangeStart", popStateSetter);
    return () => {
      Router.events.off("routeChangeStart", popStateSetter);
      window.removeEventListener("popstate", popState);
    };
  }, []);
  /* End detect popstate */

  React.useEffect(() => {
    // Fix bug nextjs back to home not render page
    window.addEventListener(
      "popstate",
      function (event) {
        if (isEmpty(event.state)) {
          router.replace(router.asPath);
        }
      },
      false
    );

    if (isMobile) {
      try {
        screen?.orientation?.lock("portrait").then(
          (success) => console.log(success),
          (failure) => console.log(failure)
        );
      } catch (err) {} // eslint-disable-line
    }
  }, []);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  React.useEffect(() => {
    if (!(isCompletedLottie && isLoaded)) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [isCompletedLottie, isLoaded]);

  const seo = useAppSelector((state) => state.seo);
  const isReady = isCompletedLottie && isLoaded;
  return (
    <>
      <Meta />
      <DefaultSeo
        facebook={{
          appId: "582794449350879",
        }}
      />
      <NextSeo
        title={seo.title}
        description={seo.description}
        canonical={seo.canonical}
        openGraph={seo.openGraph}
      />
      <SeoStructure />
      {!isReady && (
        <div
          className={
            "w-screen h-screen absolute top-0 left-0 items-center z-max bg-white"
          }
        >
          <div
            className="absolute top-1/2 left-1/2 h-1/5 w-auto transform -translate-x-1/2 -translate-y-3/5"
            ref={lottieRef}
          />
        </div>
      )}
      {(isReady || router.asPath === "/") && (
        <ThemeProvider>
          <TrackingProvider>
            <DefaultLayout>
              <Component {...pageProps} isReady={isReady} />
            </DefaultLayout>
          </TrackingProvider>
        </ThemeProvider>
      )}
    </>
  );
};

export default wrapper.withRedux(withReduxSaga(MyApp));
