import React from "react";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import Image from "next/image";
import classNames from "classnames";
import debounce from "lodash/debounce";
import MuiInputBase, {
  InputBaseProps as MuiInputBaseProps,
} from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Skeleton from "@material-ui/lab/Skeleton";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { IProfile } from "features/profile/type";
import { searchProfile } from "features/profile/api";
import ProfileModal from "components/profile-modal";
import { useAppDispatch } from "store";
import { appSearch } from "features/app/appSlice";

/* eslint-disable @typescript-eslint/no-empty-interface */
interface Props extends MuiInputBaseProps {
  className?: string;
}

const useStyles = makeStyles({
  inputRounded: {
    borderRadius: "0.75rem",
  },
  input: {
    border: "1px solid transparent",
    color: "#4d4d4d",
    background: "transparent",
    fontSize: "0.875rem",
    height: "2.5rem",
    "& .MuiFilledInput-input": {
      paddingLeft: 20,
    },
    "&:hover": {
      background: "transparent",
    },
    "&:focus": {
      background: "transparent",
    },
    "&:acitve": {
      background: "transparent",
    },
    "&.Mui-focused": {
      background: "transparent",
    },
    "&.Mui-disabled": {
      background: "transparent",
    },
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
  disabled: {
    opacity: "0.5",
    background: "#f5f7fa",
  },
  select: {
    "&:hover": {
      background: "#f5f7fa",
      borderRadius: "0.75rem",
    },
    "&:focus": {
      background: "#f5f7fa",
      borderRadius: "0.75rem",
    },
  },
  searchProgress: {
    color: "#01334c",
    position: "absolute",
  },
  menuPaper: {
    maxHeight: "12rem",
    marginTop: "0.25rem",
    borderRadius: "0.75rem",
    width: "29rem",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4rem",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
  menuItemRoot: {
    fontSize: "0.875rem",
    padding: "0.25rem 3rem",
    height: "3rem",
    "&:focus": {
      background: "#f5f7fa",
      "& .MuiListItemIcon-root": {
        color: "#808080",
      },
      "& .MuiListItemText-primary": {
        color: "#808080",
      },
    },
    "&.Mui-selected": {
      background: "#f5f7fa",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
  },
});

const SearchBox: React.FC<Props> = ({ className, ...otherProps }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const routeRef = React.useRef<string>(router.route);
  React.useEffect(() => {
    routeRef.current = router.route;
  }, [router.route]);
  /* Search function */
  const classes = useStyles();
  const searchDebouncing = React.useCallback(
    debounce(() => {
      if (inputRef.current && !inputRef.current.value) {
        clearSearchResult();
        dispatch(appSearch({ searchKey: "" }));
        return;
      }
      if (inputRef.current && inputRef.current.value) {
        if (routeRef.current !== "/tim-kiem") {
          fetchSearch(inputRef.current.value);
        }
        dispatch(appSearch({ searchKey: inputRef.current.value }));
      }
    }, 500),
    []
  );

  const redirectToSearchPage = React.useCallback(() => {
    if (inputRef.current && inputRef.current.value) {
      router.push({
        pathname: "/tim-kiem",
        query: {
          q: inputRef.current.value,
        },
      });
    }
  }, [router]);

  const onClickShowAllResult = React.useCallback(() => {
    if (
      inputRef.current &&
      inputRef.current.value &&
      routeRef.current !== "/tim-kiem"
    ) {
      clearSearchResult();
      redirectToSearchPage();
    }
  }, [router.route]);

  const inputOnKeyDown: React.KeyboardEventHandler = React.useCallback((e) => {
    switch (e.key) {
      case "Enter":
        if (
          inputRef.current &&
          inputRef.current.value &&
          router.route !== "/tim-kiem"
        ) {
          clearSearchResult();
          redirectToSearchPage();
          searchDebouncing.cancel();
          dispatch(appSearch({ searchKey: inputRef.current.value }));
        }
        break;
      default:
        searchDebouncing();
        break;
    }
  }, []);

  /* Mobile handler function */
  const mobileSearchDebouncing = React.useCallback(
    debounce(() => {
      if (inputRef.current && !inputRef.current.value) {
        clearSearchResult();
        dispatch(appSearch({ searchKey: "" }));
        return;
      }
      if (inputRef.current && inputRef.current.value) {
        dispatch(appSearch({ searchKey: inputRef.current.value }));
        mobileRedirectToSearchPage();
      }
    }, 500),
    []
  );

  const mobileRedirectToSearchPage = React.useCallback(() => {
    const needRedirect = ["/social/[type]", "/"].indexOf(routeRef.current) < 0;
    if (!needRedirect) return;
    if (inputRef.current && inputRef.current.value) {
      router.push({
        pathname: "/",
      });
    }
  }, [router]);

  const mobileInputOnKeyDown: React.KeyboardEventHandler = React.useCallback(
    (e) => {
      switch (e.key) {
        case "Enter":
          if (inputRef.current && inputRef.current.value) {
            clearSearchResult();
            mobileRedirectToSearchPage();
            mobileSearchDebouncing.cancel();
            dispatch(appSearch({ searchKey: inputRef.current.value }));
          }
          break;
        default:
          mobileSearchDebouncing();
          break;
      }
    },
    []
  );
  /* End Mobile handler function */

  /* Search result function */
  const [isShowSearchLoading, setIsShowSearchLoading] = React.useState<boolean>(
    false
  );
  const [isShowSearchResult, setIsShowSearchResult] = React.useState<boolean>(
    false
  );
  const [searchResults, setSearchResults] = React.useState<IProfile[]>([]);
  const [searchResultsFor, setSearchResultsFor] = React.useState<string>("");
  const handleClose = (e: React.MouseEvent<Document | HTMLLIElement>) => {
    if (e.target !== inputRef.current) {
      setIsShowSearchResult(false);
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsShowSearchResult(false);
    }
  };

  const clearSearchResult = React.useCallback(() => {
    setIsShowSearchLoading(false);
    setIsShowSearchResult(false);
  }, []);

  const fetchSearch = React.useCallback((keyword: string) => {
    setIsShowSearchLoading(true);
    setIsShowSearchResult(true);
    searchProfile(keyword)
      .then(({ profiles }) => {
        setIsShowSearchLoading(false);
        setSearchResults(profiles.map((i) => new IProfile(i)));
        setSearchResultsFor(keyword);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Show profile modal
  const [showProfileModal, setShowProfileModal] = React.useState<boolean>(
    false
  );
  const [profile, setProfile] = React.useState<IProfile | undefined>();
  const handleItemClick = React.useCallback((i: IProfile) => {
    setProfile(i);
    setShowProfileModal(true);
  }, []);

  return (
    <div
      ref={anchorRef}
      className={
        className ??
        "w-116 h-10 bg-pale-grey rounded-xl px-4 py-2 flex justify-between items-center"
      }
    >
      {/* <MuiInputBase */}
      {/*   autoComplete={"off"} */}
      {/*   inputRef={inputRef} */}
      {/*   onKeyDown={inputOnKeyDown} */}
      {/*   id="component-filled" */}
      {/*   fullWidth={true} */}
      {/*   classes={{ */}
      {/*     root: classNames(classes.input, classes.inputRounded), */}
      {/*     disabled: classes.disabled, */}
      {/*   }} */}
      {/*   startAdornment={ */}
      {/*     <div className="mr-2 w-5 h-5 mt-1"> */}
      {/*       <Image */}
      {/*         src="/assets/sidemenu-logo/search-icon.svg" */}
      {/*         alt="search icon" */}
      {/*         height={20} */}
      {/*         width={20} */}
      {/*       /> */}
      {/*     </div> */}
      {/*   } */}
      {/*   endAdornment={ */}
      {/*     <Select */}
      {/*       classes={{ */}
      {/*         select: classes.select, */}
      {/*       }} */}
      {/*       options={SEARCH_PLATFORM_OPTIONS} */}
      {/*       onChange={searchDebouncing} */}
      {/*       input={ */}
      {/*         <MuiInputBase */}
      {/*           classes={{ */}
      {/*             root: classes.input, */}
      {/*           }} */}
      {/*           inputRef={platformInputRef} */}
      {/*         /> */}
      {/*       } */}
      {/*       width="9.125rem" */}
      {/*       height="14rem" */}
      {/*       displayEmpty={true} */}
      {/*       defaultValue="all" */}
      {/*     /> */}
      {/*   } */}
      {/*   {...otherProps} */}
      {/* /> */}
      <MuiInputBase
        autoComplete={"off"}
        placeholder="Nhập tên người ảnh hưởng muốn tìm..."
        inputRef={inputRef}
        onKeyDown={isMobile ? mobileInputOnKeyDown : inputOnKeyDown}
        id="component-filled"
        fullWidth={true}
        classes={{
          root: classNames(classes.input, classes.inputRounded),
          disabled: classes.disabled,
        }}
        startAdornment={
          <div className="mr-2 w-5 h-5 mt-1">
            <Image
              src="/assets/sidemenu-logo/search-icon.svg"
              alt="search icon"
              height={20}
              width={20}
            />
          </div>
        }
        {...otherProps}
      />
      {!isMobile && (
        <Popper
          style={{ zIndex: 99 }}
          open={isShowSearchResult}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "top left" }}>
              <Paper classes={{ root: classes.menuPaper }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <MenuList
                      autoFocusItem={isShowSearchResult}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {isShowSearchLoading ? (
                        [1, 2, 3].map((i) => {
                          return (
                            <MenuItem
                              key={i}
                              classes={{ root: classes.menuItemRoot }}
                            >
                              <div className="flex flex-row w-full">
                                <div className="rounded overflow-hidden w-10 h-10 mr-2">
                                  <Skeleton
                                    variant="rect"
                                    width={"3rem"}
                                    height={"3rem"}
                                  />
                                </div>
                                <div className="flex flex-col flex-grow">
                                  <div className="font-medium text-black w-full">
                                    <Skeleton variant="text" />
                                  </div>
                                  <div className="text-xs text-grey-80 w-full">
                                    <Skeleton variant="text" />
                                  </div>
                                </div>
                              </div>
                            </MenuItem>
                          );
                        })
                      ) : searchResults.length > 0 ? (
                        searchResults.map((i) => {
                          return (
                            <MenuItem
                              classes={{ root: classes.menuItemRoot }}
                              onClick={handleItemClick.bind(null, i)}
                              key={i.id}
                            >
                              <div className="flex flex-row">
                                <div className="rounded overflow-hidden w-10 h-10 mr-2">
                                  <img
                                    className="w-full h-auto"
                                    src={i.avatar}
                                  />
                                </div>
                                <div className="flex flex-col">
                                  <div className="font-medium text-black">
                                    {i.fullName}
                                  </div>
                                  <div className="text-xs text-grey-80">
                                    {i.job.join(" | ")}
                                  </div>
                                </div>
                              </div>
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem>
                          <div className="flex justify-center items-center">
                            <HighlightOff
                              style={{
                                fontSize: "1.25rem",
                                marginRight: "0.25rem",
                                color: "#f53126",
                              }}
                            />
                            Không có kết quả tìm kiếm
                          </div>
                        </MenuItem>
                      )}
                    </MenuList>
                    {isShowSearchLoading ? (
                      <div className="mt-2 mb-2 px-12 text-xs text-greyish">
                        <Skeleton variant="text" />
                      </div>
                    ) : (
                      <div className="mt-2 mb-2 px-12 text-xs text-greyish">
                        Hiển thị{" "}
                        <span
                          className="text-medium-blue underline cursor-pointer"
                          onClick={onClickShowAllResult}
                        >
                          tất cả kết quả
                        </span>{" "}
                        có từ khóa &ldquo;{searchResultsFor}&ldquo;
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      <ProfileModal
        profile={profile}
        isShow={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
      />
    </div>
  );
};

export default SearchBox;
