import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ProfileActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        transform="translate(4 4)"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={7.847} cy={3.923} r={3.923} stroke="#01334c" />
        <path
          stroke="gray"
          d="M4.376 10.79h6.942c1.427 0 2.015.162 2.585.467.57.305 1.018.752 1.323 1.323.305.57.467 1.158.467 2.585V16h0H0v-.835c0-1.427.162-2.015.467-2.585a3.186 3.186 0 011.324-1.323c.57-.305 1.158-.468 2.585-.468z"
        />
      </g>
    </SvgIcon>
  );
};
export default ProfileActive;
