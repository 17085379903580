import React from "react";
import {LOGIN_METHODS} from "lib/constants";
import Button from "components/button";
import {userLoginThirdParty} from "features/user/api";
import {IUserInfo} from "features/user/type";
import AlertModal from "../Dialog/AlertModal";
import {updateUserInfo, userAccessToken} from "../../features/user/userSlice";
import {useAppDispatch} from "../../store";
import {ErrorType} from "../../features/app/type";
import {get} from "lodash";

export interface Props {
  onLoginSuccess?: (user?: IUserInfo) => void;
  onLoginFailure?: (type?: string) => void;
}

const LoginThirdParty: React.FC<Props> = ({onLoginSuccess, onLoginFailure,}) => {
  const dispatch = useAppDispatch();
  const handleLoginThirdParty = (item: typeof LOGIN_METHODS[0]) => {
    //LOGIN WITH REDIRECT
    // userLoginThirdPartyWithRedirect(item.method).catch(err => {
    //   console.error("ERROR userLoginThirdPartyWithRedirect", err);
    //   const {close} = AlertModal({
    //     title: "Thất bại!",
    //     description: "Đăng nhập không thành công. Vui lòng thử lại!",
    //     type: "error",
    //     bottomText: "Thử lại",
    //     onBottomClick: () => {
    //       close();
    //     },
    //   });
    // });
    // LOGIN POPUP
    userLoginThirdParty(item.method)
      .then((res) => {
        const {token, user} = res;
        dispatch(userAccessToken(token));
        dispatch(updateUserInfo(new IUserInfo(user)));
        if (onLoginSuccess) {
          onLoginSuccess(new IUserInfo(user));
        }
      })
      .catch((err) => {
        if (err?.code == ErrorType.ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED) {
          if (onLoginFailure) {
            onLoginFailure(ErrorType.ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED);
          }
          // changeFlow(AuthenFlow.REGISTER_STEP_TWO);
        } else {
          const {close} = AlertModal({
            title: "Thất bại!",
            description: "Đăng nhập không thành công. Vui lòng thử lại!",
            type: "error",
            bottomText: "Thử lại",
            onBottomClick: () => {
              close();
            },
          });
        }
      });
  };
  return (
    <>
      {LOGIN_METHODS.filter(i => !get(i, 'isDisabled', true)).map((i) => (
        <div className="mb-3" key={i.method}>
          <Button infType="secondary" onClick={() => handleLoginThirdParty(i)}>
            <div className="w-full relative">
              <img
                className="absolute left-2 top-1/2 transform -translate-y-1/2"
                width={20}
                height={20}
                src={i.icon}
                alt=""
              />
              <div className="flex-grow font-normal">{i.label}</div>
            </div>
          </Button>
        </div>
      ))}
    </>
  );
};

export default LoginThirdParty;

