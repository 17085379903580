import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab, { TabProps as MuiTabProps } from "@material-ui/core/Tab";
import classNames from "classnames";

const useTabStyles = makeStyles({
  root: {
    minWidth: "fit-content",
    minHeight: "fit-content",
    maxWidth: "none",
    height: "2.375rem",
    marginRight: "0.625rem",
    fontSize: "0.875rem",
    lineHeight: "1",
    padding: "0.75rem",
    borderRadius: "0.75rem",
    "&:focus": {
      outline: "none",
    },
  },
  textColorPrimary: {
    color: "#4d4d4d",
    background: "#f5f7fa",
    textTransform: "none",
    "&.Mui-selected": {
      color: "#fff",
      background: "#01334c",
      "&:hover": {
        background: "#012435",
      },
    },
    "&:hover": {
      background: "#c4c6c8",
    },
  },
  textColorPrimaryMobileProfileEdit: {
    "&.Mui-selected": {
      color: "#01334C",
      background: "#e3e6ea",
      "&:hover": {
        background: "#e3e6ea",
      },
    },
  },
});

interface TabProps extends MuiTabProps {
  type?: string;
}

export const Tab: React.FC<TabProps> = ({ type = "", ...others }) => {
  const classes = useTabStyles();
  return (
    <MuiTab
      classes={{
        root: classes.root,
        textColorPrimary: classnames(
          classes.textColorPrimary,
          type === "mobile-profile-edit"
            ? classes.textColorPrimaryMobileProfileEdit
            : ""
        ),
      }}
      {...others}
    />
  );
};

export interface Props {
  value: unknown;
  className?: string;
  tabAlignment?: "left" | "right" | "center";
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: unknown
  ) => void;
}

const useTabsStyles = makeStyles({
  root: {
    minHeight: "fit-content",
    position: "relative",
    "& .MuiTabs-scrollButtons:first-child": {
      left: 0,
      background: "linear-gradient(to left, rgba(255,255,255,0) 0%, #fff 59%)",
      justifyContent: "flex-start",
    },
    "& .MuiTabs-scrollButtons:last-child": {
      right: 0,
      background: "linear-gradient(to right, rgba(255,255,255,0) 0%, #fff 59%)",
      justifyContent: "flex-end",
    },
    "&.tab-left .MuiTabs-scroller>div[role='tablist']": {
      justifyContent: "flex-start",
    },
    "&.tab-center .MuiTabs-scroller>div[role='tablist']": {
      justifyContent: "center",
    },
    "&.tab-right .MuiTabs-scroller>div[role='tablist']": {
      justifyContent: "flex-end",
    },
  },
  scrollButtons: {
    minWidth: "fit-content",
    minHeight: "fit-content",
    maxWidth: "none",
    height: "2.375rem",
    width: "4rem",
    fontSize: "0.875rem",
    lineHeight: "1",
    padding: "0.75rem",
    position: "absolute",
    zIndex: 10,
    color: "#01334c",
    opacity: 1,
  },
});

const Tabs: React.FC<Props> = ({
  value,
  onChange,
  children,
  className,
  tabAlignment = "left",
}) => {
  const classes = useTabsStyles();
  return (
    <MuiTabs
      value={value}
      onChange={onChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
      classes={{
        root: classes.root,
        scrollButtons: classes.scrollButtons,
      }}
      className={classNames(className, `tab-${tabAlignment}`)}
    >
      {children}
    </MuiTabs>
  );
};

export default Tabs;
