import get from "lodash/get";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, IUserInfo, UserType } from "features/user/type";
import { USER_MENU, TOP_MENU, TOP_MOBILE_MENU } from "lib/constants";

const initialState: IUser = {
  isLoggedIn: false,
  errorMsg: "",
  errorCode: 0,
  accessToken: "",
  firebaseToken: "",
  userMenu: [],
  topMenu: [],
  topMobileMenu: [],
  user: {} as IUserInfo,
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    userLoginSuccess: (state) => {
      state.isLoggedIn = true;
    },
    userLoginFail: (state, action?: PayloadAction<string>) => {
      state.isLoggedIn = false;
      state.errorMsg = action?.payload ?? "";
      state.user = null;
    },
    userUpdateFirebaseToken: (state, action?: PayloadAction<string>) => {
      state.firebaseToken = action?.payload ?? "";
    },
    userAccessToken: (state, action?: PayloadAction<string>) => {
      state.accessToken = action?.payload ?? "";
    },
    updateUserInfo: (
      state,
      action: PayloadAction<IUserInfo | null | undefined>
    ) => {
      state.isLoggedIn = !!action.payload;
      state.user = action.payload;
      const userRole = get(action, "payload.userRole", "");
      if (userRole === UserType.KOL) {
        state.userMenu = [...USER_MENU];
        state.topMenu = [...TOP_MENU];
        state.topMobileMenu = [...TOP_MOBILE_MENU];
      } else {
        state.userMenu = USER_MENU.filter((i) => i.path !== "/quan-ly");
        state.topMenu = TOP_MENU.filter((i) => i.action !== "profile");
        state.topMobileMenu = TOP_MOBILE_MENU.filter(
          (i) => i.action !== "profile"
        );
      }
    },
    updateFirebaseProvider: (state, action: PayloadAction<string>) => {
      state.firebaseProvider = action.payload;
    },
    userSignOut: (state) => {
      state.isLoggedIn = false;
      state.errorMsg = "";
      state.user = null;
      state.accessToken = "";
      state.firebaseToken = "";
      state.userMenu = [];
    },
  },
  extraReducers: {},
});
export const {
  updateUserInfo,
  userLoginSuccess,
  userLoginFail,
  userUpdateFirebaseToken,
  userAccessToken,
  updateFirebaseProvider,
  userSignOut,
} = users.actions;
export default users.reducer;
