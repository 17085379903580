import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Close: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.834 15.834L4.167 4.167m11.667 0L4.167 15.834"
        stroke="currentColor"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default Close;
