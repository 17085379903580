import React from "react";
import Head from "next/head";

const devMode = process.env.NODE_ENV === "development";
const Meta = () => {
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      {!devMode && (
        <meta
          name="google-site-verification"
          content="g0OjHk9z2M40cdW7rFRoRL4wYaREbEzDCia_YMmDlLg"
        />
      )}
    </Head>
  );
};

export default Meta;
