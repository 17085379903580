import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Close: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M15.954 16C17.31 16 18 15.346 18 14.05V7.46c0-1.295-.69-1.943-2.046-1.943h-1.863c-.514 0-.67-.101-.964-.416l-.521-.566C12.28 4.189 11.948 4 11.276 4H8.679c-.671 0-1.004.189-1.33.535l-.52.566c-.294.309-.457.416-.965.416H4.046C2.684 5.517 2 6.165 2 7.46v6.588C2 15.346 2.684 16 4.046 16h11.908zM10 14.144c-1.974 0-3.557-1.523-3.557-3.442 0-1.913 1.583-3.436 3.557-3.436s3.55 1.523 3.55 3.436c0 1.919-1.583 3.442-3.55 3.442zm4.834-4.946c-.456 0-.847-.359-.847-.812 0-.428.39-.806.847-.806.45 0 .834.378.834.806 0 .447-.385.805-.834.812zM10 13.188c1.42 0 2.567-1.102 2.567-2.486 0-1.378-1.147-2.486-2.567-2.486-1.42 0-2.573 1.108-2.573 2.486 0 1.384 1.16 2.485 2.573 2.485z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};
export default Close;
