import { ISeo } from "./type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  WEB_NAME,
  DOMAIN,
  DEFAULT_SEO_TITLE,
  DEFAULT_SEO_DESCRIPTION,
  DEFAULT_SEO_IMAGE,
} from "lib/constants";

const initialState: ISeo = {
  title: DEFAULT_SEO_TITLE,
  description: DEFAULT_SEO_DESCRIPTION,
  canonical: `https://${DOMAIN}`,
  openGraph: {
    url: `https://${DOMAIN}`,
    title: DEFAULT_SEO_TITLE,
    description: DEFAULT_SEO_DESCRIPTION,
    images: DEFAULT_SEO_IMAGE,
    site_name: WEB_NAME,
    type: "website",
  },
};

export const seo = createSlice({
  name: "seo",
  initialState,
  reducers: {
    updateSeoData: (state, action: PayloadAction<ISeo>) => {
      state.title = action.payload.title || "";
      state.description = action.payload.description || "";
      state.openGraph = action.payload.openGraph;
      state.canonical = action.payload.canonical || "";
    },
    updateDefault: (
      state,
      action: PayloadAction<{ hideCanonical?: boolean }>
    ) => {
      if (!action.payload.hideCanonical) {
        state.canonical = `https://${DOMAIN}`;
      }
      state.title = DEFAULT_SEO_TITLE;
      state.description = DEFAULT_SEO_DESCRIPTION;
      state.openGraph = {
        url: `https://${DOMAIN}`,
        title: DEFAULT_SEO_TITLE,
        description: DEFAULT_SEO_DESCRIPTION,
        images: DEFAULT_SEO_IMAGE,
        site_name: WEB_NAME,
        type: "website",
      };
    },
  },
});

export const { updateSeoData, updateDefault } = seo.actions;
export default seo.reducer;
