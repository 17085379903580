import React from "react";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    width: "100%",
    height: "calc(100vh - 3rem - 4rem)",
    marginTop: "4rem",
    marginBottom: "3rem",
    background: "#fff",
    zIndex: 30,
    overflowY: "scroll",
  },
});
interface Props {
  isOpen: boolean;
}
const MobileMenu: React.FC<Props> = ({ isOpen, children }) => {
  const classes = useStyles();
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [isOpen]);
  return (
    <Fade in={isOpen}>
      <Paper
        classes={{
          root: classes.root,
        }}
      >
        {children}
      </Paper>
    </Fade>
  );
};

export default MobileMenu;
