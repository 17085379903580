import { ErrorType } from "features/app/type";
import React, { useEffect } from "react";
import { MenuProps as MuiMenuProps } from "@material-ui/core/Menu";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Input from "components/input";
import get from "lodash/get";
import CountdownButton, { CountState } from "components/countdown-button";
import Button from "components/button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formatPhoneNumber, PHONE_REGEX } from "lib/common";
import { userCheckPhoneExists, sendOTP, verifyOTP } from "features/user/api";
import AlertModal from "components/Dialog/AlertModal";
import classNames from "classnames";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props {
  type: "checkPhoneExits" | "checkPhoneNoExits" | "";
  onSubmitData: (data: SendOtpFormValues) => void;
  submitText?: string;
  className?: string;
}

export type SendOtpFormValues = {
  phone: string;
  otp: string;
};

const SendOtpFormSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(PHONE_REGEX, "Vui lòng nhập số điện thoại")
    .required("Vui lòng điền số điện thoại của bạn"),
  otp: yup
    .string()
    .min(6, "Mã OTP gồm 6 ký tự")
    .max(6, "Mã OTP gồm 6 ký tự")
    .required("Vui lòng điền OTP"),
});

const SendOtpForm: React.FC<Props> = ({
  submitText = "Tiếp tục",
  type = "",
  onSubmitData,
  className,
}) => {
  const [countState, setCountState] = React.useState<CountState>(
    CountState.INIT
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [phoneExist, setPhoneExist] = React.useState(new Set());
  // useEffect(() => {
  //   recaptchaVerifierInvisible("btn-submit-otp").then(() => {
  //     console.log("Init Login OTP Success");
  //   });
  // }, []);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SendOtpFormValues>({
    mode: "onSubmit",
    resolver: yupResolver(SendOtpFormSchema),
  });
  const watchPhone = watch("phone", ""); // you can supply default value as second argument
  const onSubmit: SubmitHandler<SendOtpFormValues> = (data) => {
    setIsLoading(true);
    verifyOTP(data.phone, "RESET_PASS", data.otp)
      .then((resp) => {
        setIsLoading(false);
        onSubmitData({ ...data });
      })
      .catch((error) => {
        console.log(error);
        const { close } = AlertModal({
          title: "Xác thực thất bại!",
          description: error.messageShow || "Lỗi không xác định",
          type: "error",
          bottomText: "Quay lại",
          onBottomClick: () => {
            close();
          },
        });
        setIsLoading(false);
      });
  };

  const handleClickSendOtp = () => {
    if (PHONE_REGEX.test(watchPhone)) {
      setCountState(CountState.START);
      const phoneNumber = formatPhoneNumber(watchPhone);
      (async () => {
        try {
          let isNextStep = true;
          if (type !== "") {
            const { is_existed: isExists } = await userCheckPhoneExists(
              phoneNumber
            );
            if (type === "checkPhoneExits" && isExists) {
              const { close } = AlertModal({
                title: "Tài khoản đã tồn tại!",
                description:
                  "Tài khoản sử dụng số điện thoại trên đã tồn tại.\n" +
                  "Vui lòng sử dụng số điện thoại khác.",
                type: "error",
                bottomText: "Quay lại",
                onBottomClick: () => {
                  close();
                },
              });
              isNextStep = false;
            }
            if (type === "checkPhoneNoExits" && !isExists) {
              const { close } = AlertModal({
                title: "Tài khoản không tồn tại!",
                description:
                  "Không tồn tại tài khoản nào trên số điện thoại này.\n",
                type: "error",
                bottomText: "Quay lại",
                onBottomClick: () => {
                  close();
                },
              });
              isNextStep = false;
            }
          }

          if (!isNextStep) {
            setCountState(CountState.RELOAD);
            setPhoneExist(phoneExist.add(watchPhone));
          } else {
            await sendOTP(phoneNumber, "RESET_PASS");
          }
        } catch (err) {
          //TODO: handle send sms error
          console.log("Send SMS Error: ", err);
          setCountState(CountState.RELOAD);
          if (err.code === ErrorType.LIMIT) {
            const { close } = AlertModal({
              title: "Lỗi!",
              description:
                "Vượt quá giới hạn gửi SMS. Vui lòng thử lại sau 10 phút.",
              type: "error",
              bottomText: "Đã hiểu",
              onBottomClick: () => {
                close();
              },
            });
          } else {
            const { close } = AlertModal({
              title: "Lỗi!",
              description: "Có lổi xảy ra. Vui lòng thử lại.",
              type: "error",
              bottomText: "Thử lại",
              onBottomClick: () => {
                close();
              },
            });
          }
        }
      })();
    } else {
      const { close } = AlertModal({
        title: "Lỗi!",
        description: "Vui lòng nhập đúng định dạng số điện thoại",
        type: "error",
        bottomText: "Thử lại",
        onBottomClick: () => {
          close();
        },
      });
    }
  };
  const onChangeCountState = (state: CountState) => {
    setCountState(state);
  };
  return (
    <form className={classNames(className)} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...others } }) => (
          <Input
            label="Số điện thoại"
            className="w-full mb-3"
            errorMsg={get(errors, "phone.message", "")}
            inputRef={ref}
            {...others}
          />
        )}
      />
      <Controller
        name="otp"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...others } }) => (
          <Input
            label="OTP"
            className="w-full col-span-2"
            errorMsg={get(errors, "otp.message", "")}
            inputRef={ref}
            endAdornment={
              <div className="w-52">
                <CountdownButton
                  id="btn-submit-otp"
                  infType="tertiary"
                  countFrom={120}
                  completeText="Gửi lại mã"
                  countState={countState}
                  onChangeCountState={onChangeCountState}
                  onClick={handleClickSendOtp}
                >
                  Gửi mã
                </CountdownButton>
              </div>
            }
            {...others}
          />
        )}
      />
      <div className="mt-4">
        <Button type="submit" loading={isLoading}>
          {submitText}
        </Button>
      </div>
    </form>
  );
};

export default SendOtpForm;
