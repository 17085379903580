import React from "react";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import ClearIcon from "@material-ui/icons/Clear";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { isMobile } from "react-device-detect";

type ModalSize = "s" | "m" | "l";
interface Props {
  title?: string;
  size?: ModalSize;
  scrollable?: boolean;
  showCloseIcon?: boolean;
  showBackIcon?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  className?: string;
  children?: React.ReactNode;
  scrollbarRef?: (I: any) => void;
  fullScreen?: boolean;
}

const modalSizeClass = {
  s: "w-modal-s",
  m: "w-modal-m",
  l: "w-modal-l",
};

const ModalBody = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      size = "m",
      children,
      showCloseIcon,
      showBackIcon,
      onClose,
      onBack,
      className,
      scrollable,
      scrollbarRef,
      fullScreen,
    },
    ref
  ) => {
    const [innerHeight, setInnerHeight] = React.useState<number>(
      window.innerHeight
    );
    React.useEffect(() => {
      let lastHeight = 0;
      let tick = false;
      const onResize = () => {
        lastHeight = window.innerHeight;
        if (!tick) {
          window.requestAnimationFrame(function () {
            setInnerHeight(lastHeight);
            tick = false;
          });
        }
        tick = true;
      };
      window.addEventListener("resize", onResize);
      return () => {
        window.removeEventListener("resize", onResize);
      };
    }, []);

    return (
      <div
        className={classnames(
          "overflow-hidden focus-visible:outline-none scrollbar",
          modalSizeClass[size],
          scrollable ? "scroll-modal" : "",
          !fullScreen ? "rounded-xl" : "",
          scrollable && fullScreen ? "scroll-modal--full-screen" : ""
        )}
        ref={ref}
        style={{ maxHeight: `${innerHeight}px` }}
      >
        <PerfectScrollbar className="inf-scrollbar" containerRef={scrollbarRef}>
          <div
            className={classnames(className ?? "bg-white p-5 pt-12 relative")}
          >
            {showCloseIcon && (
              <div
                className="absolute top-6 sm:top-3 right-3 w-6 h-6 bg-pale-grey rounded-full flex justify-center items-center cursor-pointer sm:hover:bg-grey-c6"
                onClick={onClose}
                onTouchStart={onClose}
              >
                <ClearIcon style={{ color: "#808080", fontSize: "1rem" }} />
              </div>
            )}
            {showBackIcon && (
              <div
                className="absolute top-6 sm:top-3 left-3 w-6 h-6 bg-pale-grey rounded-full flex justify-center items-center cursor-pointer sm:hover:bg-grey-c6"
                onClick={onBack}
                onTouchStart={onBack}
              >
                <ChevronLeftIcon
                  style={{ color: "#808080", fontSize: "1rem" }}
                />
              </div>
            )}
            {title && (
              <h2
                id="transition-modal-title"
                className="text-center text-lg font-bold mb-4"
              >
                {title}
              </h2>
            )}
            <div
              id="transition-modal-description"
              className="w-full flex flex-col"
            >
              {children}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
);

ModalBody.displayName = "ModalBody";

export default ModalBody;
