import React from "react";
import ModalBody from "./modal-body";
import {AuthenFlow} from "lib/enums";
import LoginThirdParty from "components/Form/LoginThirdParty";
import RegisterStepOneForm from "components/Form/register-step-one-form";
import {ErrorType} from "features/app/type";
import {useTracking} from "context/trackers";

interface Props {
  handleClose: () => void;
  changeFlow: (toFlow: AuthenFlow) => void;
  stepSubmit: (I: RegisterValues) => void;
  defaultValue?: Partial<RegisterValues>;
}

type RegisterValues = {
  phone: string;
  otp: string;
};

const RegisterStepOne: React.FC<Props> = ({
  handleClose,
  changeFlow,
  stepSubmit,
  defaultValue = {},
}) => {
  const handleLoginThirdPartySuccess = () => {
    handleClose();
  };
  const handleLoginThirdPartyFailure = (type?: string) => {
    if (type && type === ErrorType.ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED) {
      stepSubmit({ phone: "", otp: "" });
    }
  };

  /* Tracking GA modal open */
  const { modalView } = useTracking();
  React.useEffect(() => {
    modalView("/register-step-one");
  }, []);
  /* End Tracking GA modal open */

  return (
    <ModalBody
      title="Đăng ký"
      size="s"
      showCloseIcon
      onClose={handleClose}
      className="bg-white p-5 pt-8"
    >
      <RegisterStepOneForm defaultValue={defaultValue} onSuccess={stepSubmit} />
      <LoginThirdParty
        onLoginSuccess={handleLoginThirdPartySuccess}
        onLoginFailure={handleLoginThirdPartyFailure}
      />
      <div className="text-center text-xs text-grey-80 mt-4">
        Bạn đã có tài khoản?{" "}
        <span
          className="text-medium-blue underline cursor-pointer hover:text-bright-blue"
          onClick={() => changeFlow(AuthenFlow.LOGIN)}
        >
          Đăng nhập ngay
        </span>
      </div>
    </ModalBody>
  );
};

export default RegisterStepOne;
