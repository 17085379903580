import { ErrorType } from "features/app/type";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import get from "lodash/get";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./input";
import CountdownButton, { CountState } from "./countdown-button";
import Button from "./button";
import ModalBody from "./modal-body";
import { recaptchaVerifierInvisible } from "lib/firebase";
import {
  sendOTP,
  userCheckPhoneExists,
  userPhoneLogin,
  userVerifyPhone,
  verifyCode,
  verifyOTP,
} from "features/user/api";
import { formatPhoneNumber, PHONE_REGEX } from "lib/common";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MuiModal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AlertModal from "components/Dialog/AlertModal";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .image-icon": {
        width: "7.25rem",
        height: "auto",
        marginBottom: "1.625rem",
      },
      "&.modal-mobile": {
        position: "fixed",
        zIndex: "49 !important",
        width: "100%",
        height: "100vh",

        "& .w-modal-s": {
          width: "100%",
          padding: "0 1rem",
          height: "100%",
          backgroundColor: "#fff",
        },
      },
      "& .w-modal-s": {
        padding: "0 1rem",
        backgroundColor: "#fff",
      },
      "& #transition-modal-title": {
        paddingTop: "2.5rem",
      },
    },
  })
);

interface Props {
  phone: string;
  isShow: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

type RegisterValues = {
  phone: string;
  otp: string;
};

const PhoneVerifySchema = yup.object().shape({
  phone: yup
    .string()
    .matches(PHONE_REGEX, "Vui lòng nhập số điện thoại")
    .required("Vui lòng điền số điện thoại của bạn"),
  otp: yup
    .string()
    .min(6, "Mã OTP gồm 6 ký tự")
    .max(6, "Mã OTP gồm 6 ký tự")
    .required("Vui lòng điền OTP"),
});

const PhoneVerifyModal: React.FC<Props> = ({
  handleClose,
  isShow,
  phone,
  onSuccess,
}) => {
  const defaultValue: Partial<RegisterValues> = {
    phone: phone,
    otp: "",
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm<RegisterValues>({
    mode: "onSubmit",
    resolver: yupResolver(PhoneVerifySchema),
  });

  React.useEffect(() => {
    setValue("phone", phone, { shouldValidate: true });
  }, [isShow]);

  const classes = useStyles();
  const watchPhone = watch("phone", ""); // you can supply default value as second argument
  // clearErrors
  React.useEffect(() => {
    clearErrors();
  }, [watchPhone]);

  const [countState, setCountState] = React.useState<CountState>(
    CountState.INIT
  );
  const [phoneExist, setPhoneExist] = React.useState(new Set());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const onSubmit: SubmitHandler<RegisterValues> = (data) => {
    setIsLoading(true);
    verifyOTP(data.phone, "VERIFY_PHONE", data.otp)
      .then(() => {
        return userVerifyPhone(data.phone, data.otp);
      })
      .then(() => {
        setIsLoading(false);
        const { close } = AlertModal({
          title: "Xác thực thành công!",
          description:
            "Bạn có thể thiết lập mật khẩu cho tài khoản\n" +
            "để tăng cường bảo mật cho tài khoản và sử dụng\n" +
            "số điện thoại để đăng nhập vào Influzee.",
          type: "success",
          bottomText: "Thiết lập mật khẩu",
          onBottomClick: () => {
            close();
          },
        });
        onSuccess();
        handleClose();
      })
      .catch((err) => {
        setIsLoading(false);
        const { close } = AlertModal({
          title: "Xác thực thất bại!",
          description:
            "Mã OTP chưa đúng. Vui lòng kiểm tra lại hoặc liên hệ với chúng tôi để được hỗ trợ.",
          type: "error",
          bottomText: "Thử lại",
          onBottomClick: () => {
            close();
          },
        });
      });
  };

  useEffect(() => {
    if (isShow) {
      recaptchaVerifierInvisible("btn-submit-otp").then(() => {
        console.log("Init Login OTP Success");
      });
    } else {
      reset({ ...defaultValue });
    }
    setCountState(CountState.RELOAD);
  }, [isShow]);

  const onChangeCountState = (state: CountState) => {
    setCountState(state);
  };

  const handleClickSendOtp = () => {
    clearErrors();
    if (PHONE_REGEX.test(watchPhone)) {
      setCountState(CountState.START);
      const phoneNumber = formatPhoneNumber(watchPhone);
      (async () => {
        try {
          const { is_existed: isExists, is_verified: isVerified } =
            await userCheckPhoneExists(phoneNumber);
          if (isExists && isVerified) {
            setCountState(CountState.RELOAD);
            setPhoneExist(phoneExist.add(watchPhone));
            const { close } = AlertModal({
              title: "Xác thực thất bại!",
              description:
                "Số điện thoại đã được sử dụng. Vui lòng kiểm tra\n" +
                "lại hoặc liên hệ với chúng tôi để được hỗ trợ.",
              type: "error",
              bottomText: "Thử lại",
              onBottomClick: () => {
                close();
              },
            });
          } else {
            await sendOTP(phoneNumber, "VERIFY_PHONE");
          }
        } catch (err) {
          //TODO: handle send sms erorr
          console.log("Send SMS Error: ", err);
          setCountState(CountState.RELOAD);
          if (err.code === ErrorType.LIMIT) {
            const { close } = AlertModal({
              title: "Lỗi!",
              description:
                "Vượt quá giới hạn gửi SMS. Vui lòng thử lại sau 10 phút.",
              type: "error",
              bottomText: "Đã hiểu",
              onBottomClick: () => {
                close();
              },
            });
          } else {
            const { close } = AlertModal({
              title: "Lỗi!",
              description: "Có lổi xảy ra. Vui lòng thử lại.",
              type: "error",
              bottomText: "Thử lại",
              onBottomClick: () => {
                close();
              },
            });
          }
        }
      })();
    } else {
      setError("phone", {
        type: "manual",
        message: "Vui lòng nhập đúng định dạng số điện thoại",
      });
    }
  };

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classNames(classes.modal, { "modal-mobile": isMobile })}
      open={isShow}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
      disableBackdropClick
    >
      <Fade in={isShow} onExited={() => console.log("exit animation")}>
        <ModalBody
          className={classNames({ "modal-mobile-body": isMobile })}
          title="Xác thực điện thoại"
          size="s"
          showCloseIcon
          onClose={handleClose}
        >
          <div className="w-full flex items-center justify-center">
            <img
              className="image-icon"
              src="/assets/icon/phone-3-d-sticker.png"
              alt="Icon phone"
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="phone"
              control={control}
              defaultValue={defaultValue.phone || ""}
              render={({ field: { ref, ...others } }) => (
                <Input
                  label="Số điện thoại"
                  className="w-full mb-3"
                  errorMsg={get(errors, "phone.message", "")}
                  {...others}
                  disabled={true}
                />
              )}
            />
            <Controller
              name="otp"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...others } }) => (
                <Input
                  label="OTP"
                  className="w-full col-span-2"
                  errorMsg={get(errors, "otp.message", "")}
                  endAdornment={
                    <div className="w-52">
                      <CountdownButton
                        id="btn-submit-otp"
                        infType="tertiary"
                        countFrom={120}
                        completeText="Gửi lại mã"
                        countState={countState}
                        disabled={phoneExist.has(watchPhone)}
                        onChangeCountState={onChangeCountState}
                        onClick={handleClickSendOtp}
                      >
                        Gửi mã
                      </CountdownButton>
                    </div>
                  }
                  {...others}
                />
              )}
            />
            <div className="mb-4 mt-4">
              <Button type="submit" loading={isLoading}>
                Tiếp tục
              </Button>
            </div>
          </form>
        </ModalBody>
      </Fade>
    </MuiModal>
  );
};

export default PhoneVerifyModal;
