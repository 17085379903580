import React from "react";
import { createMuiTheme } from "@material-ui/core";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";

const Theme = createMuiTheme({
  typography: {
    fontFamily: `"SFProDisplay", sans-serif`,
  },
});
/* eslint-disable */
interface Props {}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return <MuiThemeProvider theme={Theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
