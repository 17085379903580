import React, {useCallback, useEffect} from "react";
import ReactDOM from "react-dom";
import Backdrop from "@material-ui/core/Backdrop";
import MuiModal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import ModalBody from "components/modal-body";
import Image from "next/image";
import Button from "components/button";
import ThemeProvider from "context/ThemeProvider";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  iconError: {
    marginBottom: "0.9375rem",
    fontSize: "4.375rem",
    color: "#FF0000",
  },
  iconSuccess: {
    marginBottom: "0.9375rem",
    fontSize: "4.375rem",
    color: "#FF0000",
  },
});

interface PopupProps {
  title?: string;
  description?: string;
  visible: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  afterClose: () => void;
  type: "success" | "error" | "coming-soon";
  bottomText?: string;
  onBottomClick?: () => void;
  showCloseIcon?: boolean;
  disableEscapeKeyDown?: boolean;
}

const Modal: React.FC<PopupProps> = ({
  title,
  description,
  visible,
  onOpen,
  onClose,
  afterClose,
  type,
  bottomText = "",
  onBottomClick,
  showCloseIcon = true,
  disableEscapeKeyDown = false,
}) => {
  const handleClose = useCallback(() => {
    if (onClose && typeof onClose === "function") {
      onClose();
    }
    if (afterClose && typeof afterClose === "function") {
      afterClose();
    }
  }, []);

  useEffect(() => {
    if (visible) {
      if (onOpen && typeof onOpen === "function") {
        onOpen();
      }
    }
  }, [visible]);
  const classes = useStyles();
  return (
    <ThemeProvider>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
        disableBackdropClick
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <ModalBody
          className="bg-white p-5 pt-7 rounded-xl relative"
          title={title ?? ""}
          size="s"
          showCloseIcon={showCloseIcon}
          onClose={handleClose}
        >
          <div className={classes.center}>
            <div className="w-20 h-20 relative mb-5">
              <Image
                src={`/assets/icon/${
                  type == "success"
                    ? "check-success"
                    : type === "coming-soon"
                      ? "phone-3-d-sticker"
                      : "error"
                }.png`}
                layout="fill"
              />
            </div>
            {description && (
              <div
                className="text-sm text-grey-80 mb-5 text-center"
                dangerouslySetInnerHTML={{__html: description}}
              ></div>
            )}
            {bottomText.length > 0 && (
              <Button
                onClick={onBottomClick}
                className="w-full bg-dark-teal text-white"
              >
                {bottomText}
              </Button>
            )}
          </div>
        </ModalBody>
      </MuiModal>
    </ThemeProvider>
  );
};

type DialogConfig = Omit<PopupProps, "visible" | "close" | "afterClose">;

type ConfigUpdate = DialogConfig | ((prevConfig: DialogConfig) => DialogConfig);

const AlertModal = (config: DialogConfig) => {
  const div = document.createElement("div");
  document.body.appendChild(div);
  let currentConfig: any = {
    ...config,
    close,
    visible: true,
    afterClose: destroy,
  };

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  function render({...props}: any) {
    ReactDOM.hydrate(<Modal {...props} />, div);
  }

  function close() {
    currentConfig = {
      ...currentConfig,
      visible: false,
    };
    render(currentConfig);
  }

  function update(configUpdate: ConfigUpdate) {
    if (typeof configUpdate === "function") {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }

  const closeModal = () => {
    close();
    destroy();
  };

  render(currentConfig);
  return {
    close: closeModal,
    update,
  };
};

export default AlertModal;
