import React from "react";
import classnames from "classnames";
import { WEB_NAME } from "../lib/constants";

interface Props {
  size?: "xs" | "sm" | "l" | "2xl" | "m";
}

const SIZE = {
  "2xl": "text-2xl",
  l: "text-l",
  xs: "text-xs",
  sm: "text-sm",
  m: "text-base",
};

const Logo: React.FC<Props> = ({ size = "2xl" }) => {
  return (
    <span
      className={classnames("text-dark-teal font-black lowercase", SIZE[size])}
    >
      {WEB_NAME}
      <span className="text-bright-blue">.</span>
    </span>
  );
};

export default Logo;
