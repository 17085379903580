import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Category: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          stroke="gray"
          d="M19.111 8.444h-4.444a.89.89 0 01-.89-.888V4.889a.89.89 0 01.89-.889h4.444c.491 0 .889.398.889.889v2.667c0 .49-.398.888-.889.888zm-8.889 10.667v-2.667a.889.889 0 00-.889-.888H4.89a.889.889 0 00-.889.888v2.667c0 .491.398.889.889.889h4.444a.89.89 0 00.89-.889z"
        />
        <path
          stroke="#C6C6C6"
          d="M9.333 12H4.89a.89.89 0 01-.89-.889V4.89A.89.89 0 014.889 4h4.444a.89.89 0 01.89.889v6.221a.89.89 0 01-.89.889zM20 19.111V12.89a.889.889 0 00-.889-.889h-4.444a.89.89 0 00-.89.889v6.222a.89.89 0 00.89.889h4.444a.889.889 0 00.889-.889z"
        />
      </g>
    </SvgIcon>
  );
};
export default Category;
