import React from "react";
import ModalBody from "./modal-body";
import { useTracking } from "context/trackers";
import RegisterStepTwoForm, {
  RegisterValues,
} from "components/Form/register-step-two-form";

interface Props {
  stepBack: () => void;
  stepSubmit: () => void;
  defaultValue?: Partial<RegisterValues>;
  isLoginThirdParty?: boolean;
}

const RegisterStepTwo: React.FC<Props> = ({
  stepBack,
  stepSubmit,
  defaultValue = {},
  isLoginThirdParty = false,
}) => {
  /* Tracking GA modal open */
  const { modalView } = useTracking();
  React.useEffect(() => {
    modalView("/register-step-two");
  }, []);
  /* End Tracking GA modal open */

  return (
    <ModalBody
      title="Hoàn tất đăng ký"
      size="m"
      showBackIcon
      onBack={stepBack}
      className="bg-white p-5 pt-8"
    >
      <div className="mt-1 text-center text-sm mb-3">Xin chào! Bạn là</div>
      <RegisterStepTwoForm
        defaultValue={defaultValue}
        isLoginThirdParty={isLoginThirdParty}
        onSuccess={stepSubmit}
      />
    </ModalBody>
  );
};

export default RegisterStepTwo;
