import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiToggleButtonGroup, {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from "@material-ui/lab/ToggleButtonGroup";

interface Props extends MuiToggleButtonGroupProps {}

const useStyles = makeStyles({
  root: {
    background: "#f5f7fa",
    borderRadius: "0.75rem",
  },
});

const ToggleButtonGroup: React.FC<Props> = ({ ...others }) => {
  const classes = useStyles();
  return (
    <MuiToggleButtonGroup
      classes={{
        root: classes.root,
      }}
      {...others}
    ></MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
