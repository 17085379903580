import React from "react";
import MuiMenu, { MenuProps as MuiMenuProps } from "@material-ui/core/Menu";
import { makeStyles, Theme } from "@material-ui/core/styles";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props {
  width?: string;
  maxHeight?: string;
}

const useStyle = makeStyles<Theme, { maxHeight: string; width: string }>({
  paper: {
    maxHeight: (props) => props.maxHeight,
    marginTop: "1rem",
    borderRadius: "0.75rem",
    width: (props) => props.width,
    "&::-webkit-scrollbar": {
      width: "0.4rem",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "rgba(0,0,0,.15)",
    },
  },
});

const Menu: React.FC<Props & Omit<MuiMenuProps, keyof Props>> = (props) => {
  const { width = "", maxHeight = "12rem", ...others } = props;
  const classes = useStyle({ width, maxHeight });
  return (
    <MuiMenu
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ paper: classes.paper }}
      {...others}
    />
  );
};

export default Menu;
