import React from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import get from "lodash/get";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/input";
import InputSlug from "components/input-slug";
import Button from "components/button";
import Select from "components/select";
import MultiSelect from "components/multi-select";
import DatePicker from "components/date-picker";
import ToggleButton from "components/toggle-button";
import ToggleButtonGroup from "components/toggle-button-group";
import { HOMETOWN_OPTIONS } from "lib/constants";
import {
  userRegisterAccount,
  userRegisterAccountThirdParty,
} from "features/user/api";
import { useAppDispatch, useAppSelector } from "store";
import { updateUserInfo, userAccessToken } from "features/user/userSlice";
import { IUserInfo, UserType } from "features/user/type";
import {
  EMAIL_REGEX,
  formatPhoneNumber,
  PASSWORD_REGEX,
  PHONE_REGEX,
} from "lib/common";
import dayjs from "dayjs";
import AlertModal from "components/Dialog/AlertModal";

/* eslint-disable */
interface Props {
  onSuccess: (user?: IUserInfo) => void;
  defaultValue?: Partial<RegisterValues>;
  isLoginThirdParty: boolean;
}

export type RegisterValues = {
  phone: string;
  password: string;
  otp: string;
  name: string;
  birth_date: string;
  email: string;
  hometown: string;
  influzee_id: string;
  categories: string[];
  address: string;
};

const RegisterSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(PHONE_REGEX, "Vui lòng nhập số điện thoại")
    .required("Vui lòng điền số điện thoại của bạn"),
  email: yup.string().matches(EMAIL_REGEX, "Vui lòng nhập đúng email của bạn"),
  name: yup.string().required("Vui lòng điền tên"),
  influzee_id: yup
    .string()
    .required("Vui lòng điền id dẫn tới trang cá nhân")
    .min(5, "Influzee ID ít nhất 5 ký tự"),
  password: yup
    .string()
    .min(8, "Mật khẩu ít nhất 8 ký tự")
    .max(20, "Mật khẩu tối đa 20 ký tự")
    .matches(PASSWORD_REGEX, "Mật khẩu phải gồm chữ")
    .required("Vui lòng điền mật khẩu"),
  categories: yup
    .array()
    .required("Vui lòng chọn thể loại")
    .min(1, "Vui lòng chọn ít nhất 1 thể loại")
    .max(3, "Chọn tối đa 3 thể loại"),
});

const RegisterStepTwoForm: React.FC<Props> = ({
  onSuccess,
  isLoginThirdParty,
  defaultValue = {},
}) => {
  const categoryOptions = useAppSelector((state) => state.category.list);
  const userState = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterValues>({
    mode: "onSubmit",
    resolver: yupResolver(RegisterSchema),
  });

  const influzeeId = useWatch({
    control,
    name: "influzee_id", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "", // default value before the render
  });

  const onSubmit: SubmitHandler<RegisterValues> = (data) => {
    const id_token = userState.firebaseToken || "";
    const otp = defaultValue.otp || "";
    if (!id_token && !otp) return;
    const info = {
      password: data.password,
      user_role: userType,
      optionData: {
        phone: formatPhoneNumber(data.phone),
        full_name: data.name,
        birth_date: dayjs(data.birth_date).format("DD/MM/YYYY"),
        email: data.email,
        province: data.hometown,
        influzee_id: data.influzee_id,
        address: data.address,
        categories: data.categories,
      },
    };
    setIsLoading(true);
    (() => {
      if (isLoginThirdParty) {
        return userRegisterAccountThirdParty({ id_token, ...info });
      } else {
        return userRegisterAccount({ otp, ...info });
      }
    })()
      .then((res) => {
        if (res) {
          const { token, user } = res;
          dispatch(userAccessToken(token));
          dispatch(updateUserInfo(new IUserInfo(user)));
          onSuccess(new IUserInfo(user));
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorObj = get(err, "errorObj");
        if (errorObj) {
          const validationError = get(
            errorObj,
            "response.data.data.ValidationError"
          );
          if (validationError) {
            Object.keys(validationError).forEach((i) => {
              setError(i as keyof RegisterValues, {
                type: "manual",
                message: validationError[i].join(" "),
              });
            });
            return;
          }
        }
        const { close } = AlertModal({
          title: "Xác thực thất bại!",
          description: get(err, "messageShow", err.message),
          type: "error",
          bottomText: "Thử lại",
          onBottomClick: () => {
            close();
          },
        });
      });
  };
  const [userType, setUserType] = React.useState<UserType>(UserType.KOL);
  const onChangeHandler = (
    event: React.MouseEvent<HTMLElement>,
    newValue: UserType | null
  ) => {
    if (newValue !== null) {
      setUserType(newValue);
    }
  };

  return (
    <>
      <div className="w-full flex justify-center mb-8">
        <ToggleButtonGroup
          value={userType}
          exclusive
          onChange={onChangeHandler}
          aria-label="text alignment"
        >
          {/* <ToggleButton value={UserType.USER} style={{ width: "8.75rem" }}> */}
          {/*   Người dùng */}
          {/* </ToggleButton> */}
          <ToggleButton value={UserType.KOL} style={{ width: "8.75rem" }}>
            Người ảnh hưởng
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <Controller
            name="phone"
            control={control}
            defaultValue={defaultValue.phone}
            render={({ field: { ref, ...others } }) => (
              <Input
                label="Số điện thoại"
                className="w-full"
                errorMsg={get(errors, "phone.message", "")}
                disabled={get(defaultValue, "phone", "").length > 0}
                {...others}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue={defaultValue.password}
            render={({ field: { ref, ...others } }) => (
              <Input
                label="Mật khẩu"
                type="password"
                className="w-full"
                autoComplete="new-password"
                errorMsg={get(errors, "password.message", "")}
                {...others}
              />
            )}
          />
          <div className="sm:hidden w-full text-center font-bold text-lg">
            Thông tin cá nhân
          </div>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...others } }) => (
              <Input
                label="Họ & tên"
                className="w-full"
                errorMsg={get(errors, "name.message", "")}
                {...others}
              />
            )}
          />
          <Controller
            name="birth_date"
            control={control}
            defaultValue={dayjs().format()}
            render={({ field: { ref, ...others } }) => (
              <DatePicker
                disableFuture
                openTo="year"
                format="DD/MM/YYYY"
                label="Ngày sinh"
                views={["year", "month", "date"]}
                className="w-full"
                {...others}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...others } }) => (
              <Input
                label="Email"
                type="email"
                className="w-full"
                errorMsg={get(errors, "email.message", "")}
                {...others}
              />
            )}
          />
          <Controller
            name="hometown"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...others } }) => (
              <Select
                className="w-full"
                options={HOMETOWN_OPTIONS}
                inputProps={{
                  label: "Tỉnh thành",
                  errorMsg: get(errors, "hometown.message", ""),
                  ...others,
                }}
              />
            )}
          />
          {/* <Controller */}
          {/*   name="influzeeID" */}
          {/*   control={control} */}
          {/*   defaultValue="" */}
          {/*   render={({ field: { ref, ...others } }) => ( */}
          {/*     <VerifiedInput */}
          {/*       label="Influzee ID" */}
          {/*       className="w-full" */}
          {/*       remoteMethod={verifyID} */}
          {/*       loading={isVerifyingID} */}
          {/*       successMsg={verifyingID.success} */}
          {/*       errorMsg={ */}
          {/*         get(errors, "influzeeID.message", "") || verifyingID.error */}
          {/*       } */}
          {/*       {...others} */}
          {/*     /> */}
          {/*   )} */}
          {/* /> */}
          <Controller
            name="influzee_id"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...others } }) => (
              <InputSlug
                label="Influzee ID"
                className="w-full"
                errorMsg={get(errors, "influzee_id.message", "")}
                helperBottomMsg={
                  influzeeId
                    ? `${process.env.NEXT_PUBLIC_DOMAIN}${influzeeId}`
                    : `${process.env.NEXT_PUBLIC_DOMAIN}influzee-id`
                }
                {...others}
              />
            )}
          />
          <Controller
            name="categories"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, ...others } }) => (
              <MultiSelect
                multiple
                className="w-full"
                options={categoryOptions.map((i) => {
                  return { value: i.id, label: i.name };
                })}
                width={"13.25rem"}
                inputProps={{
                  label: "Lĩnh vực quan tâm (Tối đa: 3)",
                  errorMsg: get(errors, "categories.message", ""),
                }}
                {...others}
              />
            )}
          />
        </div>
        <div className="mt-8 mb-14 sm:mb-3 mx-auto w-72 ">
          <Button type="submit" loading={isLoading}>
            Hoàn thành
          </Button>
        </div>
      </form>
    </>
  );
};

export default RegisterStepTwoForm;
