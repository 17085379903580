import React from "react";
import ReactGA from "react-ga";
import { useRouter } from "next/router";
import { useAppSelector } from "store";
import { GA_TRACKING_ID, DEBUG_GA } from "lib/constants";

interface ContextValue {
  logEvent: (I: { category: string; action: string; label?: string }) => void;
  modalView: (I: string) => void;
}
const TrackingContext = React.createContext<ContextValue>({
  logEvent: () => {}, // eslint-disable-line
  modalView: () => {}, // eslint-disable-line
});

function TrackingProvider(props: any) {
  const router = useRouter();
  const userId = useAppSelector((state) => state.user?.user?.id);
  const [analytics, setAnalytics] = React.useState({
    isInitialized: false,
    hasUser: false,
    trackers: [GA_TRACKING_ID],
  });
  const handleRouteChange = (url: string) => {
    ReactGA.set({ page: url }, analytics.trackers);
    ReactGA.pageview(url, analytics.trackers);
  };

  const logEvent = ({ category = "", action = "", label = "" }) => {
    if (analytics.isInitialized) {
      ReactGA.event(
        {
          category,
          action,
          label,
        },
        analytics.trackers
      );
    }
  };

  const modalView = (modalName: string) => {
    if (analytics.isInitialized) {
      ReactGA.modalview(modalName);
    }
  };

  React.useEffect(() => {
    const { isInitialized, hasUser, trackers } = analytics;
    // initialize GA with our tracking id
    // uncomment the user tracking method that works for you
    if (!isInitialized) {
      ReactGA.initialize(GA_TRACKING_ID, {
        debug: !!DEBUG_GA,
        gaOptions: {
          userId: userId,
        },
      });

      // Handle all route changes
      router.events.on("routeChangeComplete", handleRouteChange);

      setAnalytics((prev) => ({
        ...prev,
        isInitialized: true,
        hasUser: Boolean(userId),
      }));
      handleRouteChange(window.location.pathname);
      // in case we dont have the user initially,
      // we handle setting a user in our tracker
    } else if (isInitialized && !hasUser) {
      ReactGA.set({ userId }, trackers);
      setAnalytics((prev) => ({
        ...prev,
        hasUser: Boolean(userId),
      }));
    }

    return () => {
      // clean up
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [userId]);
  return (
    <TrackingContext.Provider value={{ logEvent, modalView }} {...props} />
  );
}

const useTracking = () => React.useContext(TrackingContext);

export { TrackingProvider, useTracking };
