import React from "react";
import { SITE_URL, SIDE_MENU } from "lib/constants";

interface Props {} // eslint-disable-line
const SeoStructure: React.FC<Props> = () => {
  const [isShow, setIsShow] = React.useState<boolean>(true);
  React.useEffect(() => {
    setIsShow(false);
  }, []);
  if (!isShow) return null;
  return (
    <>
      <div className="-z-10 opacity-0 absolute">
        <h1>Influzee - Making for influencers</h1>
        <aside>
          <nav>
            <ul>
              {SIDE_MENU.map((i) => {
                return (
                  <li key={i.path}>
                    <a href={`${SITE_URL}${i.path}`} title={i.label}>
                      {i.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </aside>
        <section>
          <h2>Influzee là gì?</h2>
          <p>
            Influzee là nền tảng xây dựng và kết nối thương hiệu được phát triển
            dành riêng cho Influencers.
          </p>
        </section>
      </div>
    </>
  );
};

export default SeoStructure;
