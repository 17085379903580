import React, { useState } from "react";
import Fade from "@material-ui/core/Fade";
import MuiFilledInput, {
  FilledInputProps as MuiFilledInputProps,
} from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import useInputStyles from "assets/styles/input";

export interface Props extends MuiFilledInputProps {
  label?: string;
  type?: string;
  className?: string;
  errorMsg?: string;
  successMsg?: string;
  helperBottomMsg?: string;
  disableErrorMsg?: boolean;
  defaultValue?: unknown;
  onClickIconEye?: () => void;
  isHideValue?: boolean;
  helperText?: string;
  helperTextColor?: string;
}

interface IconProps {
  onClick?: () => void;
}
const ShowPasswordIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <img
      onClick={onClick}
      src="/assets/sidemenu-logo/eye-icon.svg"
      alt=""
      className="w-5 h-5 cursor-pointer"
    />
  );
};
const HidePasswordIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <img
      onClick={onClick}
      src="/assets/sidemenu-logo/eye-slash-icon.svg"
      alt=""
      className="w-5 h-5 cursor-pointer"
    />
  );
};
const Input: React.FC<Props> = ({
  label,
  type,
  className = "",
  errorMsg,
  successMsg,
  disableErrorMsg,
  defaultValue = "",
  onClickIconEye,
  isHideValue,
  helperText,
  helperTextColor,
  helperBottomMsg,
  ...otherProps
}) => {
  const classes = useInputStyles();
  const [value, setValue] = React.useState<unknown>(defaultValue);
  const [_type, _setType] = React.useState<string>(type || "text");
  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (type === "password") {
      if (isShowPassword) {
        _setType("text");
      } else {
        _setType("password");
      }
    }
  }, [isShowPassword]);

  React.useEffect(() => {
    if (type !== "password" && isHideValue !== undefined) {
      if (!isHideValue) {
        _setType("text");
      } else {
        _setType("password");
      }
    }
  }, [isHideValue]);

  const onIconClick = () => {
    if (onClickIconEye && typeof onClickIconEye === "function") {
      onClickIconEye();
    }
  };

  const [inputId] = useState(() => `${Math.floor(Math.random() * 10000) + 1}`);

  return (
    <div className={className}>
      <FormControl
        variant="filled"
        className={classes.control}
        error={!!errorMsg}
      >
        {label && (
          <InputLabel
            htmlFor={"component-filled" + inputId}
            classes={{
              root: classes.label,
            }}
          >
            {label}
          </InputLabel>
        )}
        <MuiFilledInput
          id={"component-filled" + inputId}
          aria-describedby={"helper-text" + inputId}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth={true}
          type={_type}
          endAdornment={
            type === "password" ? (
              isShowPassword ? (
                <HidePasswordIcon
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              ) : (
                <ShowPasswordIcon
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              )
            ) : isHideValue !== undefined ? (
              !isHideValue ? (
                <HidePasswordIcon onClick={onIconClick} />
              ) : (
                <ShowPasswordIcon onClick={onIconClick} />
              )
            ) : null
          }
          classes={{
            root: classes.input,
            disabled: classes.disabled,
          }}
          {...otherProps}
        />
        {helperText && (
          <FormHelperText
            id={"helper-text" + inputId}
            classes={{ root: classes.helperText }}
            style={{ color: helperTextColor }}
          >
            {helperText}
          </FormHelperText>
        )}
        {helperBottomMsg && helperBottomMsg.length > 0 && (!errorMsg || disableErrorMsg) && (
          <Fade in={!!helperBottomMsg}>
            <div className="pl-5 mt-1 text-xs text-grey-80">
              {helperBottomMsg}
            </div>
          </Fade>
        )}
        {successMsg && !disableErrorMsg && (
          <Fade in={!!successMsg && !disableErrorMsg}>
            <div className="pl-5 mt-1 text-xs text-success-green">
              {successMsg}
            </div>
          </Fade>
        )}
        {errorMsg && !disableErrorMsg && (
          <Fade in={!!errorMsg && !disableErrorMsg}>
            <div className="pl-5 mt-1 text-xs text-error-red">{errorMsg}</div>
          </Fade>
        )}
      </FormControl>
    </div>
  );
};

export default Input;
