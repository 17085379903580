import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from "@material-ui/core/MenuItem";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props {}

const useStyle = makeStyles<Theme>({
  root: {
    fontSize: "0.875rem",
    "&:focus": {
      background: "#f5f7fa",
      "& .MuiListItemIcon-root": {
        color: "#808080",
      },
      "& .MuiListItemText-primary": {
        color: "#808080",
      },
    },
    "&.Mui-selected": {
      background: "#f5f7fa",
    },
    "&:hover": {
      background: "#f5f7fa",
    },
  },
});

const MenuItem = React.forwardRef<
  HTMLLIElement,
  Props & Omit<MuiMenuItemProps, keyof Props | "button">
>(({ ...others }, ref) => {
  const classes = useStyle();
  return <MuiMenuItem ref={ref} classes={{ root: classes.root }} {...others} />;
});

MenuItem.displayName = "MenuItem";
/* const MenuItem: React.FC< */
/*   Props & Omit<MuiMenuItemProps, keyof Props | "button"> */
/* > = (props) => { */
/* }; */

export default MenuItem;
