import React from "react";
import { isMobile } from "react-device-detect";
import Image from "next/image";
import { ITab, ITabItem } from "features/profile/type";

interface Props {
  tab?: ITab;
  onItemClick?: (link: string) => void;
}

interface CustomTabItemProps extends ITabItem {
  onClick?: () => void;
}

const CustomTabItem: React.FC<CustomTabItemProps> = ({
  image,
  text,
  link,
  onClick,
}) => {
  return (
    <div className="flex flex-col w-1/2 sm:w-1/4 p-4 flex-shrink-0">
      <div className="w-full mb-3">
        <div
          className="aspect-w-1 aspect-h-1 rounded-xl overflow-hidden cursor-pointer"
          onClick={onClick}
        >
          {image ? (
            isMobile ? (
              <a
                href={link.indexOf("https://") >= 0 ? link : "https://" + link}
              >
                <Image
                  layout="fill"
                  src={image}
                  placeholder="blur"
                  blurDataURL="/assets/sidemenu-logo/app-icon-1-1.png"
                  unoptimized
                />
              </a>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={link.indexOf("https://") >= 0 ? link : "https://" + link}
              >
                <Image
                  layout="fill"
                  src={image}
                  placeholder="blur"
                  blurDataURL="/assets/sidemenu-logo/app-icon-1-1.png"
                  unoptimized
                />
              </a>
            )
          ) : (
            <img
              src="/assets/sidemenu-logo/app-icon-1-1.png"
              className="opacity-50"
            />
          )}
        </div>
      </div>
      <div className="text-sm text-greyish-brown line-clamp-2">
        {isMobile ? (
          <a href={link.indexOf("https://") >= 0 ? link : "https://" + link}>
            {text}
          </a>
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link.indexOf("https://") >= 0 ? link : "https://" + link}
          >
            {text}
          </a>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ProfileCustomTab: React.FC<Props> = ({ tab, onItemClick = () => {} }) => {
  return (
    <div className="flex flex-row flex-wrap -m-4">
      {tab?.items.map((i: ITabItem, index: number) => (
        <CustomTabItem
          key={index}
          onClick={i.link ? onItemClick.bind(null, i.link) : undefined}
          {...i}
        />
      ))}
    </div>
  );
};

export default ProfileCustomTab;
