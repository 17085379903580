import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import MuiModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Login from "./login";
import Register, {RegisterStep} from "./register";
import ForgotPassword from "./forgot-password";
import {AuthenFlow} from "lib/enums";

interface Props {
  isShow: boolean;
  handleClose: () => void;
  defaultFlow?: AuthenFlow;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const AuthenModal: React.FC<Props> = ({isShow, handleClose, defaultFlow}) => {
  const classes = useStyles();
  const [flow, setFlow] = React.useState<AuthenFlow>(defaultFlow ?? AuthenFlow.LOGIN);

  React.useEffect(() => {
    if (isShow) {
      setFlow(defaultFlow ?? AuthenFlow.LOGIN);
    }
  }, [isShow]);
  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isShow}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
      disableBackdropClick
    >
      <Fade in={isShow}>
        <>
          {flow === AuthenFlow.LOGIN && (
            <Login handleClose={handleClose} changeFlow={setFlow} />
          )}
          {(flow === AuthenFlow.REGISTER ||
            flow === AuthenFlow.REGISTER_STEP_TWO) && (
            <Register
              defaultStep={
                flow === AuthenFlow.REGISTER_STEP_TWO
                  ? RegisterStep.STEP_TWO
                  : RegisterStep.STEP_ONE
              }
              handleClose={handleClose}
              changeFlow={setFlow}
            />
          )}
          {flow === AuthenFlow.FORGOT_PASSWORD && (
            <ForgotPassword handleClose={handleClose} changeFlow={setFlow} />
          )}
        </>
      </Fade>
    </MuiModal>
  );
};

export default AuthenModal;
