import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const CategorySolid: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M19.465 8.266H14.8a.934.934 0 01-.934-.934V4.535c0-.515.418-.933.934-.933h4.668c.511 0 .93.418.93.933v2.797a.934.934 0 01-.934.934zm-9.332 11.199v-2.797a.934.934 0 00-.934-.934H4.531a.933.933 0 00-.93.934v2.8c0 .516.419.934.934.934H9.2a.934.934 0 00.934-.933zm0 0"
          stroke="#01334c"
          strokeWidth={1.7999999999999998}
        />
        <path
          d="M9.2 12H4.53a.933.933 0 01-.93-.934v-6.53c0-.516.419-.934.934-.934H9.2c.516 0 .934.418.934.933v6.531a.934.934 0 01-.934.934zm11.198 7.465v-6.531a.934.934 0 00-.933-.934H14.8a.934.934 0 00-.934.934v6.53c0 .516.418.934.934.934h4.668c.511 0 .93-.418.93-.933zm0 0"
          stroke="gray"
          strokeWidth={1.7999999999999998}
        />
      </g>
    </SvgIcon>
  );
};
export default CategorySolid;
