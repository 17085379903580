import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import get from "lodash/get";
import { useAppDispatch } from "store";
import { formatPhoneNumber, PHONE_REGEX, PASSWORD_REGEX } from "lib/common";
import Input from "components/input";
import Button from "components/button";
import PhoneVerifyModal from "components/phone-verify-modal";
import { IUserInfo } from "features/user/type";
import { ErrorType } from "features/app/type";
import { userLoginPassword } from "features/user/api";
import { updateUserInfo, userAccessToken } from "features/user/userSlice";

/* eslint-disable */
interface Props {
  onSuccess: (user?: IUserInfo) => void;
}

type LoginValues = {
  phone: string;
  password: string;
};

const LoginSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(PHONE_REGEX, "Vui lòng nhập số điện thoại")
    .required("Vui lòng điền số điện thoại của bạn"),
  password: yup
    .string()
    .min(8, "Mật khẩu ít nhất 8 ký tự")
    .max(20, "Mật khẩu tối đa 20 ký tự")
    .matches(PASSWORD_REGEX, "Mật khẩu phải gồm chữ")
    .required("Vui lòng điền mật khẩu"),
});

const LoginForm: React.FC<Props> = ({ onSuccess }) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginValues>({
    mode: "onSubmit",
    resolver: yupResolver(LoginSchema),
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [isShowVerifyModal, setIsShowVerifyModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const onSubmit: SubmitHandler<LoginValues> = (data) => {
    const phone = formatPhoneNumber(data.phone);
    setPhoneNumber(phone);
    setIsLoading(true);
    userLoginPassword(phone, data.password)
      .then((res) => {
        const { token, user } = res;
        dispatch(userAccessToken(token));
        dispatch(updateUserInfo(new IUserInfo(user)));
        onSuccess(new IUserInfo(user));
      })
      .catch((err) => {
        if (err.code == ErrorType.ERROR_WRONG_PASSWORD) {
          setError("password", {
            type: "manual",
            message: get(err, "messageShow", err.message),
          });
          return;
        }
        if (err.code == ErrorType.ERROR_USER_NOT_FOUND) {
          setError("phone", {
            type: "manual",
            message: get(err, "messageShow", err.message),
          });
          return;
        }
        if (err.code == ErrorType.ERROR_USER_UNVERIFIED) {
          setError("phone", {
            type: "manual",
            message: get(err, "messageShow", err.message),
          });
          return;
        }
        setError("password", {
          type: "manual",
          message: get(
            err,
            "messageShow",
            "Lỗi chưa xác định, vui lòng thử lại"
          ),
        });
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...others } }) => (
          <Input
            label="Số điện thoại"
            className="w-full mb-3"
            errorMsg={get(errors, "phone.message", "")}
            {...others}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...others } }) => (
          <Input
            label="Mật khẩu"
            type="password"
            className="w-full"
            errorMsg={get(errors, "password.message", "")}
            {...others}
          />
        )}
      />
      <div className="mb-2 mt-4">
        <Button type="submit" loading={isLoading}>
          Đăng nhập
        </Button>
      </div>
      <PhoneVerifyModal
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSuccess={() => {}}
        isShow={isShowVerifyModal}
        phone={phoneNumber}
        handleClose={() => setIsShowVerifyModal(false)}
      />
    </form>
  );
};

export default LoginForm;
