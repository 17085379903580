import React from "react";
import classnames from "classnames";
import { nFormatter } from "lib/utils";

type SocialType = "facebook" | "tiktok" | "instagram" | "youtube";
interface Props {
  className?: string;
  type: SocialType;
  value: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const SOCIAL_BG_COLOR = {
  facebook: ["bg-facebook-light-blue", "hover:bg-facebook-dark-blue"],
  facebook_group: ["bg-facebook-light-blue", "hover:bg-facebook-dark-blue"],
  tiktok: ["bg-tiktok-light-grey", "hover:bg-tiktok-dark-grey"],
  instagram: ["bg-instagram-light-yellow", "hover:bg-instagram-dark-yellow"],
  youtube: ["bg-youtube-light-red", "hover:bg-youtube-dark-red"],
};

const SOCIAL_LOGO = {
  facebook: "/assets/sidemenu-logo/fb-lg.svg",
  facebook_group: "/assets/sidemenu-logo/fb-lg.svg",
  tiktok: "/assets/sidemenu-logo/tiktok-lg.svg",
  instagram: "/assets/sidemenu-logo/instagram-lg.webp",
  youtube: "/assets/sidemenu-logo/youtube-lg.svg",
};

const SOCIAL_TEXT_COLOR = {
  facebook: "text-medium-blue",
  facebook_group: "text-medium-blue",
  tiktok: "text-black",
  instagram: "text-instagram-yellow",
  youtube: "text-youtube-red",
};

const SOCIAL_TITLE = {
  facebook: "Facebook",
  facebook_group: "Facebook Group",
  tiktok: "Tiktok",
  instagram: "Instagram",
  youtube: "Youtube",
};
const SOCIAL_VALUE_UNIT = {
  facebook: "Followers",
  facebook_group: "Members",
  tiktok: "Followers",
  instagram: "Followers",
  youtube: "Subscribers",
};

const ConnectSocial: React.FC<Props> = ({
  type,
  value,
  className,
  onClick,
}) => {
  return (
    <div className={classnames(className)} onClick={onClick}>
      <div
        className={classnames(
          "py-3 flex flex-col content-center rounded-xl cursor-pointer",
          [...SOCIAL_BG_COLOR[type]]
        )}
      >
        <span className="mx-auto">
          <img
            src={SOCIAL_LOGO[type]}
            height={60}
            width={60}
            alt="logo facebook"
          />
        </span>
        <span className="text-greyish-brown text-xs text-center my-1">
          {SOCIAL_TITLE[type]}
        </span>
        <span
          className={classnames(
            "text-xs text-center font-medium",
            SOCIAL_TEXT_COLOR[type]
          )}
        >
          {nFormatter(value, 1)} {SOCIAL_VALUE_UNIT[type]}
        </span>
      </div>
    </div>
  );
};

export default ConnectSocial;
