import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { cacheAdapterEnhancer, ICacheLike } from "axios-extensions";
import LRUCache from "lru-cache";
import get from "lodash/get";
import queryString from "query-string";
import store from "store";
import * as https from "https";
import { AppError, ErrorType } from "features/app/type";
import { userSignOut } from "features/user/userSlice";

export const cacheMemory = new LRUCache({
  maxAge: 10 * 60 * 1000,
  max: 50,
}) as ICacheLike<AxiosPromise>;

export const clearCache = () => {
  const cache: any = cacheMemory;
  console.log("clear cached api");
  if (cache && cache.reset && typeof cache.reset === "function") {
    cache.reset();
  }
};

const API_ROOT =
  process.env.NEXT_PUBLIC_API_URL || "https://be.influzee.com/api/";
const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});

const axiosInstance = axios.create({
  httpsAgent: httpsAgent,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter!, {
    enabledByDefault: false,
    defaultCache: cacheMemory,
  }),
  timeout: 10000,
  paramsSerializer: (params) => {
    return queryString.stringify(params, {
      arrayFormat: "separator",
      arrayFormatSeparator: "|",
    });
  },
});

axiosInstance.interceptors.request.use((config) => {
  // Add token to header
  const token = get(store.getState(), "user.accessToken", "");
  if (!token.length) {
    delete config.headers.authorization;
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.params = {
    device_id: get(store.getState(), "app.deviceId", ""),
    ...config.params,
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const { status, error_code, message } = response.data;
    if (status && status === 1) {
      return response.data;
    }
    console.log("API ERROR: ", error_code, message);
    return Promise.reject(
      new AppError(error_code, message, get(response, "data.data"))
    );
  },
  (error) => {
    console.log(error);
    if (error.config && error.response) {
      if (error.response.status === 401) {
        store.dispatch(userSignOut());
        return Promise.reject(
          new AppError(ErrorType.UNAUTHORIZED, error.message)
        );
      } else if (error.response.status === 403) {
        return Promise.reject(new AppError(ErrorType.FORBIDDEN, error.message));
      } else if (error.response.status === 404) {
        return Promise.reject(new AppError(ErrorType.NOT_FOUND, error.message));
      } else if (error.response.status === 503) {
        return Promise.reject(new AppError(ErrorType.LIMIT, error.message));
      } else if (error.response.status > 500) {
        return Promise.reject(new AppError(ErrorType.APP_ERROR, error.message));
      }
    }
    return Promise.reject(
      new AppError(
        ErrorType.Unexpected,
        get(error, "response.data.message", "") || (error && error.message),
        error
      )
    );
  }
);

const _callApiFactory = (ROOT: string) => {
  return <T>(options: AxiosRequestConfig) => {
    if (options.url) {
      options.url =
        options.url.indexOf("https://") === -1
          ? ROOT + options.url
          : options.url;
    }
    if (!options.headers)
      options.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "vi",
      };

    return axiosInstance.request(options).then((response: AxiosResponse<T>) => {
      return response.data;
    });
  };
};
export default _callApiFactory(API_ROOT);
export function callApiNotDomain(options: AxiosRequestConfig, schema?: any) {
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter!, {
      enabledByDefault: false,
    }),
    timeout: 10000,
  });

  return axiosInstance.request(options).then(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error) => {
      return error.response;
    }
  );
}
