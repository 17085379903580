import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Profile: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M16.84 8.012a4.414 4.414 0 01-8.828 0 4.411 4.411 0 014.414-4.41 4.411 4.411 0 014.414 4.41zm0 0"
          stroke="gray"
          strokeWidth={1.7999999999999998}
        />
        <path
          d="M8.7 15.738h7.456c1.531 0 2.266.196 2.953.559a3.84 3.84 0 011.586 1.59c.364.683.559 1.422.559 2.953v.762H3.602v-.762c0-1.531.191-2.27.558-2.953a3.827 3.827 0 011.59-1.59c.684-.363 1.418-.559 2.953-.559zm0 0"
          stroke="#c6c6c6"
          strokeWidth={1.7999999999999998}
        />
      </g>
    </SvgIcon>
  );
};
export default Profile;
