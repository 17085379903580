import * as Sentry from "@sentry/nextjs";
import { AppError, ErrorType } from "features/app/type";
import {
  updateFirebaseProvider,
  userSignOut,
  userUpdateFirebaseToken,
} from "features/user/userSlice";
import { UserCredential } from "firebase/auth";
import callApi, { clearCache } from "lib/apiService";
import { ENC_KEY } from "lib/constants";
import {
  auth,
  signInWithPhoneNumber,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from "lib/firebase";
import { encode } from "lib/utils";
import { get } from "lodash";
import store from "store";

export enum LoginType {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
}

export const getProfile = () => {
  return callApi({
    url: `auth/profile`,
    method: "GET",
  });
};

export const userLoginPassword = (phone: string, password: string) => {
  return callApi<{ token: string; user: any }>({
    url: `auth/login`,
    method: "POST",
    data: {
      phone,
      password,
    },
  });
};

export const userLoginThirdParty = async (type: string) => {
  try {
    try {
      if (auth.currentUser) {
        await signOut();
      }
    } catch (err) {
      console.log("ERROR SIGN OUT BEFORE LOGIN REDIRECT");
    }
    const result = await signInWithPopup(type);
    if (result && result.user) {
      return checkUserLoginResult(result, type);
    }
    return Promise.reject(
      new AppError(
        ErrorType.LOGIN_FAIL,
        "Error login third party authentication"
      )
    );
  } catch (error: any) {
    if (error && error?.name === "App Error") return Promise.reject(error);
    else {
      Sentry.captureException(error);
      return Promise.reject(
        new AppError(ErrorType.LOGIN_FAIL, error.message, error)
      );
    }
  }
};

export const checkUserLoginResult = async (
  result: UserCredential,
  type?: string
) => {
  const info = await result.user.getIdTokenResult();
  const provider = (() => {
    if (type && type.length) return type;
    switch (result.providerId) {
      case "google.com":
        return LoginType.GOOGLE;
      case "facebook.com":
        return LoginType.FACEBOOK;
      default:
        return "";
    }
  })();
  if (info) {
    const token = get(info, "token");
    if (token) {
      //Update firebase token to store user
      store.dispatch(userUpdateFirebaseToken(token));
      store.dispatch(updateFirebaseProvider(provider));
      return userLoginFirebaseToken({ provider: provider, id_token: token });
    }
  }
  Sentry.captureException("Error login third party authentication");
  return Promise.reject(
    new AppError(ErrorType.LOGIN_FAIL, "Error login third party authentication")
  );
};

export const userLoginThirdPartyWithRedirect = async (type: string) => {
  try {
    try {
      if (auth.currentUser) {
        await signOut();
      }
    } catch (err) {
      console.log("ERROR SIGN OUT BEFORE LOGIN REDIRECT");
    }
    signInWithRedirect(type);
  } catch (error: any) {
    if (error && error?.name === "App Error") return Promise.reject(error);
    else {
      Sentry.captureException(error);
      return Promise.reject(
        new AppError(ErrorType.LOGIN_FAIL, error.message, error)
      );
    }
  }
};
export const userLoginFirebaseToken = (data: {
  provider: string;
  id_token: string;
}) => {
  return callApi<{ token: string; user: any }>({
    url: `auth/login_3rd_party`,
    method: "POST",
    data,
  });
};

export const verifyCode = async (verificationUserCode: string) => {
  try {
    const confirmationResult = window.confirmationResult;
    if (confirmationResult) {
      const result = await confirmationResult.confirm(verificationUserCode);
      if (result && result.user) {
        const info = await result.user.getIdTokenResult();
        if (info) {
          const token = get(info, "token");
          if (token) {
            //Update firebase token to store user
            store.dispatch(userUpdateFirebaseToken(token));
            return Promise.resolve(token);
          }
        }
      }
    }
    return Promise.reject(
      new AppError(ErrorType.VERIFY_FAIL, "Error Verify Code")
    );
  } catch (error: any) {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
};

export const userPhoneLogin = async (
  phoneNumber: string,
  appVerifier?: any
) => {
  const verified = appVerifier || window.recaptchaVerifier;
  return await signInWithPhoneNumber(phoneNumber, verified)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      return {
        status: true,
        message: "",
        statusCode: "",
      };
      // ...
    })
    .catch((error) => {
      // Error; SMS not sent
      // ...
      Sentry.captureException(error);
      if (error.code === "auth/too-many-requests") {
        return {
          status: false,
          message: "Quá nhiều request. Vui lòng thử lại sau!",
          statusCode: error.code,
        };
      }
      return {
        status: false,
        message: error.message,
        statusCode: error.code,
      };
    });
};

export const sendOTP = async (
  phone: string,
  type: "REGISTER" | "RESET_PASS" | "VERIFY_PHONE" | "SET_PASS" | "CHANGE_PASS"
) => {
  const payload = encode(
    JSON.stringify({
      phone,
      type,
    }),
    ENC_KEY || ""
  );
  return callApi({
    url: `auth/send_otp`,
    method: "POST",
    data: {
      payload,
    },
  });
};

export const verifyOTP = async (
  phone: string,
  type: "REGISTER" | "RESET_PASS" | "VERIFY_PHONE",
  otp: string
) => {
  const payload = encode(
    JSON.stringify({
      phone,
      type,
      otp,
    }),
    ENC_KEY || ""
  );
  return callApi({
    url: `auth/verify_otp`,
    method: "POST",
    data: {
      payload,
    },
  });
};

export const userRegisterAccount = (data: {
  otp: string;
  password: string;
  user_role: string;
  optionData?: { [key: string]: any };
}) => {
  const { otp, password, user_role, optionData } = data;
  return callApi<{ token: string; user: any }>({
    url: `auth/register_otp`,
    method: "POST",
    data: {
      otp,
      password,
      user_role,
      ...optionData,
    },
  });
};

export const userRegisterAccountThirdParty = (data: {
  id_token: string;
  user_role: string;
  password: string;
  optionData?: { [key: string]: any };
}) => {
  const { id_token, user_role, optionData, password } = data;
  const provider = get(store.getState(), "user.firebaseProvider");
  if (!provider || !provider.length) {
    return Promise.reject(
      new AppError(
        ErrorType.MISSING_FIREBASE_PROVIDER,
        "MISSING_FIREBASE_PROVIDER"
      )
    );
  }
  return callApi<{ token: string; user: any }>({
    url: `auth/register_3rd_party`,
    method: "POST",
    data: {
      id_token,
      provider,
      user_role,
      password,
      ...optionData,
    },
  });
};

export const userCheckPhoneExists = (phoneNumber: string) => {
  return callApi<{ is_existed: boolean; is_verified?: boolean }>({
    url: `auth/check_account`,
    method: "POST",
    data: {
      phone: phoneNumber,
    },
  });
};

export const userGetProfile = () => {
  return callApi<{
    user: any;
    hidden_fields: string[];
    social_accounts: any[];
  }>({
    url: `auth/profile`,
    method: "GET",
  });
};

export const userUpdateProfile = (data: any) => {
  return callApi({
    url: `auth/profile`,
    method: "POST",
    data,
  });
};

export const userLinkProfile = async (type: string) => {
  try {
    try {
      if (auth.currentUser) {
        await signOut();
      }
    } catch (err) {
      console.log("ERROR SIGN OUT BEFORE LOGIN REDIRECT");
    }
    const result = await signInWithPopup(type);
    return linkAccountWithFirebaseResult(result, type);
  } catch (error) {
    Sentry.captureException(error);
    return Promise.reject(
      new AppError(
        ErrorType.Unexpected,
        "Error link third party authentication",
        error
      )
    );
  }
};
export const linkAccountWithFirebaseResult = async (
  result: UserCredential,
  type?: string
) => {
  const info = await result.user.getIdTokenResult();
  const provider = (() => {
    if (type && type.length) return type;
    switch (result.providerId) {
      case "google.com":
        return LoginType.GOOGLE;
      case "facebook.com":
        return LoginType.FACEBOOK;
      default:
        return "";
    }
  })();
  if (info) {
    const token = get(info, "token");
    if (token) {
      //Update firebase token to store user
      store.dispatch(userUpdateFirebaseToken(token));
      store.dispatch(updateFirebaseProvider(provider));
      await callApi({
        url: `auth/social_account/link`,
        method: "POST",
        data: {
          provider: provider,
          id_token: token,
        },
      });
      return Promise.resolve(provider);
    }
  }
  Sentry.captureException("Error linked third party authentication");
  return Promise.reject(
    new AppError(
      ErrorType.LOGIN_FAIL,
      "Error linked third party authentication"
    )
  );
};

export const userLinkProfileRedirect = async (type: string) => {
  try {
    try {
      if (auth.currentUser) {
        await signOut();
      }
    } catch (err) {
      console.log("ERROR SIGN OUT BEFORE LOGIN REDIRECT");
    }
    sessionStorage.setItem("linkedProfile", "true");
    signInWithRedirect(type);
  } catch (error) {
    console.log("error catch userLinkProfile", error);
    Sentry.captureException(error);
    return Promise.reject(
      new AppError(
        ErrorType.Unexpected,
        "Error link third party authentication",
        error
      )
    );
  }
};

export const userUnLinkProfile = (type: string) => {
  return callApi({
    url: `auth/social_account/unlink`,
    method: "POST",
    data: {
      provider: type,
    },
  });
};

export const userLogout = async () => {
  try {
    if (auth.currentUser) {
      await auth.signOut();
    }
    await callApi({
      url: `auth/logout`,
      method: "POST",
    });
    store.dispatch(userSignOut());
    clearCache();
    Promise.resolve();
  } catch (error: any) {
    if (error && error?.name === "App Error") return Promise.reject(error);
    else {
      Sentry.captureException(error);
      return Promise.reject(new AppError(ErrorType.LOGIN_FAIL, error.message));
    }
  }
};

export const userChangePassword = (
  oldPassword: string | null,
  newPassword: string,
  otp: string
) => {
  return callApi({
    url: `auth/change_password_otp`,
    method: "POST",
    data: {
      old_password: oldPassword,
      new_password: newPassword,
      otp,
    },
  });
};

export const userVerifyPhone = (phone: string, otp: string) => {
  return callApi({
    url: `auth/verify_phone_otp`,
    method: "POST",
    data: {
      phone,
      otp,
    },
  });
};

export const userResetPassword = (
  password: string,
  confirmationPassword: string,
  otp: string,
  phone: string
) => {
  return callApi({
    url: `auth/reset_password_otp`,
    method: "POST",
    data: {
      password_1: password,
      password_2: confirmationPassword,
      otp,
      phone,
    },
  });
};
