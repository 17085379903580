import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ErrorMessage, ErrorType, IApp } from "features/app/type";
import { get } from "lodash";

const initialState: IApp = {
  isLoaded: false,
  errorCode: ErrorType.NO_ERROR,
  deviceId: "",
  isShowToast: false,
  errorMsg: "",
  errorMsgShow: "",
  searchKey: "",
  popstate: false,
  isOpenRegister: false,
  initialHistoryPoint: 0,
  refreshBottomMenu: 0,
} as IApp;

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appInit: (state) => {
      state.isLoaded = false;
    },
    appInitSuccess: (state) => {
      state.isLoaded = true;
    },
    appInitFail: (state, action: PayloadAction<{ errorMsg: string }>) => {
      state.isLoaded = true;
      state.errorCode = ErrorType.INIT_APP_FAIL;
      state.errorMsg = action.payload.errorMsg;
      state.errorMsgShow = ErrorMessage[ErrorType.INIT_APP_FAIL];
    },
    openRegister: (state) => {
      state.isOpenRegister = true;
    },
    closeRegister: (state) => {
      state.isOpenRegister = false;
    },
    appError: (
      state,
      action: PayloadAction<{
        errorCode: string;
        errorMsg: string;
        errorMsgShow?: string;
      }>
    ) => {
      const { errorCode, errorMsg, errorMsgShow } = action.payload;
      state.errorCode = errorCode;
      state.errorMsg = errorMsg;
      state.errorMsgShow = errorMsgShow
        ? errorMsgShow
        : get(ErrorMessage, errorCode, "Lỗi không xác định");
    },
    appSetDevice: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload;
    },
    appSetInitialHistoryPoint: (state, action: PayloadAction<number>) => {
      state.initialHistoryPoint = action.payload;
    },
    appShowToast: (state) => {
      state.isShowToast = true;
    },
    appHideToast: (state) => {
      state.isShowToast = false;
    },
    appSearch: (state, action: PayloadAction<{ searchKey: string }>) => {
      state.searchKey = action.payload.searchKey;
    },
    setPopstate: (state, action: PayloadAction<boolean>) => {
      state.popstate = action.payload;
    },
    refreshBottomMenu: (state) => {
      state.refreshBottomMenu = state.refreshBottomMenu + 1;
    },
  },
  extraReducers: {},
});

export const {
  appInit,
  appInitSuccess,
  appInitFail,
  appError,
  appSetDevice,
  appSetInitialHistoryPoint,
  appShowToast,
  appHideToast,
  appSearch,
  setPopstate,
  openRegister,
  closeRegister,
  refreshBottomMenu,
} = appSlice.actions;
export const selectAppStatus = (state: RootState) => state.app.isLoaded;
export default appSlice.reducer;
