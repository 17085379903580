import { getNullDefault } from "lib/common";

export enum UserType {
  KOL = "influencer",
  USER = "customer",
  MANAGER = "manager",
}

export interface Category {
  id: string;
  name: string;
  label: string;
}
export interface IUserInfo {
  id: string;
  lastLogin: string;
  username: string;
  phone: string;
  email: string;
  userRole: string;
  fullName: string;
  birthDate: string;
  province: string;
  address: string;
  avatar: string;
  influzeeId: string;
  isVerified: boolean;
  isActive: boolean;
  gender: string;
  website: string;
  isPasswordSet: boolean;
  categories: Category[];
}

export interface Menu {
  icon: string;
  label: string;
  path: string;
}

export interface TopMenu {
  icon: string;
  label: string;
  action: string;
}
export class IUserInfo {
  constructor(value: any) {
    this.id = getNullDefault(value, "id", "") + "";
    this.lastLogin = getNullDefault(value, "last_login", "");
    this.username = getNullDefault(value, "username", "");
    this.phone = getNullDefault(value, "phone", "");
    this.email = getNullDefault(value, "email", "");
    this.userRole = getNullDefault(value, "user_role", "");
    this.fullName = getNullDefault(value, "full_name", "");
    this.birthDate = getNullDefault(value, "birth_date", "");
    this.province = getNullDefault(value, "province", "");
    this.address = getNullDefault(value, "address", "");
    this.avatar = getNullDefault(value, "avatar", "");
    this.influzeeId = getNullDefault(value, "influzee_id", "");
    this.isVerified = getNullDefault(value, "is_verified", false);
    this.isActive = getNullDefault(value, "is_active", false);
    this.gender = getNullDefault(value, "gender", "other");
    this.website = getNullDefault(value, "website", "");
    this.isPasswordSet = getNullDefault(value, "is_password_set", false);
    this.categories = getNullDefault(value, "categories", []);
  }
}
export interface IUser {
  isLoggedIn: boolean;
  errorMsg?: string;
  errorCode?: number;
  accessToken?: string;
  firebaseToken?: string;
  firebaseProvider?: string;
  userMenu: Menu[];
  topMenu: TopMenu[];
  topMobileMenu: TopMenu[];
  user: IUserInfo | null | undefined;
}
