import React from "react";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import CopyableInput from "./copyable-input";
import ConnectSocial from "./connect-social";
import AddNew from "./add-new";
import Logo from "./logo";
import PreviewImage from "./preview-image";
import isEmpty from "lodash/isEmpty";
import { IProfile } from "features/profile/type";
import openLinkConfirm from "components/Dialog/open-link-confirm";
import MailIcon from "components/icons/Mail";
import { EditProfileFlow } from "lib/enums";
import { defaultBio } from "lib/utils";

interface ProfileIntroduceTabProps {
  nonEditable?: boolean;
  profile?: IProfile | null;
  email?: string;
  setShowEditProfileModal?: (V: EditProfileFlow) => void;
  onSocialClick?: (platform: string) => void;
}

const ProfileIntroduceTab: React.FC<ProfileIntroduceTabProps> = ({
  profile,
  email,
  nonEditable = false,
  /* eslint-disable @typescript-eslint/no-empty-function*/
  setShowEditProfileModal = () => {},
  onSocialClick = () => {},
}) => {
  const socials = profile?.socials.map((obj: any, i) => ({
    type: obj.platform,
    value: obj.no_followers,
    url: obj.url,
  }));
  const categories = profile?.categories.map((obj: any, i) => obj.name);
  const onConnectSocialClick = (url: string, platform: string) => {
    const { close } = openLinkConfirm({
      type: "social",
      url,
      onOk: () => {
        onSocialClick(platform);
      },
      onBottomClick: () => {
        close();
      },
    });
  };
  return (
    <>
      <div className="w-full flex flex-col mb-5 sm:mb-7">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full sm:w-1/2 flex flex-col mr-4 mb-5 sm:mb-0">
            <div className="flex justify-between items-center mb-4">
              <div className="font-bold text-lg">Giới thiệu</div>
              {/* {!nonEditable && ( */}
              {/*   <div */}
              {/*     className="hidden sm:block text-sm text-medium-blue cursor-pointer hover:text-bright-blue" */}
              {/*     onClick={setShowEditProfileModal.bind( */}
              {/*       null, */}
              {/*       EditProfileFlow.INFO, */}
              {/*       "edit-introduce" */}
              {/*     )} */}
              {/*   > */}
              {/*     Chỉnh sửa */}
              {/*   </div> */}
              {/* )} */}
            </div>
            <div className="sm:px-5 sm:py-3 sm:bg-pale-grey sm:rounded-xl h-full">
              <p
                className={classnames(
                  "line-clamp-3 text-sm text-greyish-brown whitespace-pre-line",
                  {
                    "opacity-50": !profile?.bio,
                  }
                )}
              >
                {profile?.bio ||
                  (nonEditable
                    ? defaultBio(profile?.fullName || "")
                    : "Vui lòng nhập thông tin giới thiệu về bản thân, sở thích cá nhân hoặc thông tin khác")}
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 flex flex-col ml-0 sm:ml-4">
            <div className="flex justify-between items-center mb-2 sm:mb-4">
              <div className="hidden sm:block font-bold text-lg opacity-0">
                Kết nối
              </div>
              {!nonEditable && (
                <div
                  className="hidden sm:block text-sm text-medium-blue cursor-pointer hover:text-bright-blue"
                  onClick={setShowEditProfileModal.bind(
                    null,
                    EditProfileFlow.INFO,
                    "edit-personal-info"
                  )}
                >
                  Chỉnh sửa
                </div>
              )}
            </div>
            <div className="flex-grow flex flex-col justify-between">
              <CopyableInput
                text={
                  (process.env.NEXT_PUBLIC_DOMAIN || "influzee.com/") +
                  profile?.influzeeID
                }
                placeholder={
                  nonEditable ? "" : "Vui lòng tạo influzee ID của bạn"
                }
                className="mt-2 first:mt-0"
                copyText={
                  "https://" +
                  (process.env.NEXT_PUBLIC_DOMAIN || "influzee.com/") +
                  profile?.influzeeID
                }
                prefixIcon={<Logo size="sm" />}
              />
              <CopyableInput
                text={email || profile?.contactEmail || ""}
                placeholder={nonEditable ? "" : "Vui lòng nhập email của bạn"}
                className="mt-2 first:mt-0"
                prefixIcon={
                  <MailIcon
                    className="text-greyish-brown"
                    style={{ fontSize: "1.125rem" }}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-5 sm:mb-7">
        <div className="flex justify-between items-center mb-2 sm:mb-4">
          <div className="font-bold text-lg">Mạng xã hội</div>
          {!nonEditable && (
            <div
              className="hidden sm:block text-sm text-medium-blue cursor-pointer hover:text-bright-blue"
              onClick={setShowEditProfileModal.bind(
                null,
                EditProfileFlow.SOCIAL,
                "edit-social"
              )}
            >
              Chỉnh sửa
            </div>
          )}
        </div>
        <div className="flex flex-wrap sm:h-fit-content -m-2 sm:-m-1">
          {isEmpty(socials) ? (
            nonEditable ? null : (
              <>
                {[1, 2].map((i) => (
                  <AddNew
                    key={i}
                    className="w-1/2 sm:w-1/4 p-2 sm:py-4 sm:px-1 h-38"
                    onClick={setShowEditProfileModal.bind(
                      null,
                      EditProfileFlow.SOCIAL,
                      "edit-social"
                    )}
                  >
                    <div className="text-xs text-grey-80 mt-1">
                      Thêm mạng xã hội
                    </div>
                  </AddNew>
                ))}
              </>
            )
          ) : (
            socials?.map((i) => (
              <ConnectSocial
                className="w-1/2 sm:w-1/4 p-2 sm:p-1"
                key={i.type}
                onClick={onConnectSocialClick.bind(null, i.url, i.type)}
                {...i}
              />
            ))
          )}
        </div>
      </div>
      <div className="flex flex-col mb-5 sm:mb-7">
        <div className="flex justify-between items-center mb-3">
          <div className="font-bold text-lg">Link cá nhân</div>
          {!nonEditable && (
            <div
              className="hidden sm:block text-sm text-medium-blue cursor-pointer hover:text-bright-blue"
              onClick={setShowEditProfileModal.bind(
                null,
                EditProfileFlow.SOCIAL,
                "edit-link"
              )}
            >
              Chỉnh sửa
            </div>
          )}
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 -my-2 sm:my-0">
          {isEmpty(profile?.links)
            ? nonEditable
              ? null
              : [1, 2].map((i) => (
                  <div key={i} className="w-full my-2 sm:my-0 sm:w-1/2 sm:mx-3">
                    <CopyableInput
                      key={i}
                      className="mt-2 first:mt-0 cursor-pointer"
                      placeholder={"Vui lòng nhập link cá nhân của bạn"}
                      onClick={setShowEditProfileModal.bind(
                        null,
                        EditProfileFlow.SOCIAL,
                        "edit-link"
                      )}
                    />
                  </div>
                ))
            : profile?.links.map((i, index) =>
                !i && !nonEditable ? (
                  <div
                    key={index}
                    className="w-full my-2 sm:my-0 sm:w-1/2 sm:mx-3"
                  >
                    <CopyableInput
                      key={i}
                      className="mt-2 first:mt-0 cursor-pointer"
                      placeholder={"Vui lòng nhập link cá nhân của bạn"}
                      onClick={setShowEditProfileModal.bind(
                        null,
                        EditProfileFlow.SOCIAL,
                        "edit-link"
                      )}
                    />
                  </div>
                ) : (
                  <div
                    key={index}
                    className="w-full my-2 sm:my-0 sm:w-1/2 sm:mx-3"
                  >
                    <CopyableInput
                      key={index}
                      className="mt-2 first:mt-0"
                      text={i}
                    />
                  </div>
                )
              )}
        </div>
      </div>
      <div className="flex flex-col mb-5 sm:mb-7">
        <div className="flex justify-between items-center mb-3">
          <div className="font-bold text-lg">Hình ảnh và Video</div>
          {!nonEditable && (
            <div
              className="hidden sm:block text-sm text-medium-blue cursor-pointer hover:text-bright-blue"
              onClick={setShowEditProfileModal.bind(
                null,
                EditProfileFlow.INFO,
                "edit-image"
              )}
            >
              Chỉnh sửa
            </div>
          )}
        </div>
        <div className="flex flex-row -mx-2 sm:-mx-1 overflow-x-scroll sm:overflow-x-hidden">
          {isEmpty(profile?.images) ? (
            nonEditable ? null : (
              <>
                {[1, 2].map((i) => (
                  <div
                    key={i}
                    className="w-30 sm:w-1/5 flex-shrink-0 sm:flex-shrink-1 px-2 sm:px-1"
                  >
                    <div className="aspect-w-1 aspect-h-1">
                      <div
                        onClick={setShowEditProfileModal.bind(
                          null,
                          EditProfileFlow.INFO,
                          "edit-image"
                        )}
                        className="flex flex-col justify-center items-center bg-pale-grey rounded-lg hover:bg-pale-grey-hover cursor-pointer z-10"
                      >
                        <AddIcon
                          style={{
                            fontSize: "1.75rem",
                            color: "#808080",
                          }}
                        />
                        <div className="text-xs text-grey-80 mt-1">
                          Thêm hình ảnh
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )
          ) : (
            profile?.images.map((i, index) => (
              <div
                key={index}
                className="w-30 sm:w-1/5 flex-shrink-0 sm:flex-shrink-1 px-2 sm:px-1"
              >
                <div className="aspect-w-1 aspect-h-1">
                  <PreviewImage src={i} />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="flex flex-col mb-5 sm:mb-7">
        <div className="flex justify-between items-center mb-3">
          <div className="font-bold text-lg">Lĩnh vực hoạt động</div>
          {!nonEditable && (
            <div
              className="hidden sm:block text-sm text-medium-blue cursor-pointer hover:text-bright-blue"
              onClick={setShowEditProfileModal.bind(
                null,
                EditProfileFlow.INFO,
                "edit-category"
              )}
            >
              Chỉnh sửa
            </div>
          )}
        </div>
        <div className="flex sm:h-fit-content flex-wrap -m-0.5 sm:-m-1 sm:flex-nowrap overflow-x-scroll">
          {isEmpty(categories) ? (
            nonEditable ? null : (
              <>
                {[1, 2].map((i) => (
                  <div
                    key={i}
                    className="min-w-max z-10 text-greyish-brown text-xs p-0.5 sm:p-1"
                  >
                    <div
                      className="rounded-lg bg-pale-grey p-2 px-4 flex justify-center items-center hover:bg-pale-grey-hover cursor-pointer"
                      onClick={setShowEditProfileModal.bind(
                        null,
                        EditProfileFlow.INFO,
                        "edit-category"
                      )}
                    >
                      Thêm lĩnh vực
                      <AddIcon
                        style={{
                          marginLeft: "0.25rem",
                          fontSize: "1rem",
                          color: "#808080",
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )
          ) : (
            categories?.map((i, index) => (
              <div
                key={index}
                className="min-w-max z-10 text-greyish-brown text-xs p-0.5 sm:p-1"
              >
                <div className="rounded-lg bg-pale-grey p-2">{i}</div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileIntroduceTab;
