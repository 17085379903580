import callApi from "lib/apiService";
import { STATS_EVENT } from "lib/constants";
import merge from "lodash/merge";
import queryString from "query-string";

export const getMyProfiles = () => {
  return callApi<{ profiles: Array<any> }>({
    url: `profile/my_profiles`,
    method: "GET",
  });
};

export const updateProfileDisplay = (profileID?: number, data: any = {}) => {
  return callApi<{ profile: any }>({
    url: `profile/${profileID ? profileID : ""}/display`,
    method: "POST",
    data,
  });
};

export const updateProfileSocialLink = (profileID?: number, data: any = {}) => {
  return callApi<{ profile: any }>({
    url: `profile/${profileID ? profileID : ""}/social-link`,
    method: "POST",
    data,
  });
};

export const createOrUpdateProfileTab = (
  profileID?: number,
  tabID = "",
  data: any = {}
) => {
  if (tabID) {
    return callApi<{ profile: any }>({
      url: `profile/${profileID ? profileID : ""}/tab/${tabID}`,
      method: "POST",
      data,
    });
  } else {
    return callApi<{ profile: any }>({
      url: `profile/${profileID ? profileID : ""}/tab`,
      method: "POST",
      data,
    });
  }
};

export const getProfileList = (
  filter: any = {},
  sort: any = {},
  page = 1,
  per_page = 10
) => {
  return callApi<{
    profiles: Array<any>;
    page: number;
    per_page: number;
    total: number;
  }>({
    url: `profile/list`,
    method: "POST",
    data: {
      filter,
      sort,
      page,
      per_page,
    },
  });
};

export const searchProfile = (search: string, platform?: string) => {
  return callApi<{ profiles: Array<any>; page: number; per_page: number }>({
    url: `profile/list`,
    method: "POST",
    data: {
      filter: merge(
        {
          full_name: search,
        },
        platform ? { platform: [platform] } : {}
      ),
      sort: {
        full_name: 1,
        updated_at: -1,
      },
      page: 1,
      per_page: 10,
    },
  });
};

export const getProfileById = (profileID = "") => {
  return callApi<{ profile: any }>({
    url: `profile/${profileID}/info`,
    method: "GET",
  });
};

export const changeProfileStatus = (profileID: number, is_private = false) => {
  return callApi<{ profile: any }>({
    url: `profile/${profileID}/status`,
    method: "POST",
    data: {
      is_private,
    },
  });
};

export const getProfileByInfluzeeId = (profileInfluzeeID = "") => {
  return callApi<{ profile: any }>({
    url: `profile/influzee/${profileInfluzeeID}`,
    method: "GET",
  });
};

export const getSensityInfoByInfluzeeId = (profileInfluzeeID = "") => {
  return callApi<{ profile: { contact_email: string; contact_phone: string } }>(
    {
      url: `profile/influzee/${profileInfluzeeID}`,
      method: "GET",
      params: {
        p: "1",
      },
    }
  );
};

export const getProfileTotalStats = (params: {
  start_date?: string;
  end_date?: string;
  events?: string[];
  // platform?: string[];
  // links?: string[];
  // page?: number;
  // per_page?: number;
  // get_total?: number;
  // tab_id?: string;
}) => {
  return callApi<{ stats: { event: string; total: number }[] }>({
    url: `stats`,
    method: "GET",
    params: {
      ...params,
      get_total: 1,
    },
    // paramsSerializer: (params) => {
    //   return queryString.stringify(params, {
    //     arrayFormat: "separator",
    //     arrayFormatSeparator: "|",
    //   });
    // },
  });
};

export const getProfileViewStats = (params: {
  start_date?: string;
  end_date?: string;
}) => {
  return callApi<{
    stats: {
      event: string;
      total: number;
      vnt_date: string;
    }[];
  }>({
    url: `stats`,
    method: "GET",
    params: {
      ...params,
      events: [STATS_EVENT.profileView],
    },
  });
};

export const getSocialClickStats = (params: {
  start_date?: string;
  end_date?: string;
  platforms?: string[];
}) => {
  return callApi<{
    stats: {
      event: string;
      total: number;
      platform: string;
      vnt_date: string;
    }[];
  }>({
    url: `stats`,
    method: "GET",
    params: {
      ...params,
      events: [STATS_EVENT.socialClick],
    },
  });
};

export const getLinkClickStats = (params: {
  start_date?: string;
  end_date?: string;
  tab_id?: string;
  links?: string[];
  page?: number;
  per_page?: number;
}) => {
  return callApi<{
    stats: {
      event: string;
      total: number;
      link: string;
      link_hash: string;
      vnt_date: string;
    }[];
  }>({
    url: `stats`,
    method: "GET",
    params: {
      ...params,
      events: [STATS_EVENT.linkClick],
    },
  });
};
