//lib/firebase.ts
import { LoginType } from "../features/user/api";
import { initializeApp } from "firebase/app";
import {
  ApplicationVerifier,
  ConfirmationResult,
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { AppError, ErrorType } from "../features/app/type";

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier;
    confirmationResult?: ConfirmationResult;
  }
}
const clientCredentials = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};
initializeApp(clientCredentials);
const auth = getAuth();
auth.languageCode = "vi";

const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const appleAuthProvider = new OAuthProvider("apple.com");
appleAuthProvider.addScope("email");
appleAuthProvider.addScope("name");

const getProvider = (type: string) => {
  switch (type) {
    case LoginType.GOOGLE:
      return googleAuthProvider;
    case LoginType.FACEBOOK:
      return facebookAuthProvider;
    case LoginType.APPLE:
      return appleAuthProvider;
    default:
      return null;
  }
};

const signInWithPopupFn = (type: string) => {
  const provider = getProvider(type);
  if (provider) return signInWithPopup(auth, provider);
  return Promise.reject(
    new AppError(ErrorType.LOGIN_FAIL, "Error login third party authentication")
  );
};

const signInWithRedirectFn = (type: string) => {
  const provider = getProvider(type);
  if (provider) return signInWithRedirect(auth, provider);
  return Promise.reject(
    new AppError(ErrorType.LOGIN_FAIL, "Error login third party authentication")
  );
};

const signInWithPhoneNumberFn = (
  phoneNumber: string,
  appVerifier: ApplicationVerifier
) => {
  return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
};

const signOutFn = () => {
  return signOut(auth);
};

const getRedirectResultFn = () => getRedirectResult(auth);

export {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
  appleAuthProvider,
  signInWithPopupFn as signInWithPopup,
  signOutFn as signOut,
  signInWithPhoneNumberFn as signInWithPhoneNumber,
  signInWithRedirectFn as signInWithRedirect,
  getRedirectResultFn as getRedirectResult,
};

export const recaptchaVerifierInvisible = async (btnSubmitId: string) => {
  function onSignInSubmit() {
    // TODO(you): Implement
    console.log("SignInSubmit", window.recaptchaVerifier);
    Promise.resolve(window.recaptchaVerifier);
  }

  signOutFn();
  // [START auth_phone_recaptcha_verifier_invisible]
  setTimeout(() => {
    const element = document.getElementById(btnSubmitId);
    if (element) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        btnSubmitId,
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            onSignInSubmit();
          },
        },
        auth
      );
    }
  }, 500);
};

