import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const ChatActive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M20 5.889v10.667c0 .49-.398.888-.889.888H12.89L8.444 21v-3.556H4.89a.889.889 0 01-.889-.888V5.889A.887.887 0 014.889 5H19.11c.491 0 .889.398.889.889z"
        stroke="#01334c"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ChatActive;
