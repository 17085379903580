import React from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import get from "lodash/get";
// import Image from "next/image";
import Link from "next/link";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MuiModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearIcon from "@material-ui/icons/Clear";
import PhotoCameraIcon from "components/icons/PhotoCamera";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MailIcon from "components/icons/Mail";
import PhoneIcon from "components/icons/Phone";
import ChatIcon from "components/icons/Chat";
import Skeleton from "@material-ui/lab/Skeleton";
import { useAppDispatch, useAppSelector } from "store";
import { useTracking } from "context/trackers";
import ButtonIcon from "./button-icon";
import Button from "./button";
import Tabs, { Tab } from "./tab-panel";
import ProfileIntroduceTab from "./profile-introduce-tab";
import ProfileCustomTab from "./profile-custom-tab";
import Menu from "./menu";
import MenuItem from "./menu-item";
import CopyableInput from "./copyable-input";
import ComingSoon from "components/Dialog/AlertModal";
import { joinWithSeperator } from "lib/utils";
// import useTrackingStats from "hooks/useTrackingStats";

import { IProfile } from "features/profile/type";
import { getSensityInfoAsync } from "features/profile/profileSlice";

interface Props {
  isShow: boolean;
  handleClose: () => void;
  profile?: IProfile | null;
}

const TAB_INTRO = { name: "Giới thiệu", id: "profile" };
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const ProfileModal: React.FC<Props> = ({ isShow, handleClose, profile }) => {
  const [activeTab, setActiveTab] = React.useState<string>("profile");
  const userId = useSelector((state) => get(state, "user.user.id"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [callAnchorEl, setCallAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [animationTimeline] = React.useState<gsap.core.Timeline>(() =>
    gsap.timeline()
  );
  const textCopiedRef = React.useRef<HTMLSpanElement>(null);
  const glowCopiedText = React.useCallback(() => {
    animationTimeline.clear();
    animationTimeline
      .set(textCopiedRef.current, { opacity: 0, y: "100%" })
      .to(textCopiedRef.current, { opacity: 1, y: 0, duration: 0.5 })
      .to(textCopiedRef.current, { opacity: 0, y: "-100%", duration: 1 }, 2)
      .set(textCopiedRef.current, { opacity: 0, y: "100%" });
  }, [animationTimeline]);

  const onMoreActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreMenuClick = (action: string) => {
    if (action === "favorite") {
      // TODO: Handle favorite profile
      return;
    }
    if (action === "share") {
      // TODO: Handle share profile
      return;
    }
    if (action === "report") {
      // TODO: Handle report profile
      return;
    }
  };
  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };
  const handleTabChange = (_: unknown, value: unknown) => {
    setActiveTab(value as string);
  };

  const classes = useStyles();

  const isOwner = userId === get(profile, "id");

  const tabs = [1, 2, 3, 4, 5, 6].reduce<any[]>((acc, i) => {
    const tab = profile?.tabs?.find((v) => v.position === i);
    if (tab) {
      return [...acc, tab];
    }
    if (acc.findIndex((v) => v.id === "profile") < 0) {
      return [...acc, TAB_INTRO];
    } else {
      return acc;
    }
  }, []);

  React.useEffect(() => {
    if (isShow) {
      setActiveTab(get(tabs, [0, "id"]));
    }
  }, [isShow]);

  const onCallButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCallAnchorEl(event.currentTarget);
    },
    []
  );

  const dispatch = useAppDispatch();
  const sensityInfo = useAppSelector(
    (state) => state.profile.sensityInfos[profile?.influzeeID || ""]
  );
  React.useEffect(() => {
    if (isShow) {
      if (!sensityInfo && profile) {
        dispatch(getSensityInfoAsync(profile.influzeeID));
      }
    }
  }, [isShow]);

  const handleCloseCallMenu = () => {
    setCallAnchorEl(null);
  };

  /* Tracking GA modal open */
  const { modalView } = useTracking();
  React.useEffect(() => {
    if (isShow) {
      if (profile && profile.influzeeID) {
        modalView(profile?.influzeeID);
      }
    }
  }, [isShow]);
  /* End Tracking GA modal open */

  /* Tracking Stats */
  // const { trackingProfileView, trackingLinkClick, trackingSocialClick } =
  //   useTrackingStats();
  // React.useEffect(() => {
  //   if (isShow) {
  //     if (profile && profile.userId) {
  //       trackingProfileView({ userID: profile?.userId });
  //     }
  //   }
  // }, [isShow]);
  // const onSocialLinkClick = (userID: string | undefined, platform: string) => {
  //   if (userID) {
  //     trackingSocialClick({
  //       userID,
  //       platform,
  //     });
  //   }
  // };
  // const onTabItemClick = (
  //   userID: string | undefined,
  //   activeTab: string,
  //   link: string
  // ) => {
  //   if (userID) {
  //     trackingLinkClick({
  //       userID,
  //       link,
  //       tabID: activeTab,
  //     });
  //   }
  // };
  /* End Tracking Stats */

  const openComingSoonPopup = React.useCallback(() => {
    const { close } = ComingSoon({
      type: "coming-soon",
      title: "Coming Soon",
      description: "Chức năng này đang được phát triển",
      bottomText: "Uh! OK nha",
      onBottomClick: () => {
        close();
      },
    });
  }, []);

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isShow}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={isShow}>
        <>
          <div className="bg-white w-modal-2xl rounded-xl overflow-hidden focus-visible:outline-none relative flex flex-col scroll-modal">
            <PerfectScrollbar className="inf-scrollbar">
              <div
                className="absolute top-3 right-3 w-6 h-6 bg-black rounded-full flex justify-center items-center cursor-pointer hover:opacity-100 opacity-25 z-10"
                onClick={handleClose}
              >
                <ClearIcon style={{ color: "#fff", fontSize: "1rem" }} />
              </div>
              <div
                className="w-full h-auto relative overflow-hidden"
                style={{ padding: "40% 0 0 0" }}
              >
                {profile?.coverImage && (
                  <img
                    className="absolute top-0 left-0 w-full h-auto"
                    src={profile?.coverImage}
                    alt="cover image"
                  />
                )}
                {isOwner && (
                  <div className="w-24 absolute bottom-3 right-12">
                    <Link href="/quan-ly">
                      <Button infType="secondary" infSize="s">
                        <PhotoCameraIcon
                          className="mr-1"
                          style={{ fontSize: "1.25rem" }}
                        />
                        Chỉnh sửa
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
              <div className="w-full px-12 flex -mt-16 mb-8">
                <div className="w-40">
                  <div
                    className="w-full h-auto relative"
                    style={{ padding: "100% 0 0 0" }}
                  >
                    <img
                      className="absolute top-0 left-0 w-full h-auto rounded-xl filter drop-shadow-xl"
                      src={`${
                        profile?.avatar?.length
                          ? profile?.avatar
                          : "/assets/icon/person-avatar.png"
                      }`}
                      alt="profile avatar"
                    />
                    {isOwner && (
                      <div className="absolute bottom-3 right-3">
                        <Link href="/quan-ly">
                          <ButtonIcon
                            infType="secondary"
                            infSize="s"
                            icon={
                              <PhotoCameraIcon
                                style={{ fontSize: "1.25rem" }}
                              />
                            }
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-grow flex flex-col justify-between mt-16 pt-1 pl-4">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <h2 className="text-lg font-bold">
                        {profile?.fullName ?? ""}
                      </h2>
                      <CheckCircleIcon
                        style={{ fontSize: "0.75rem" }}
                        className="ml-1 text-dark-blue"
                      />
                    </div>
                    <div className="text-xs text-grey-80">
                      {joinWithSeperator(
                        " | ",
                        profile?.job?.join(", ") || "",
                        profile?.province || ""
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div
                      className={classnames("w-32 mr-3", {
                        "cursor-not-allowed": !profile?.isChatEnabled,
                      })}
                    >
                      {/* <Link href="/chat"> */}
                      {/*   <Button */}
                      {/*     infType="secondary" */}
                      {/*     disabled={!profile?.isChatEnabled} */}
                      {/*   > */}
                      {/*     <span className="w-5 h-5 mr-2 flex items-center"> */}
                      {/*       <ChatIcon style={{fontSize: "1.25rem"}} /> */}
                      {/*     </span> */}
                      {/*     Chat */}
                      {/*   </Button> */}
                      {/* </Link> */}
                      <Button
                        infType="secondary"
                        disabled={!profile?.isChatEnabled}
                        onClick={openComingSoonPopup}
                      >
                        <span className="w-5 h-5 mr-2 flex items-center">
                          <ChatIcon style={{ fontSize: "1.25rem" }} />
                        </span>
                        Chat
                      </Button>
                    </div>
                    <div className="w-32 mr-3">
                      <Button infType="secondary" onClick={onCallButtonClick}>
                        <span className="w-5 h-5 mr-2 flex items-center">
                          <PhoneIcon style={{ fontSize: "1.25rem" }} />
                        </span>
                        Call
                      </Button>
                      <Menu
                        id="profile-call-dropdown"
                        anchorEl={callAnchorEl}
                        // keepMounted
                        open={Boolean(callAnchorEl)}
                        onClose={handleCloseCallMenu}
                        width={"20.1875rem"}
                      >
                        {sensityInfo ? (
                          <>
                            {sensityInfo.contactPhone && (
                              <MenuItem>
                                <div className="w-full">
                                  <CopyableInput
                                    text={sensityInfo?.contactPhone}
                                    type="menu"
                                    prefixIcon={
                                      <PhoneIcon
                                        style={{ fontSize: "1.25rem" }}
                                      />
                                    }
                                  />
                                </div>
                              </MenuItem>
                            )}
                            {sensityInfo.contactEmail && (
                              <MenuItem>
                                <div className="w-full">
                                  <CopyableInput
                                    text={sensityInfo?.contactEmail}
                                    type="menu"
                                    prefixIcon={
                                      <MailIcon
                                        style={{ fontSize: "1.25rem" }}
                                      />
                                    }
                                  />
                                </div>
                              </MenuItem>
                            )}
                          </>
                        ) : (
                          <>
                            <MenuItem>
                              <div className="w-full">
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height="20px"
                                />
                              </div>
                            </MenuItem>
                            <MenuItem>
                              <div className="w-full">
                                <Skeleton
                                  variant="text"
                                  width="100%"
                                  height="20px"
                                />
                              </div>
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </div>
                    <div className="w-16 mr-3 relative">
                      <CopyToClipboard
                        text={sensityInfo?.contactEmail || ""}
                        onCopy={glowCopiedText}
                      >
                        <Button infType="secondary">
                          <span className="w-5 h-5 flex items-center">
                            <MailIcon style={{ fontSize: "1.25rem" }} />
                          </span>
                        </Button>
                      </CopyToClipboard>
                      <span
                        className="absolute opacity-0 -bottom-3/4 left-1/2 transform -translate-x-1/2 text-sm cursor-none pointer-events-none"
                        ref={textCopiedRef}
                      >
                        Copied!
                      </span>
                    </div>
                    {/* START issue-112 disable more menu */}
                    {/* <div className="w-16"> */}
                    {/*   <Button infType="secondary" onClick={onMoreActionClick}> */}
                    {/*     <span className="w-5 h-5"> */}
                    {/*       <Image */}
                    {/*         src="/assets/sidemenu-logo/more-icon.svg" */}
                    {/*         height={20} */}
                    {/*         width={20} */}
                    {/*       /> */}
                    {/*     </span> */}
                    {/*   </Button> */}
                    {/*   <Menu */}
                    {/*     id="profile-more-dropdown" */}
                    {/*     anchorEl={anchorEl} */}
                    {/*     // keepMounted */}
                    {/*     open={Boolean(anchorEl)} */}
                    {/*     onClose={handleCloseMoreMenu} */}
                    {/*     width={"12.125rem"} */}
                    {/*   > */}
                    {/*     {PROFILE_MORE_MENU.map((i) => ( */}
                    {/*       <MenuItem */}
                    {/*         key={i.action} */}
                    {/*         disabled={true} */}
                    {/*         onClick={handleMoreMenuClick.bind(null, i.action)} */}
                    {/*       > */}
                    {/*         <div className="flex items-center"> */}
                    {/*           <img src={i.icon} className="mr-2" /> */}
                    {/*           {i.label} */}
                    {/*         </div> */}
                    {/*       </MenuItem> */}
                    {/*     ))} */}
                    {/*   </Menu> */}
                    {/* </div> */}
                    {/* END issue-112 */}
                  </div>
                </div>
              </div>
              <div className="w-full px-12 mb-8">
                <Tabs value={activeTab} onChange={handleTabChange}>
                  {tabs?.map((obj: any, i) => (
                    <Tab key={obj.id} label={obj.name} value={obj.id} />
                  )) ?? null}
                </Tabs>
              </div>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={activeTab}
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  classNames="fade-right"
                >
                  <div className="w-full px-12 mb-8">
                    {activeTab === "profile" && (
                      <ProfileIntroduceTab
                        profile={profile}
                        email={sensityInfo?.contactEmail}
                        nonEditable={true}
                        // onSocialClick={onSocialLinkClick.bind(
                        //   null,
                        //   profile?.userId
                        // )}
                      />
                    )}
                    {activeTab !== "profile" && (
                      <ProfileCustomTab
                        tab={profile?.tabs.find((i) => i.id === activeTab)}
                        // onItemClick={onTabItemClick.bind(
                        //   null,
                        //   profile?.userId,
                        //   activeTab
                        // )}
                      />
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </PerfectScrollbar>
          </div>
        </>
      </Fade>
    </MuiModal>
  );
};

export default ProfileModal;
