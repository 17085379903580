import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const HomeSolid: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round">
        <path
          d="M14.299 20.997V14.1h-4.6v6.898m0 0h-3.87a.77.77 0 01-.831-.7V10l7-7 7 6.999v10.299a.772.772 0 01-.831.7h-3.868"
          fill="#DDE2EB"
          stroke="#DDE2EB"
        />
        <path
          stroke="#01334c"
          strokeLinejoin="round"
          d="M3 11.999L11.999 3l8.998 8.999"
        />
      </g>
    </SvgIcon>
  );
};
export default HomeSolid;
