import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import classnames from "classnames";
import get from "lodash/get";
import { gsap } from "gsap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { BOTTOM_MENU, SIDE_MENU, URL_HIDE_BOTTOM_MENU } from "lib/constants";
import Logo from "components/logo";
import Sidemenu from "components/sidemenu";
import Button from "components/button";
import SearchBox from "components/searchbox";
import UserMenu from "components/user-menu";
import AuthenModal from "components/authen-modal";
import MobileMenu from "components/mobile-menu";
import BottomMenu from "components/bottom-menu";
import MobileTopMenu from "components/mobile-top-menu";
import HelpMenuItem from "components/help-menu-item";
import { useAppDispatch, useAppSelector } from "store";
import { appHideToast, closeRegister } from "features/app/appSlice";

const DefaultLayout: React.FC = ({ children }) => {
  const [isHiddenMenu, setIsHiddenMenu] = useState(false);
  const directionRef = useRef(1);
  const scrollTriggerRef = useRef<ScrollTrigger | null>(null);
  gsap.registerPlugin(ScrollTrigger);
  const router = useRouter();
  const isLogin = useAppSelector((state) => state.user.isLoggedIn);
  const userMenu = useAppSelector((state) => state.user.userMenu);
  const topMenu = useAppSelector((state) => state.user.topMenu);
  const topMobileMenu = useAppSelector((state) => state.user.topMobileMenu);
  const appState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [isShowAuthenDialog, setShowAuthenDialog] = React.useState<boolean>(
    () => {
      return false;
    }
  );
  const openModal = () => {
    setShowAuthenDialog(true);
  };

  const onMobileMenuClick = React.useCallback(
    (type: "command" | "router", path: string) => {
      setIsOpenMobileMenu(false);
      if (type === "router") {
        router.push(path);
        return;
      }
      if (type === "command") {
        switch (path) {
          case "authen":
            router.push("/m/dang-nhap");
            break;
        }
        return;
      }
    },
    []
  );

  useEffect(() => {
    const route = router.route || router.pathname;
    const isHidden = (URL_HIDE_BOTTOM_MENU as string[]).includes(route);
    setIsHiddenMenu(isHidden);
    setIsOpenMobileMenu(false);
  }, [router]);

  const viewPortRef = React.useRef<HTMLDivElement>(null);
  const bottomBarRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const viewPort = viewPortRef.current;
    const bottomBar = bottomBarRef.current;
    if (viewPort && bottomBar && !isHiddenMenu) {
      const triggerMenu = (direction: number) => {
        if (directionRef.current != direction) {
          if (direction == 1) {
            gsap.to(bottomBar, {
              y: "100%",
            });
          } else {
            gsap.to(bottomBar, {
              y: "0",
            });
          }
          directionRef.current = direction;
        }
      };
      triggerMenu(-1);
      scrollTriggerRef.current?.kill();
      scrollTriggerRef.current = ScrollTrigger.create({
        trigger: viewPort,
        start: "top top",
        end: "bottom bottom",
        onUpdate: (self) => triggerMenu(self.direction),
      });
    } else {
      scrollTriggerRef.current?.kill();
    }
    return () => {
      scrollTriggerRef.current?.kill();
    };
  }, [isHiddenMenu, appState.refreshBottomMenu]);

  const [isOpenMobileMenu, setIsOpenMobileMenu] =
    React.useState<boolean>(false);
  const onBottomMenuClick = React.useCallback(
    (path: string) => {
      if (path === "account") {
        if (isLogin) {
          router.push("/m/quan-ly");
        } else {
          router.push("/m/dang-nhap");
        }
        return;
      }
      if (path === "menu") {
        setIsOpenMobileMenu(true);
        return;
      }
      router.push(path);
    },
    [isLogin]
  );

  React.useEffect(() => {
    if (isOpenMobileMenu) {
      window.scrollTo(0, 0);
    }
  }, [isOpenMobileMenu]);

  const [showOrientationNoti, setShowOrientationNoti] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    const onOrientationChange = (event: Event) => {
      const angle = get(event, "target.screen.orientation.angle");
      if (angle !== undefined) {
        switch (angle) {
          case 0:
          case 180:
            setShowOrientationNoti(false);
            break;
          case 90:
          case -90:
            setShowOrientationNoti(true);
            break;
        }
      }
    };
    const angle = window?.screen?.orientation?.angle ?? 0;
    setShowOrientationNoti(angle === 90 || angle === -90);
    window.addEventListener("orientationchange", onOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", onOrientationChange);
    };
  }, []);
  React.useEffect(() => {
    if (showOrientationNoti) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [showOrientationNoti]);

  const handleCloseAuthModal = () => {
    setShowAuthenDialog(false);
    dispatch(closeRegister());
  };
  return (
    <>
      {/* <Meta/> */}
      <div
        className={classnames("min-h-screen flex flex-col sm:flex-row", {
          "w-full overflow-hidden fixed": isOpenMobileMenu,
        })}
        ref={viewPortRef}
      >
        {/* START WEB SIDE MENU */}
        <div className="hidden sm:flex fixed top-0 left-0 w-55 flex-col pt-4 h-screen border-r border-gray-300 border-solid z-60">
          <PerfectScrollbar
            className="inf-scrollbar pr-5 pl-5"
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <Link href="/">
              <div className="w-full mb-10 pl-2 cursor-pointer">
                <Logo />
              </div>
            </Link>
            {/* NAVIGATE BAR */}
            <nav className="w-full">
              <Sidemenu navItems={SIDE_MENU} activePath={router.asPath} />
              <HelpMenuItem />
            </nav>
            {/* USER NAVIGATE BAR */}
            {isLogin && (
              <>
                <div className="bg-pale-grey font-medium h-12 flex items-center -ml-7 -mr-5 pl-7 text-xs">
                  Quản lý cá nhân
                </div>
                <nav className="w-full mt-4">
                  <Sidemenu navItems={userMenu} activePath={router.pathname} />
                </nav>
              </>
            )}
            {!isLogin && (
              <div className="mt-7 px-8 py-5 -ml-7 -mr-5 bg-pale-grey">
                <div className="font-bold">Đăng nhập</div>
                <div className="my-3 text-sm text-greyish-brown leading-5">
                  Đăng nhập để trò chuyện, tương tác với người ảnh hưởng cùng
                  nhiều tính năng khác.
                </div>
                <Button onClick={openModal}>Đăng nhập</Button>
              </div>
            )}
          </PerfectScrollbar>
        </div>
        <div className="hidden sm:hidden w-full justify-center items-center px-5 py-4 bg-grey-top-bar">
          <div>
            <Image
              src={"/assets/sidemenu-logo/menu-icon.svg"}
              width={20}
              height={20}
            />
          </div>
          <div className="mx-auto">
            <Logo />
          </div>
          <div>
            <Image
              src={"/assets/sidemenu-logo/search-icon.svg"}
              width={20}
              height={20}
            />
          </div>
        </div>
        {/* END WEB SIDE MENU */}
        <main
          className={classnames(
            "flex-grow w-full sm:w-container sm:pl-67.5 sm:pr-7 sm:mt-4 mb-16 sm:mb-16"
          )}
        >
          {/* START WEB TOP MENU */}
          <div className="hidden sm:block fixed top-0 left-0 pl-67.5 w-screen pr-7.5 py-5 z-50 bg-white">
            <div className="top-container flex justify-between">
              <SearchBox />
              {isLogin && <UserMenu navItems={topMenu} />}
            </div>
          </div>
          {/* END WEB TOP MENU */}
          {/* ===================================================*/}
          {/* ============== START MOBILE TOP MENU  ============*/}
          {/* ===================================================*/}
          <MobileTopMenu
            isOpenMobileMenu={isOpenMobileMenu}
            setIsOpenMobileMenu={setIsOpenMobileMenu}
            navItems={topMobileMenu}
          />
          {/* ===================================================*/}
          {/* ===================================================*/}
          {/* ===================================================*/}
          {/* START MOBILE BOTTOM MENU */}
          {!isHiddenMenu && (
            <div
              className="fixed sm:hidden z-50 w-full py-1 px-2 left-0 right-0 bottom-0 bg-white flex justify-between items-center transform"
              ref={bottomBarRef}
            >
              <BottomMenu
                onNavClick={onBottomMenuClick}
                navItems={BOTTOM_MENU}
                activePath={router.asPath}
              />
            </div>
          )}
          {/* END MOBILE BOTTOM MENU */}
          <div className="mt-0 sm:mt-20">{children}</div>
        </main>
        {showOrientationNoti && (
          <div className="fixed top-0 left-0 h-full w-full bg-mobile-rotate bg-cover z-80"></div>
        )}
        {/* ===================================================*/}
        {/* ============== START MOBILE SIDE MENU  ============*/}
        {/* ===================================================*/}
        <MobileMenu isOpen={isOpenMobileMenu}>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start mr-auto ml-auto w-max -my-3 py-6">
              {SIDE_MENU.map((item) =>
                item.path === "/tim-kiem" ? null : (
                  <div
                    key={item.path}
                    className="flex items-center content-start w-30 py-3"
                    onClick={onMobileMenuClick.bind(null, "router", item.path)}
                  >
                    <span className="w-5 h-5 mr-4">
                      <img src={item.icon} />
                    </span>
                    <span className="text-sm">{item.label}</span>
                  </div>
                )
              )}
              <HelpMenuItem type="mobile" />
            </div>
            {!isLogin && (
              <div className="flex w-36 items-center content-start">
                <Button
                  onClick={onMobileMenuClick.bind(null, "command", "authen")}
                >
                  Đăng nhập
                </Button>
              </div>
            )}
            {isLogin && (
              <>
                <div className="w-full text-center text-xs uppercase text-grey-80 bg-grey-f5 py-2">
                  Quản lý tài khoản
                </div>
                <div className="flex flex-col items-start mr-auto ml-auto w-max -my-3 py-6">
                  {userMenu.map((item) => (
                    <div
                      key={item.path}
                      className="flex items-center content-start w-30 py-3"
                      onClick={onMobileMenuClick.bind(
                        null,
                        "router",
                        item.path
                      )}
                    >
                      <span className="w-5 h-5 mr-4">
                        <img src={item.icon} />
                      </span>
                      <span className="text-sm">{item.label}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </MobileMenu>
        {/* ===================================================*/}
        {/* ===================================================*/}
        {/* ===================================================*/}
        <AuthenModal
          isShow={isShowAuthenDialog}
          handleClose={handleCloseAuthModal}
        />
        <Snackbar
          open={appState.isShowToast}
          autoHideDuration={6000}
          onClose={() => dispatch(appHideToast())}
        >
          <Alert onClose={() => dispatch(appHideToast())} severity="success">
            This is a success message!
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default DefaultLayout;
