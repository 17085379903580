import { call, put, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { appInitSaga, handleAppError } from "features/app/appSaga";
import { appError, appInit, appInitFail } from "features/app/appSlice";
import { getSensityInfoSaga } from "features/profile/profileSaga";
import { getSensityInfoAsync } from "features/profile/profileSlice";
import {
  updateUserInfo,
  userAccessToken,
  userLoginSuccess,
  userSignOut,
} from "features/user/userSlice";
import cookie from "js-cookie";

export function log(action: PayloadAction) {
  console.log("Log", action);
}

export function handeSaveAccessToken(action?: PayloadAction<string>) {
  if (action?.payload?.length) {
    cookie.set("token", action?.payload, { expires: 30 });
  } else {
    cookie.remove("token");
  }
}

export function handeDeleteAccessToken() {
  cookie.remove("token");
}

export default function* rootSaga() {
  //TODO: Log Action
  // yield takeEvery('*', log);

  //TODO: Init App and Data
  yield takeEvery(appInit.type, appInitSaga);

  //TODO: Handle App Error
  yield takeEvery(appError.type, handleAppError);
  yield takeEvery(appInitFail.type, handleAppError);
  yield takeEvery(userAccessToken.type, handeSaveAccessToken);
  yield takeEvery(userSignOut.type, handeDeleteAccessToken);

  //TODO: Profile
  yield takeEvery(getSensityInfoAsync.type, getSensityInfoSaga);
}
