import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { IProfile, ISensityInfo } from "./type";

export const getSensityInfoAsync = createAction<string>(
  "profiles/getSensityInfoAsync"
);

const initialState = {
  list: [] as IProfile[],
  sensityInfos: {} as { [key: string]: ISensityInfo },
};

export const profiles = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    updateProfiles: (state, action: PayloadAction<IProfile[]>) => {
      state.list = action.payload;
    },
    concatProfiles: (state, action: PayloadAction<IProfile[]>) => {
      state.list = [...state.list, ...action.payload];
    },
    concatSensityInfo: (state, action: PayloadAction<ISensityInfo>) => {
      const influzeeID = action.payload.influzeeID;
      state.sensityInfos[influzeeID] = action.payload;
    },
  },
  extraReducers: {},
});
export const {
  updateProfiles,
  concatProfiles,
  concatSensityInfo,
} = profiles.actions;
export default profiles.reducer;
