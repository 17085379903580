import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MuiModal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalBody from "./modal-body";
interface ModalProps {
  isShow: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const HelpInfoModal: React.FC<ModalProps> = ({ isShow, handleClose }) => {
  const classes = useStyles();
  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isShow}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={isShow}>
        <ModalBody
          title="Bạn cần trợ giúp?"
          size="s"
          showCloseIcon
          onClose={handleClose}
          className="bg-white p-5 pt-8"
        >
          <div className="w-20 h-20 relative mb-5 mr-auto ml-auto">
            <img src="/assets/icon/cafe.webp" alt="" />
          </div>
          <div className="flex text-grey-80 w-full mb-3 ml-4">
            <div className="mr-2">Email hỗ trợ:</div>
            <div className="text-greyish-brown underline">
              <a href="mailto:support@influzee.com">contact@blueagency.vn</a>
            </div>
          </div>
          <div className="flex text-grey-80 mb-3 ml-4">
            <div className="mr-2">Điện thoại:</div>
            <div className="text-grey-80 underline hover:text-grey-80">
              <a className="text-greyish-brown" href="tel:+8498489977">
                0933333259
              </a>
            </div>
          </div>
          <div className="flex text-grey-80 mb-3 ml-4">
            <div className="mr-2">Fanpage:</div>
            <div className="text-greyish-brown underline hover:text-grey-80">
              <a
                href="https://fb.com/influzee.vn"
                target="_blank"
                rel="noreferrer"
              >
                https://fb.com/influzee.vn
              </a>
            </div>
          </div>
        </ModalBody>
      </Fade>
    </MuiModal>
  );
};

interface Props {
  type?: "mobile";
}
const HelpMenuItem: React.FC<Props> = ({ type }) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <>
      {type === "mobile" ? (
        <div
          className="flex items-center content-start w-30 py-3"
          onClick={() => setIsShow(true)}
        >
          <span className="w-5 h-5 mr-4">
            <img src={"/assets/sidemenu-logo/contact-icon.svg"} />
          </span>
          <span className="text-sm">Hỗ trợ</span>
        </div>
      ) : (
        <div
          className="flex items-center p-2 w-48 h-10 mb-2 rounded-lg relative hover:bg-pale-grey cursor-pointer text-greyish-brown text-sm relative"
          onClick={() => setIsShow(true)}
        >
          <div className="flex items-center w-full h-full overflow-hidden relative">
            <span className="w-5 h-5 mr-4">
              <img
                src="/assets/sidemenu-logo/contact-icon.svg"
                alt={`navigation icon for contact`}
                height={20}
                width={20}
              />
            </span>
            <span>Hỗ trợ</span>
          </div>
        </div>
      )}

      <HelpInfoModal isShow={isShow} handleClose={() => setIsShow(false)} />
    </>
  );
};

export default HelpMenuItem;
