import fernet from "fernet";
import { DEFAULT_BIO } from "lib/constants";
import replace from "lodash/replace";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export function nFormatter(num: number, digits: number): string {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function makeRandomId() {
  const result = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 5; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

export function mergeRef<T>(...refs: any[]) {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
}

export function joinWithSeperator(seperator: string, ...inputArr: string[]) {
  return inputArr.filter((i) => typeof i === "string" && !!i).join(seperator);
}

export function defaultBio(fullName: string) {
  if (!fullName) return "";
  return replace(DEFAULT_BIO, /%name%/g, fullName);
}

export function prefixProtocol(value: string) {
  const protocols = "https://";
  const protocol = "http://";
  if (!value || value.startsWith(protocol) || value.startsWith(protocols)) {
    return value;
  }
  return protocols + value;
}

export const encode = (encodedStr: string, key: string) => {
  try {
    const secret = new fernet.Secret(key);
    const temp = new fernet.Token({
      secret,
      ttl: 1900,
    });
    return temp.encode(encodedStr);
  } catch (err) {
    return encodedStr;
  }
};

export const decode = (encoded: string, key: string) => {
  try {
    const secret = new fernet.Secret(key);
    const temp = new fernet.Token({
      secret,
      token: encoded,
      ttl: 1900,
    });
    return temp.decode();
  } catch (err) {
    return encoded;
  }
};

export const makeDateByDateFrame = (value: string, format = "YYYY-MM-DD") => {
  switch (value) {
    case "month":
      return [
        dayjs().subtract(31, "day").format(format),
        dayjs().subtract(1, "day").format(format),
      ];
    default:
    case "week":
      return [
        dayjs().subtract(8, "day").format(format),
        dayjs().subtract(1, "day").format(format),
      ];
    // case "lifetime":
    // default:
    //   return [
    //     dayjs.unix(0).format(format),
    //     dayjs().subtract(1, "day").format(format),
    //   ];
  }
};

export const formatDateRange = (
  value: [Date, Date] | null,
  format = "YYYY-MM-DD"
) => {
  if (!value) return;
  const [startDate, endDate] = value;
  if (!startDate || !endDate) return;
  return [dayjs(startDate).format(format), dayjs(endDate).format(format)];
};

export const makeDates = (
  startDate: string,
  endDate: string,
  format = "YYYY-MM-DD",
  isIncludeEndDate = true
) => {
  const dates: any[] = [];
  let cur = dayjs(startDate, format);
  const end = dayjs(endDate, format);
  while (cur.isBefore(end, "date")) {
    dates.push(cur.format(format));
    cur = cur.add(1, "day");
  }
  if (isIncludeEndDate) {
    dates.push(end.format(format));
  }
  return dates;
};

export const dateFormat = (
  value: string,
  newFormat: string,
  oldFormat?: string
) => {
  return dayjs(value, oldFormat).format(newFormat);
};
