import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJob } from "./type";

const initialState = {
  list: [] as IJob[],
};

export const jobs = createSlice({
  name: "categories",
  initialState,
  reducers: {
    updateJobs: (state, action: PayloadAction<IJob[]>) => {
      state.list = action.payload;
    },
    concatJobs: (state, action: PayloadAction<IJob[]>) => {
      state.list = [...state.list, ...action.payload];
    },
  },
  extraReducers: {},
});
export const { updateJobs, concatJobs } = jobs.actions;
export default jobs.reducer;
