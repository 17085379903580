import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Dashboard: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M8.18 9.965l2.328 2.324 4.656-4.652"
          stroke="currentColor"
          strokeWidth={1.7999999999999998}
        />
        <path
          d="M19.816 18.11H3.527c-.64 0-1.164-.52-1.164-1.165V2.98h18.621v13.965c0 .64-.523 1.164-1.164 1.164zM1.2 2.983h20.946M9.809 18.11l-2.79 3.493m6.516-3.493l2.793 3.493"
          stroke="currentColor"
          strokeWidth={1.7999999999999998}
        />
      </g>
    </SvgIcon>
  );
};
export default Dashboard;
