import { getNullDefault } from "lib/common";
import { ENC_KEY } from "lib/constants";
import fernet from "fernet";

export interface ITabItem {
  id: string;
  image: string;
  text: string;
  link: string;
}

export interface ITab {
  id: string;
  name: string;
  position: number;
  items: ITabItem[];
  profileID: string;
  isActive: boolean;
}

export class ITab {
  constructor(value: any) {
    this.id = getNullDefault(value, "id", "");
    this.name = getNullDefault(value, "name", "");
    this.position = getNullDefault(value, "position", 999);
    this.items = getNullDefault(value, "items", []);
    this.profileID = getNullDefault(value, "profile_id", "");
    this.isActive = getNullDefault(value, "is_active", true);
  }
}

export interface IProfile {
  id: number;
  bio: string;
  contactPhone: string;
  contactEmail: string;
  gender: string;
  fullName: string;
  birthDate: string;
  province: string;
  address: string;
  avatar: string;
  contactWebsite: string;
  links: string[];
  userId: string;
  isActive: boolean;
  coverImage: string;
  coverVideo: string;
  mainPlatform: string;
  job: string[];
  images: string[];
  videos: string[];
  influzeeID: string;
  isChatEnabled: boolean;
  categories: any[];
  socials: any[];
  tabs: ITab[];
  hiddenFields: string[];
  isPrivate: boolean;
  canPublish: boolean;
}

export interface ISensityInfo {
  influzeeID: string;
  contactPhone: string;
  contactEmail: string;
}

const decode = (encoded: string, key: string) => {
  try {
    const secret = new fernet.Secret(key);
    const temp = new fernet.Token({
      secret,
      token: encoded,
      ttl: 1900,
    });
    return temp.decode();
  } catch (err) {
    return encoded;
  }
};

export class ISensityInfo {
  constructor(influzeeID: string, value: any) {
    this.influzeeID = influzeeID;
    let contactPhone = getNullDefault(value, "contact_phone", "");
    if (contactPhone && ENC_KEY) {
      contactPhone = decode(contactPhone, ENC_KEY);
    }
    let contactEmail = getNullDefault(value, "contact_email", "");
    if (contactEmail && ENC_KEY) {
      contactEmail = decode(contactEmail, ENC_KEY);
    }
    this.contactEmail = contactEmail;
    this.contactPhone = contactPhone;
  }
}

export class IProfile {
  constructor(value: any) {
    this.id = getNullDefault(value, "id", "");
    this.bio = getNullDefault(value, "bio", "");
    this.contactPhone = getNullDefault(value, "contact_phone", "");
    this.contactEmail = getNullDefault(value, "contact_email", "");
    this.gender = getNullDefault(value, "gender", "");
    this.fullName = getNullDefault(value, "full_name", "");
    this.birthDate = getNullDefault(value, "birth_date", "");
    this.province = getNullDefault(value, "province", "");
    this.address = getNullDefault(value, "address", "");
    this.avatar = getNullDefault(value, "avatar", "");
    this.contactWebsite = getNullDefault(value, "contact_website", "");
    this.links = getNullDefault(value, "links", []);
    this.userId = getNullDefault(value, "user_id", "");
    this.isActive = getNullDefault(value, "is_active", true);
    this.coverImage = getNullDefault(value, "cover_img", "");
    this.coverVideo = getNullDefault(value, "cover_video", "");
    this.mainPlatform = getNullDefault(value, "main_platform", false);
    this.job = getNullDefault(value, "job", []);
    this.images = getNullDefault(value, "images", []);
    this.videos = getNullDefault(value, "videos", []);
    this.influzeeID = getNullDefault(value, "influzee_id", "");
    this.isChatEnabled = getNullDefault(value, "is_chat_enabled", true);
    this.categories = getNullDefault(value, "categories", []);
    this.socials = getNullDefault(value, "socials", []);
    this.tabs = getNullDefault(value, "tabs", []);
    this.hiddenFields = getNullDefault(value, "hidden_fields", []);
    this.isPrivate = getNullDefault(value, "is_private", false);
    this.canPublish = getNullDefault(value, "can_publish", false);
  }
}
