import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { Action } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";
import { getSensityInfoAsync, concatSensityInfo } from "./profileSlice";
import { getSensityInfoByInfluzeeId } from "./api";
import { ISensityInfo } from "./type";
export function* getSensityInfoSaga(action: Action): SagaIterator<void> {
  try {
    if (getSensityInfoAsync.match(action)) {
      const data: {
        profile: { contact_email: string; contact_phone: string };
      } = yield call(getSensityInfoByInfluzeeId, action.payload);
      const sensityInfo = new ISensityInfo(action.payload, data.profile);
      yield put(concatSensityInfo(sensityInfo));
    }
  } catch (err) {
    Sentry.captureException(err);
  }
}
export default {};
