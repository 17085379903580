import {configureStore, EnhancedStore, Store} from '@reduxjs/toolkit';
import createSagaMiddleware, {Task} from 'redux-saga';
import rootSaga from "./rootSaga";
import {reducer} from './rootReducer';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {Context, createWrapper, MakeStore} from "next-redux-wrapper";

export interface SagaStore extends Store {
  sagaTask?: Task;
}

const devMode = process.env.NODE_ENV === "development";
const sagaMiddleware = createSagaMiddleware();

const initialState = {};
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat([sagaMiddleware]),
  preloadedState: initialState,
  devTools: devMode
});

const setupStore = (context: any): EnhancedStore => store;

export const makeStore: MakeStore<any> = (context: Context) => setupStore(context);

(store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

export const wrapper = createWrapper(makeStore, {
  debug: false,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
