import React, { useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import Backdrop from "@material-ui/core/Backdrop";
import MuiModal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import ModalBody from "components/modal-body";
import Button from "components/button";
import ThemeProvider from "context/ThemeProvider";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  iconError: {
    marginBottom: "0.9375rem",
    fontSize: "4.375rem",
    color: "#FF0000",
  },
  iconSuccess: {
    marginBottom: "0.9375rem",
    fontSize: "4.375rem",
    color: "#FF0000",
  },
});

interface PopupProps {
  type?: "link" | "social";
  visible: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  afterClose: () => void;
  onBottomClick?: () => void;
  onOk?: () => void;
  url: string;
}

const Modal: React.FC<PopupProps> = ({
  type = "link",
  visible,
  onOpen,
  onClose,
  afterClose,
  onBottomClick,
  onOk,
  url,
}) => {
  const handleClose = useCallback(() => {
    if (onClose && typeof onClose === "function") {
      onClose();
    }
    if (afterClose && typeof afterClose === "function") {
      afterClose();
    }
  }, []);
  const openNewTab = (url?: string) => {
    if (onOk) {
      onOk();
    }
    if (onBottomClick) {
      onBottomClick();
    }
    if (url) {
      setTimeout(() => {
        window?.open(url, "_blank")?.focus();
      });
    }
  };
  useEffect(() => {
    if (visible) {
      if (onOpen && typeof onOpen === "function") {
        onOpen();
      }
    }
  }, [visible]);
  const classes = useStyles();
  return (
    <ThemeProvider>
      <MuiModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={visible}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Fade in={visible}>
          <ModalBody
            className="bg-white p-5 pt-7 rounded-xl relative"
            title={"Truy cập liên kết"}
            size="s"
          >
            <div className="flex items-center justify-center flex-col">
              <div className="text-sm text-grey-80 text-center mb-5">
                Bạn đang truy cập liên kết nằm ngoài Influzee. Vui lòng chọn
                đồng ý để xác nhận mở liên kết.
              </div>
              <div className="w-full px-3 py-4 flex items-center bg-pale-grey rounded-xl mb-5">
                <img
                  src="/assets/sidemenu-logo/link-icon.svg"
                  width={20}
                  height={20}
                  alt="link icon"
                />
                {/* <span className="ml-2 text-sm flex-grow line-clamp-1 break-all"> */}
                {/*   {url} */}
                {/* </span> */}
                <input
                  className="ml-2 text-sm flex-grow line-clamp-1 break-all focus:outline-none focus-visible:outline-none bg-transparent"
                  value={url}
                  readOnly
                />
              </div>
              <div className="flex flex-row w-full -mx-1">
                <div className="w-1/2 px-1">
                  {type === "link" && (
                    <Button onClick={openNewTab.bind(null, url)}>Đồng ý</Button>
                  )}
                  {type === "social" && isMobile && (
                    <Button
                      onClick={openNewTab.bind(null, "")}
                      href={
                        url.indexOf("https://") >= 0 ? url : "https://" + url
                      }
                    >
                      Đồng ý
                    </Button>
                  )}
                  {type === "social" && !isMobile && (
                    <Button onClick={openNewTab.bind(null, url)}>Đồng ý</Button>
                  )}
                </div>
                <div className="w-1/2 px-1">
                  <Button onClick={onBottomClick}>Huỷ bỏ</Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Fade>
      </MuiModal>
    </ThemeProvider>
  );
};

type DialogConfig = Omit<PopupProps, "visible" | "close" | "afterClose">;

type ConfigUpdate = DialogConfig | ((prevConfig: DialogConfig) => DialogConfig);

const OpenLinkConfirm = (config: DialogConfig) => {
  const div = document.createElement("div");
  document.body.appendChild(div);
  let currentConfig: any = {
    ...config,
    close,
    visible: true,
    afterClose: destroy,
  };

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  function render({ ...props }: any) {
    ReactDOM.hydrate(<Modal {...props} />, div);
  }

  function close() {
    currentConfig = {
      ...currentConfig,
      visible: false,
    };
    render(currentConfig);
  }

  function update(configUpdate: ConfigUpdate) {
    if (typeof configUpdate === "function") {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }

  const closeModal = () => {
    close();
    destroy();
  };

  render(currentConfig);
  return {
    close: closeModal,
    update,
  };
};

export default OpenLinkConfirm;
