import { get } from "lodash";

export interface IApp {
  isLoaded: boolean;
  errorCode?: string;
  errorMsg?: string;
  errorMsgShow?: string;
  deviceId: string;
  isShowToast: boolean;
  searchKey: string;
  popstate: boolean;
  isOpenRegister: boolean;
  initialHistoryPoint: number;
  refreshBottomMenu: number;
}

export const ErrorType = {
  NO_ERROR: "NO_ERROR",
  LOGIN_FAIL: "LOGIN_FAIL",
  INIT_APP_FAIL: "INIT_APP_FAIL",
  FORBIDDEN: "FORBIDDEN",
  UNAUTHORIZED: "UNAUTHORIZED",
  APP_ERROR: "APP_ERROR",
  NOT_FOUND: "NOT_FOUND",
  LIMIT: "LIMIT",
  ERROR_USER_PHONE_ALREADY_USED: "ERROR_USER_PHONE_ALREADY_USED",
  ERROR_INVALID_ID_TOKEN: "ERROR_INVALID_ID_TOKEN",
  ERROR_WRONG_PASSWORD: "ERROR_WRONG_PASSWORD",
  ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED: "ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED",
  ERROR_SOCIAL_ACCOUNT_ALREADY_REGISTERED:
    "ERROR_SOCIAL_ACCOUNT_ALREADY_REGISTERED",
  MISSING_FIREBASE_PROVIDER: "MISSING_FIREBASE_PROVIDER",
  Unexpected: "Unexpected",
  SOCIAL_ACCOUNT_LINK: "SOCIAL_ACCOUNT_LINK",
  VERIFY_FAIL: "VERIFY_FAIL",
  ERROR_USER_NOT_FOUND: "ERROR_USER_NOT_FOUND",
  ERROR_UPLOAD_FAILED: "ERROR_UPLOAD_FAILED",
  ERROR_USER_UNVERIFIED: "ERROR_USER_UNVERIFIED",
  ERROR_PROFILE_CANNOT_BE_PUBLISHED: "ERROR_PROFILE_CANNOT_BE_PUBLISHED",
  ERROR_OTP_INVALID: "ERROR_OTP_INVALID",
  ERROR_OTP_EXPIRED: "ERROR_OTP_EXPIRED",
};
export const ErrorMessage = {
  [ErrorType.INIT_APP_FAIL]: "Có lỗi trong quá trình khởi tạo ứng dụng ",
  // [ErrorType.Unexpected]: "Lỗi không xác định",
  [ErrorType.LOGIN_FAIL]: "Đăng nhập thất bại",
  [ErrorType.FORBIDDEN]: "Bạn không có quyền thực hiện chức năng này.",
  [ErrorType.UNAUTHORIZED]: "Phiên đăng nhập của bạn đã hết hạn!",
  [ErrorType.LIMIT]: "Vượt quá giới hạn yêu cầu. Vui lòng thử lại sau.",
  [ErrorType.ERROR_USER_PHONE_ALREADY_USED]:
    "Tài khoản sử dụng số điện thoại trên đã tồn tại. \n Vui lòng sử dụng số điện thoại khác để đăng ký.",
  [ErrorType.APP_ERROR]: "Hệ thống đang gặp sự cố. Vui lòng thử lại sau.",
  [ErrorType.ERROR_WRONG_PASSWORD]: "Sai mật khẩu.",
  [ErrorType.ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED]:
    "Tài khoản bạn đăng nhập chưa đăng ký thông tin.",
  [ErrorType.ERROR_SOCIAL_ACCOUNT_ALREADY_REGISTERED]:
    "Tài khoản này đã được đăng ký hoặc liên kết với một tài khoản khác!",
  [ErrorType.ERROR_USER_NOT_FOUND]: "Tài khoản này không tồn tại.",
  [ErrorType.ERROR_UPLOAD_FAILED]:
    "File tải lên không thành công. Vui lòng kiểm tra và thử lại",
  [ErrorType.ERROR_USER_UNVERIFIED]:
    "Tài khoản chưa được xác thực. Vui lòng xác thực tài khoản",
  [ErrorType.ERROR_PROFILE_CANNOT_BE_PUBLISHED]:
    "Hãy cập nhật thông tin trang cá nhân của bạn trước khi đặt ở chế độ công khai",
  [ErrorType.ERROR_OTP_INVALID]:
    "Mã OTP không đúng. Vui lòng kiểm tra lại hoặc liên hệ với chúng tôi để được hỗ trợ.",
  [ErrorType.ERROR_OTP_EXPIRED]: "Mã OTP đã quá hạn. Vui lòng thử lại.",
};
export class AppError extends Error {
  code: string;
  messageShow: string;
  message: string;
  errorObj: any;
  constructor(code: string, message: string, errorObj?: any) {
    super();
    this.code = get(ErrorType, code, ErrorType.Unexpected);
    this.name = "App Error";
    this.messageShow = get(
      ErrorMessage,
      `${code}`,
      message.length ? message : "Lỗi không xác định"
    );
    this.message = message;
    this.errorObj = errorObj;
  }
}
