import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import get from "lodash/get";
import { useAppSelector } from "store";
import Menu from "./menu";
import MenuItem from "./menu-item";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "./icons/Dashboard";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as api from "features/user/api";

type NavItem = {
  icon: string;
  label: string;
  action: string;
};

interface Props {
  navItems: NavItem[];
}
interface IconProps extends SvgIconProps {
  type: string;
}
const Icon: React.FC<IconProps> = ({ type, ...others }) => {
  switch (type) {
    case "DashboardIcon":
      return <DashboardIcon {...others} />;
    case "ExitToAppIcon":
      return <ExitToAppIcon {...others} />;
    default:
      return null;
  }
};

const UserMenu: React.FC<Props> = ({ navItems }) => {
  const router = useRouter();
  const userState = useAppSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (action: string) => {
    if (action === "logout") {
      api
        .userLogout()
        .then(() => {
          sessionStorage.setItem("isDisableLottie", "true");
          router.reload();
        })
        .catch((err) => {
          router.push("/");
          // const { close } = AlertModal({
          //   title: "Có lỗi xảy ra",
          //   description: get(err, "messageShow", err.message),
          //   type: "success",
          //   bottomText: "Hoàn thành",
          //   onBottomClick: () => close(),
          // });
        });
    }
    if (action === "profile") {
      router.push("/quan-ly");
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex h-10">
      <div className="h-10 w-10 bg-pale-grey flex justify-center items-center rounded-xl hover:bg-pale-grey-hover cursor-pointer mr-3">
        <Image
          src="/assets/sidemenu-logo/chat-icon.svg"
          width={20}
          height={20}
        />
      </div>
      <div
        className="h-10 w-10 rounded-xl overflow-hidden mr-3 cursor-pointer"
        aria-controls="user-menu-dropdown"
        aria-haspopup="true"
        onClick={onAvatarClick}
      >
        <div className="w-full">
          <div className="aspect-w-1 aspect-h-1">
            <img
              src={
                get(userState, "user.avatar") ||
                "/assets/icon/person-avatar.png"
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-60">
        <div className="text-sm text-grey-80">Xin Chào!</div>
        <div className="text-sm text-black line-clamp-1">
          {get(userState, "user.fullName")}
        </div>
      </div>
      <Menu
        id="user-menu-dropdown"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navItems.map((i) => (
          <MenuItem key={i.action} onClick={handleClick.bind(null, i.action)}>
            <div className="flex items-center">
              <Icon
                type={i.icon}
                style={{ fontSize: "1.25rem", marginRight: "0.25rem" }}
              />
              {i.label}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default UserMenu;
