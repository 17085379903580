import { AnyAction } from "redux";
import { HYDRATE } from "next-redux-wrapper";
import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "features/user/userSlice";
import { IUser } from "features/user/type";
import appSlice from "features/app/appSlice";
import { IApp } from "features/app/type";
import categorySlice from "features/categogy/categorySlice";
import { ICategory } from "features/categogy/type";
import profileSlice from "features/profile/profileSlice";
import { IProfile, ISensityInfo } from "features/profile/type";
import jobSlice from "features/job/jobSlice";
import { IJob } from "features/job/type";
import seoSlice from "features/seo/seoSlice";
import { ISeo } from "features/seo/type";

export interface State {
  user: IUser;
  app: IApp;
  category: {
    list: ICategory[];
  };
  profile: {
    list: IProfile[];
    sensityInfos: { [key: string]: ISensityInfo };
  };
  job: {
    list: IJob[];
  };
  seo: ISeo;
}

export const reducer = (state: State | undefined, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE: {
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      };
      if (state && state.user) nextState.user = state.user;
      if (state && state.app) nextState.app = state.app;
      if (state && state.category) nextState.category = state.category;
      if (state && state.profile) nextState.profile = state.profile;
      if (state && state.job) nextState.job = state.job;
      if (state && state.seo) nextState.seo = state.seo;
      return nextState;
    }
    default: {
      const combineReducer = combineReducers({
        user: userSlice,
        app: appSlice,
        category: categorySlice,
        profile: profileSlice,
        job: jobSlice,
        seo: seoSlice,
      });
      return combineReducer(state, action);
    }
  }
};
