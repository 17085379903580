import React from "react";
import get from "lodash/get";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/Add";

interface Props {
  text?: string;
  onClick?: () => void;
  className?: string;
  type?: "row" | "col";
}

const TYPE_CLASS = {
  col: "flex-col",
  row: "flex-row-reverse",
};

const AddNew: React.FC<Props> = ({
  children,
  onClick,
  className = "w-1/5 px-4",
  type = "col",
}) => {
  return (
    <div className={className} onClick={onClick}>
      <div
        className={classnames(
          "w-full h-full flex justify-center items-center bg-pale-grey rounded-lg hover:bg-pale-grey-hover cursor-pointer",
          get(TYPE_CLASS, type, "")
        )}
      >
        <AddIcon
          style={{
            fontSize: type === "col" ? "1.75rem" : "1rem",
            color: "#808080",
          }}
        />
        {children}
      </div>
    </div>
  );
};

export default AddNew;
