import React, { useState } from "react";
import { AuthenFlow } from "lib/enums";
import ModalBody from "components/modal-body";
import SendOtpForm, { SendOtpFormValues } from "components/Form/SendOtpForm";
import ConfirmPasswordForm, {
  ConfirmPasswordFormValue,
} from "components/Form/ConfirmPasswordForm";
import AlertModal from "components/Dialog/AlertModal";
import get from "lodash/get";
import { userResetPassword } from "features/user/api";
import { useTracking } from "context/trackers";

const STEP_ONE = "STEP_ONE";
const STEP_TWO = "STEP_TWO";

interface Props {
  handleClose: () => void;
  changeFlow: (toFlow: AuthenFlow) => void;
}

const ForgotPassword: React.FC<Props> = ({ handleClose, changeFlow }) => {
  const [step, setStep] = useState(STEP_ONE);
  const [dataSubmit, setDataSubmit] = useState<SendOtpFormValues | null>(null);
  const handleBack = () => {
    if (step === STEP_TWO) {
      setStep(STEP_ONE);
    } else {
      changeFlow(AuthenFlow.LOGIN);
    }
  };

  const onSubmitDataStepOne = (data: SendOtpFormValues) => {
    setDataSubmit({
      ...dataSubmit,
      ...data,
    });
    setStep(STEP_TWO);
  };

  const onSubmitDataStepTwo = async (data: ConfirmPasswordFormValue) => {
    const { newPassword, newPasswordConfirmation } = data;
    if (dataSubmit) {
      try {
        await userResetPassword(
          newPassword,
          newPasswordConfirmation,
          dataSubmit.otp,
          dataSubmit.phone
        );
        const { close } = AlertModal({
          title: "Đổi mật khẩu\n" + "thành công!",
          description:
            "Mật khẩu mới được thiết lập. Bạn có thể dùng số điện thoại và mật khẩu mới để đăng nhập.",
          type: "success",
          bottomText: "Hoàn thành",
          onBottomClick: () => {
            close();
            changeFlow(AuthenFlow.LOGIN);
          },
        });
        return Promise.resolve();
      } catch (err: any) {
        if (err.code && err.name == "App Error") {
          const { close } = AlertModal({
            title: "Xác thực thất bại",
            description: get(err, "messageShow", err.message),
            type: "error",
            bottomText: "Thử lại",
            onBottomClick: () => {
              close();
            },
          });
        } else {
          const { close } = AlertModal({
            title: "Thất bại",
            description: "Có lỗi xảy ra. Vui lòng thử lại sau",
            type: "error",
            bottomText: "Thử lại",
            onBottomClick: () => {
              close();
            },
          });
        }
        return Promise.reject();
      }
    }
  };

  /* Tracking GA modal open */
  const { modalView } = useTracking();
  React.useEffect(() => {
    modalView("/forgot-password");
  }, []);
  /* End Tracking GA modal open */

  return (
    <ModalBody
      title="Quên mật khẩu"
      size="s"
      showBackIcon
      onBack={handleBack}
      className="bg-white p-5 pt-8"
    >
      {step === STEP_ONE && (
        <SendOtpForm
          type={"checkPhoneNoExits"}
          onSubmitData={onSubmitDataStepOne}
        />
      )}
      {step === STEP_TWO && (
        <ConfirmPasswordForm onSubmitData={onSubmitDataStepTwo} />
      )}
    </ModalBody>
  );
};

export default ForgotPassword;
