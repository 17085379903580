import { ErrorType } from "features/app/type";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Input from "components/input";
import get from "lodash/get";
import CountdownButton, { CountState } from "components/countdown-button";
import Button from "components/button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PHONE_REGEX, PASSWORD_REGEX } from "lib/common";
import { sendOTP, userPhoneLogin, verifyCode } from "features/user/api";
import AlertModal from "components/Dialog/AlertModal";
import { recaptchaVerifierInvisible } from "lib/firebase";
import classNames from "classnames";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props {
  onSubmitData: (
    data: ConfirmPasswordFormValue,
    id_token?: string
  ) => Promise<void>;
  submitText?: string;
  className?: string;
  isSetPassword?: boolean;
  isConfirmOTP?: boolean;
  phone?: string;
}

export type ConfirmPasswordFormValue = {
  password: string;
  otp: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const ConfirmPasswordForm: React.FC<Props> = ({
  submitText = "Tiếp tục",
  onSubmitData,
  className,
  isSetPassword,
  isConfirmOTP,
  phone,
}) => {
  const ConfirmPasswordFormSchema = yup.object().shape({
    password: isSetPassword
      ? yup
          .string()
          .min(8, "Mật khẩu ít nhất 8 ký tự")
          .max(20, "Mật khẩu tối đa 20 ký tự")
          .matches(PASSWORD_REGEX, "Mật khẩu phải gồm chữ")
          .required("Vui lòng điền mật khẩu")
      : yup.string().default(""),
    newPassword: yup
      .string()
      .min(8, "Mật khẩu ít nhất 8 ký tự")
      .max(20, "Mật khẩu tối đa 20 ký tự")
      .matches(PASSWORD_REGEX, "Mật khẩu phải gồm chữ")
      .required("Vui lòng điền mật khẩu mới"),
    newPasswordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), null],
        "Mật khẩu nhập lại không trùng khớp"
      ),
    otp: isConfirmOTP
      ? yup
          .string()
          .min(6, "Mã OTP gồm 6 ký tự")
          .max(6, "Mã OTP gồm 6 ký tự")
          .required("Vui lòng điền OTP")
      : yup.string().default(""),
  });
  const [countState, setCountState] = React.useState<CountState>(
    CountState.INIT
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  useEffect(() => {
    if (isConfirmOTP) {
      recaptchaVerifierInvisible("btn-submit-password").then(() => {
        console.log("Init Login OTP Success");
      });
    }
  }, [isConfirmOTP]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<ConfirmPasswordFormValue>({
    mode: "onSubmit",
    resolver: yupResolver(ConfirmPasswordFormSchema),
  });
  const onSubmit: SubmitHandler<ConfirmPasswordFormValue> = (data) => {
    setIsLoading(true);
    (async () => {
      try {
        const id_token = isConfirmOTP ? await verifyCode(data.otp) : "";
        await onSubmitData(data, id_token);
        setIsLoading(false);
        reset({
          password: "",
          newPassword: "",
          newPasswordConfirmation: "",
        });
      } catch (err) {
        setIsLoading(false);
      }
    })();
  };
  const handleClickSendOtp = () => {
    clearErrors();
    if (!phone || !phone.length) {
      const { close } = AlertModal({
        title: "Lỗi!",
        description: "Vui lòng cung cấp số điện thoại",
        type: "error",
        bottomText: "Thử lại",
        onBottomClick: () => {
          close();
        },
      });
      return;
    }
    if (PHONE_REGEX.test(phone)) {
      const { close } = AlertModal({
        title: "Lỗi!",
        description: "Vui lòng cung cấp đúng định dạng số điện thoại",
        type: "error",
        bottomText: "Thử lại",
        onBottomClick: () => {
          close();
        },
      });
      return;
    }
    (async () => {
      try {
        setCountState(CountState.START);
        await sendOTP(phone, "RESET_PASS");
      } catch (err) {
        //TODO: handle send sms erorr
        console.log("Send SMS Error: ", err);
        setCountState(CountState.RELOAD);
        if (err.code === ErrorType.LIMIT) {
          const { close } = AlertModal({
            title: "Lỗi!",
            description:
              "Vượt quá giới hạn gửi SMS. Vui lòng thử lại sau 10 phút.",
            type: "error",
            bottomText: "Đã hiểu",
            onBottomClick: () => {
              close();
            },
          });
        } else {
          const { close } = AlertModal({
            title: "Lỗi!",
            description: "Có lổi xảy ra. Vui lòng thử lại.",
            type: "error",
            bottomText: "Thử lại",
            onBottomClick: () => {
              close();
            },
          });
        }
      }
    })();
  };

  const onChangeCountState = (state: CountState) => {
    setCountState(state);
  };

  return (
    <form className={classNames(className)} onSubmit={handleSubmit(onSubmit)}>
      {isSetPassword && (
        <Controller
          name="password"
          control={control}
          defaultValue={""}
          render={({ field: { ref, ...others } }) => (
            <Input
              label="Mật khẩu cũ"
              className="w-full mb-3"
              type="password"
              errorMsg={get(errors, "password.message", "")}
              inputRef={ref}
              {...others}
            />
          )}
        />
      )}
      <Controller
        name="newPassword"
        control={control}
        defaultValue={""}
        render={({ field: { ref, ...others } }) => (
          <Input
            label="Mật khẩu mới"
            className="w-full mb-3"
            type="password"
            errorMsg={get(errors, "newPassword.message", "")}
            inputRef={ref}
            {...others}
          />
        )}
      />
      <Controller
        name="newPasswordConfirmation"
        control={control}
        defaultValue={""}
        render={({ field: { ref, ...others } }) => (
          <Input
            label="Xác nhận mật khẩu mới"
            className="w-full mb-3"
            type="password"
            errorMsg={get(errors, "newPasswordConfirmation.message", "")}
            inputRef={ref}
            {...others}
          />
        )}
      />
      {isConfirmOTP && (
        <Controller
          name="otp"
          control={control}
          defaultValue=""
          render={({ field: { ref, ...others } }) => (
            <Input
              label="OTP"
              className="w-full col-span-2"
              errorMsg={get(errors, "otp.message", "")}
              inputRef={ref}
              endAdornment={
                <div className="w-52">
                  <CountdownButton
                    id="btn-submit-otp"
                    infType="tertiary"
                    countFrom={120}
                    completeText="Gửi lại mã"
                    countState={countState}
                    onChangeCountState={onChangeCountState}
                    onClick={handleClickSendOtp}
                  >
                    Gửi mã
                  </CountdownButton>
                </div>
              }
              {...others}
            />
          )}
        />
      )}
      <div className="mt-4">
        <Button id="btn-submit-password" type="submit" loading={isLoading}>
          {submitText}
        </Button>
      </div>
    </form>
  );
};

export default ConfirmPasswordForm;
