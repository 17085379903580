export enum AuthenFlow {
  LOGIN,
  REGISTER,
  REGISTER_STEP_TWO,
  FORGOT_PASSWORD,
}

export enum EditProfileFlow {
  GENERAL = 1,
  INFO,
  SOCIAL,
  TAB_INFO,
  TAB_ONE,
  TAB_TWO,
  TAB_THREE,
  TAB_FOUR,
  TAB_FIVE,
  TAB_SIX,
  MOBILE_GENERAL_INFO,
  MOBILE_GENERAL_TAB,
}
