import React from "react";
import Button, { Props as ButtonProps } from "./button";
import { makeStyles } from "@material-ui/core/styles";

export interface Props extends ButtonProps {
  icon: React.ReactElement;
}

const useStyles = makeStyles({
  root: {
    minWidth: "fit-content",
    height: "fit-content",
    width: "fit-content",
    padding: "0.25rem",
  },
});
const ButtonIcon: React.FC<Props> = ({ icon, children, ...others }) => {
  const classes = useStyles();
  return (
    <Button classes={classes} {...others}>
      {icon}
    </Button>
  );
};

export default ButtonIcon;
