import {call, put} from "redux-saga/effects";
import * as Sentry from "@sentry/nextjs";
import * as api from "features/user/api";
import {appInitFail, appInitSuccess, closeRegister} from "features/app/appSlice";
import {updateUserInfo, userAccessToken, userLoginSuccess,} from "features/user/userSlice";
import {SagaIterator} from "redux-saga";
import {PayloadAction} from "@reduxjs/toolkit";
import {getCategory} from "features/categogy/api";
import {getJob} from "features/job/api";
import {updateCategories} from "features/categogy/categorySlice";
import {updateJobs} from "features/job/jobSlice";
import {IUserInfo} from "features/user/type";
import Cookies from "js-cookie";

export function* appInitSaga(): SagaIterator<void> {
  try {
    console.log("app init");
    // try {
    //   const result = yield call(getRedirectResult);
    //   const isLinkedProfileRedirect = !!sessionStorage.getItem("linkedProfile");
    //   if (result && !isLinkedProfileRedirect) {
    //     try {
    //       const {token} = yield call(checkUserLoginResult, result);
    //       yield put(userAccessToken(token));
    //     } catch (err) {
    //       if (err?.code == ErrorType.ERROR_SOCIAL_ACCOUNT_NOT_REGISTERED) {
    //         isNotRegister = true;
    //         yield put(openRegister());
    //       }
    //       console.error("GET USER INFO ERROR: ", err);
    //     }
    //   } else {
    //     const token = Cookies.get("token");
    //     if (token) {
    //       try {
    //         yield put(userAccessToken(token));
    //       } catch (err) {
    //         yield put(userAccessToken(""));
    //         console.log("ERROR IN GET INFO", err);
    //       }
    //     }
    //   }
    //   if (isLinkedProfileRedirect && result) {
    //     try {
    //       const provider = yield call(linkAccountWithFirebaseResult, result);
    //       sessionStorage.removeItem("linkedProfile");
    //       sessionStorage.setItem("linkedProfileSuccess", `${provider ?? 'true'}`);
    //     } catch (err) {
    //       sessionStorage.removeItem("linkedProfile");
    //       sessionStorage.setItem("linkedProfileFail", "1");
    //       console.error("LINK PROFILE ERROR: ", err);
    //     }
    //   }
    // } catch (e) {
    //   console.log("ERROR LOGIN TOKEN: ", e);
    // }
    // const token = yield select((state: RootState) => state.user.accessToken);
    const token = Cookies.get("token");
    if (token) {
      try {
        yield put(userAccessToken(token));
      } catch (err) {
        yield put(userAccessToken(""));
        console.log("ERROR IN GET INFO", err);
      }
    }
    //Get user info
    try {
      if (token && token.length) {
        const {user} = yield call(api.getProfile);
        if (user) {
          yield put(userLoginSuccess());
          yield put(updateUserInfo(new IUserInfo(user)));
          yield put(closeRegister());
        }
      }
    } catch (err) {
      console.log("ERROR USER INFO: ", err);
    }
    //Get getCategory
    try {
      const {categories} = yield call(getCategory);
      if (categories && categories.length > 0) {
        yield put(updateCategories(categories));
      }
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error Categogy", err);
    }
    try {
      const {jobs} = yield call(getJob);
      if (jobs && jobs.length > 0) {
        yield put(updateJobs(jobs));
      }
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error Job", err);
    }
    yield put(appInitSuccess());
  } catch (err: any) {
    Sentry.captureException(err);
    yield put(
      appInitFail({
        errorMsg: err?.message ?? "Không xác định",
      })
    );
  }
}

export function handleAppError(
  action: PayloadAction<{
    errorCode: string;
    errorMsg: string;
    errorMsgShow?: string;
  }>
) {
  //TODO: Handle app Error
  console.log("APP_ERROR: ", action.payload);
}
