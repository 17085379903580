import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Home: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M9.547 21.602H5.418a.82.82 0 01-.883-.747V9.867m9.918 11.735h4.129a.818.818 0 00.883-.747V9.867m-5.012 11.735v-7.36H9.547v7.36"
          stroke="#c6c6c6"
          strokeWidth={1.7999999999999998}
        />
        <path
          d="M12 2.398L2.398 12M12 2.398L21.602 12"
          stroke="gray"
          strokeWidth={1.7999999999999998}
        />
      </g>
    </SvgIcon>
  );
};
export default Home;
