import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";

type Type =
  | "secondary"
  | "tertiary"
  | "fourth"
  | "whiteBlack"
  | "linkSocial"
  | "blueWhite"
  | "tableAdd"
  | "profileEdit"
  | "profileEditMobile";
type Size = "s" | "m";
type WidthType = "fit";
export interface Props extends MuiButtonProps {
  infType?: Type;
  infSize?: Size;
  loading?: boolean;
  classes?: { root?: string };
  width?: WidthType;
}

const useStyles = makeStyles({
  root: {
    background: "#01334c",
    borderRadius: "0.75rem",
    color: "#fff",
    height: "2.375rem",
    width: "100%",
    "&:hover": {
      backgroundColor: "#012435",
    },
    "&:focus": {
      outline: "none",
    },
    "&$disabled": {
      backgroundColor: "#f5f7fa",
      color: "#d3d3d3",
    },
  },
  fit: {
    minWidth: "fit-content",
    "& > .MuiButton-label": {
      padding: 0,
    },
  },
  label: {
    textTransform: "none",
  },
  buttonProgress: {
    color: "#01334c",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-0.75rem",
    marginLeft: "-0.75rem",
  },
  disabled: {},
  secondary: {
    background: "#f5f7fa",
    color: "#4d4d4d",
    "&:hover": {
      background: "#c4c6c8",
    },
  },
  tertiary: {
    background: "#fff",
    color: "#005ed0",
    "&:hover": {
      background: "#b3cff1",
    },
  },
  fourth: {
    background: "#e3e6ea",
    color: "#4d4d4d",
    "&:hover": {
      background: "#b6b8bb",
    },
  },
  whiteBlack: {
    color: "#4d4d4d",
    background: "#fff",
    "&:hover": {
      background: grey[200],
    },
  },
  profileEdit: {
    background: "#f5f7fa",
    color: "#4d4d4d",
    fontSize: "0.75rem",
    "&:hover": {
      background: "#dddee1",
    },
  },
  profileEditMobile: {
    background: "#01334c",
    color: "#fff",
    fontSize: "0.75rem",
    "&:hover": {
      backgroundColor: "#012435",
    },
  },
  tableAdd: {
    background: "#e3e6ea",
    color: "#808080",
    "&:hover": {
      background: "#b6b8bb",
    },
  },
  linkSocial: {
    color: "#4d4d4d",
    background: "#fff",
    "&:hover": {
      background: grey[200],
    },
    padding: "0.6875rem 0.5rem",
  },
  s: {
    fontSize: "0.75rem",
    borderRadius: "0.4rem",
    height: "1.875rem",
  },
  m: {},
  blueWhite: {
    color: "#fff",
    background: "#005ed0",
    "&:hover": {
      background: "#0080d0",
    },
  },
});

const Button: React.FC<Props> = ({
  infType,
  infSize = "m",
  width,
  loading,
  disabled,
  classes: propsClasses = {},
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <div className="w-full relative">
      <MuiButton
        classes={{
          root: classNames(
            classes.root,
            infType ? classes[infType] : "",
            classes[infSize],
            width ? classes[width] : "",
            propsClasses.root
          ),
          label: classes.label,
          disabled: classes.disabled,
        }}
        disabled={loading || disabled}
        {...otherProps}
      />
      {loading && (
        <CircularProgress size={"1.5rem"} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default Button;
