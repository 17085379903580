import React from "react";
import Image from "next/image";
import classNames from "classnames";
import Link from "next/link";

type NavItem = {
  icon: string;
  label: string;
  path: string;
};
interface Props {
  navItems: NavItem[];
  activePath: string;
}
interface ItemProps {
  navItem: NavItem;
  isActive: boolean;
}

const activeClass = `bg-pale-grey`;

const SidemenuItem: React.FC<ItemProps> = ({ navItem, isActive }) => {
  return (
    <Link href={navItem.path}>
      <li
        className={classNames(
          "flex items-center p-2 w-48 h-10 mb-2 rounded-lg relative hover:bg-pale-grey cursor-pointer text-greyish-brown text-sm relative",
          isActive ? activeClass : ""
        )}
      >
        {isActive && (
          <div className="absolute w-1.5 h-full top-0 -left-5 bg-navy rounded-full rounded-l-none"></div>
        )}
        <div className="flex items-center w-full h-full overflow-hidden relative">
          <span className="w-5 h-5 mr-4">
            <Image
              src={navItem.icon}
              alt={`navigation icon for ${navItem.label}`}
              height={20}
              width={20}
            />
          </span>
          <span>{navItem.label}</span>
        </div>
      </li>
    </Link>
  );
};

const Sidemenu: React.FC<Props> = ({ navItems, activePath }) => {
  const removeQueryString = React.useCallback((p: string) => {
    const temp1 = /(\/[^?]+).*/.exec(p);
    if (temp1) {
      return temp1[1];
    }
    return p;
  }, []);
  return (
    <ul>
      {navItems.map((navItem) => (
        <SidemenuItem
          key={navItem.path}
          navItem={navItem}
          isActive={navItem.path === removeQueryString(activePath)}
        />
      ))}
    </ul>
  );
};

export default Sidemenu;
