import React from "react";
import Button, { Props as ButtonProps } from "./button";

export interface Props extends ButtonProps {
  countFrom: number;
  completeText?: string | React.ReactNode;
  countState: CountState;
  onChangeCountState: (state: CountState)=> void;
}

export enum CountState {
  INIT,
  START,
  COMPLETE,
  RELOAD
}

const CountdownButton: React.FC<Props> = ({
  countFrom,
  countState,
  onChangeCountState,
  children,
  onClick,
  completeText,
  disabled,
  ...others
}) => {
  const [_count, setCount] = React.useState<number>(countFrom);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const [disabledTemp, setDisabledTemp] = React.useState(false);
  const timerDisabledRef = React.useRef<NodeJS.Timeout | null>(null);
  React.useEffect(() => {
    if (countState === CountState.START) {
      const s = Math.random();
      setCount(countFrom);
      const recurryTimer = () => {
        let sr: number | null = s;
        setCount((i) => {
          if (i === 0) {
            sr = null;
            onChangeCountState(CountState.COMPLETE);
            return i;
          }
          return i - 1;
        });
        timerRef.current = setTimeout(() => {
          if (sr === s) {
            recurryTimer();
            return;
          }
        }, 1000);
      };
      recurryTimer();
    } else if(countState === CountState.RELOAD) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setCount(countFrom);
    }
  }, [countState]);

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (timerDisabledRef.current) {
        clearTimeout(timerDisabledRef.current);
      }
    };
  }, []);

  const injectedClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    // onChangeCountState(CountState.START);
    setDisabledTemp(true);
    timerDisabledRef.current = setTimeout(()=>  setDisabledTemp(false), 1000);
    if (onClick && typeof onClick === "function") {
      onClick(e);
    }
  };
  return (
    <Button
      onClick={injectedClick}
      disabled={countState === CountState.START || disabled || disabledTemp}
      {...others}
    >
      {countState === CountState.COMPLETE && completeText ? (
        <>{completeText}</>
      ) : (
        <>
          {children} ({_count})
        </>
      )}
    </Button>
  );
};

export default CountdownButton;
