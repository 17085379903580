import React from "react";
import get from "lodash/get";
import Input, { Props as InputProps } from "./input";

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface Props extends InputProps {}

const InputSlug: React.FC<Props> = ({ value, onChange, ...others }) => {
  const _onChange = React.useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >((e) => {
    const eValue = e.target.value.toLowerCase();
    const inputType = get(e, "nativeEvent.inputType");
    if (inputType === "insertFromPaste") {
      if (/^[a-z](-?[a-z])*$/.test(eValue)) {
        if (onChange) {
          onChange(e);
        }
      }
      return;
    }

    if (eValue.length === 0) {
      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: "",
          },
        });
      }
      return;
    }
    const lastestChar = eValue[eValue.length - 1];
    if (
      lastestChar &&
      /[a-z0-9-]$/.test(lastestChar) &&
      /^[a-z]((?!--).)*$/.test(eValue)
    ) {
      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: eValue,
          },
        });
      }
    }
  }, []);
  return <Input value={value} onChange={_onChange} {...others} />;
};

export default InputSlug;
