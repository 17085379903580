import React from "react";
import classnames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { gsap } from "gsap";

/* eslint-disable @typescript-eslint/no-empty-interface */
interface Props {
  className?: string;
  inputClassName?: string;
  prefixIcon?: React.ReactElement;
  text?: string;
  copyText?: string;
  placeholder?: string;
  type?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CopyableInput: React.FC<Props> = ({
  className,
  inputClassName,
  text,
  copyText,
  placeholder,
  prefixIcon,
  type = "default",
  onClick,
}) => {
  const [animationTimeline] = React.useState<gsap.core.Timeline>(() =>
    gsap.timeline()
  );
  const textCopiedRef = React.useRef<HTMLSpanElement>(null);
  const glowCopiedText = React.useCallback(() => {
    animationTimeline.clear();
    animationTimeline
      .set(textCopiedRef.current, { opacity: 0, y: "100%" })
      .to(textCopiedRef.current, { opacity: 1, y: 0, duration: 0.5 })
      .to(textCopiedRef.current, { opacity: 0, y: "-100%", duration: 1 }, 2);
  }, [animationTimeline]);

  return (
    <div
      className={classnames(
        "w-full flex items-center",
        className,
        type === "default" && "bg-pale-grey px-3 py-2 rounded-xl",
        type === "menu" && "bg-transparent p-0"
      )}
      onClick={onClick}
    >
      {prefixIcon ? (
        prefixIcon
      ) : (
        <img
          src="/assets/sidemenu-logo/link-icon.svg"
          width={20}
          height={20}
          alt="link icon"
        />
      )}
      {text && (
        <input
          className={classnames(
            "w-full ml-2 text-sm flex-grow text-greyish-brown bg-transparent focus:outline-none focus-visible:outline-none",
            inputClassName
          )}
          value={text}
          readOnly
        />
      )}
      {!text && placeholder && (
        <span className="ml-2 text-sm flex-grow text-greyish-brown line-clamp-1 break-all opacity-50">
          {placeholder}
        </span>
      )}
      <span
        className="mr-1 text-sm opacity-0 transform translate-y-full"
        ref={textCopiedRef}
      >
        Copied!
      </span>
      {copyText || text ? (
        <CopyToClipboard text={copyText || text || ""} onCopy={glowCopiedText}>
          <img
            src="/assets/sidemenu-logo/copy-icon.svg"
            width={20}
            height={20}
            alt="link icon"
            className="cursor-pointer"
          />
        </CopyToClipboard>
      ) : null}
    </div>
  );
};

export default CopyableInput;
