import React from "react";
import RegisterStepOne from "./register-step-one";
import RegisterStepTwo from "./register-step-two";
import { AuthenFlow } from "lib/enums";
import { useRouter } from "next/router";

interface Props {
  handleClose: () => void;
  defaultStep?: RegisterStep;
  changeFlow: (toFlow: AuthenFlow) => void;
}

export enum RegisterStep {
  STEP_ONE,
  STEP_TWO,
}

const Register: React.FC<Props> = ({ handleClose, changeFlow, defaultStep= RegisterStep.STEP_ONE }) => {
  const [step, setStep] = React.useState<RegisterStep>(defaultStep);
  const router = useRouter();
  const [stepOneData, setStepOneData] = React.useState<{
    phone: string;
    otp: string;
  }>({ phone: "", otp: "" });

  const onStepOneSubmit = (data: { phone: string; otp: string }) => {
    setStepOneData(data);
    setStep(RegisterStep.STEP_TWO);
  };

  const onStepTwoSubmit = () => {
    handleClose();
    router.push('/quan-ly');
  };

  const onStepTwoBack = () => {
    if(defaultStep === RegisterStep.STEP_ONE){
      setStep(RegisterStep.STEP_ONE);
    } else {
      changeFlow(AuthenFlow.LOGIN);
    }
  };

  return (
    <>
      {step === RegisterStep.STEP_ONE && (
        <RegisterStepOne
          handleClose={handleClose}
          changeFlow={changeFlow}
          stepSubmit={onStepOneSubmit}
          defaultValue={{
            phone: stepOneData.phone,
          }}
        />
      )}
      {step === RegisterStep.STEP_TWO && (
        <RegisterStepTwo
          isLoginThirdParty={!(stepOneData.otp?.length > 0)}
          stepBack={onStepTwoBack}
          stepSubmit={onStepTwoSubmit}
          defaultValue={stepOneData}
        />
      )}
    </>
  );
};

export default Register;
