import React from "react";
import get from "lodash/get";
import { useRouter } from "next/router";
import Link from "next/link";
import { useAppSelector, useAppDispatch } from "store";
import { appSearch } from "features/app/appSlice";
import Logo from "components/logo";
/* import MenuIcon from "components/icons/Menu"; */
import CloseIcon from "components/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import ClearIcon from "@material-ui/icons/Clear";
import Menu from "components/menu";
import MenuItem from "components/menu-item";
import SearchBox from "components/searchbox";
import ChatIcon from "components/icons/Chat";
import ProfileIcon from "components/icons/ProfileTopMenu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import DashboardIcon from "./icons/Dashboard";
import { userLogout } from "features/user/api";

interface IconProps extends SvgIconProps {
  type: string;
}
const Icon: React.FC<IconProps> = ({ type, ...others }) => {
  switch (type) {
    case "DashboardIcon":
      return <DashboardIcon {...others} />;
    case "ExitToAppIcon":
      return <ExitToAppIcon {...others} />;
    case "ChatIcon":
      return <ChatIcon {...others} />;
    case "ProfileIcon":
      return <ProfileIcon {...others} />;
    default:
      return null;
  }
};

type NavItem = {
  icon: string;
  label: string;
  action: string;
};

interface Props {
  isOpenMobileMenu: boolean;
  setIsOpenMobileMenu: (I: boolean) => void;
  navItems: NavItem[];
}

const MobileTopMenu: React.FC<Props> = ({
  isOpenMobileMenu,
  setIsOpenMobileMenu,
  navItems,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector((state) =>
    get(state.user, "user.avatar", "")
  );
  const [isOpenSearch, setIsOpenSearch] = React.useState(false);
  const isLogin = useAppSelector((state) => state.user.isLoggedIn);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (action: string) => {
    setAnchorEl(null);
    if (action === "logout") {
      userLogout()
        .then(() => {
          sessionStorage.setItem("isDisableLottie", "true");
          router.reload();
        })
        .catch((err) => {
          router.push("/");
          // const { close } = AlertModal({
          //   title: "Có lỗi xảy ra",
          //   description: get(err, "messageShow", err.message),
          //   type: "success",
          //   bottomText: "Hoàn thành",
          //   onBottomClick: () => close(),
          // });
        });
    }
    if (action === "profile") {
      router.push("/quan-ly");
    }
    if (action === "chat") {
      router.push("/chat");
    }
    if (action === "account") {
      router.push("/tai-khoan");
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (isOpenSearch) {
      setIsOpenMobileMenu(false);
    }
  }, [isOpenSearch]);

  React.useEffect(() => {
    if (!isOpenSearch) {
      dispatch(appSearch({ searchKey: "" }));
    }
  }, [isOpenSearch]);

  return (
    <div className="flex relative sm:hidden justify-between items-center h-16 p-5 bg-grey-top-bar">
      {isOpenSearch && (
        <div className="flex items-center w-full">
          <SearchBox className="h-10 flex-grow" />
          <div className="w-6 h-6 rounded-full bg-pale-grey-avatar flex items-center justify-center">
            <ClearIcon
              style={{ fontSize: "1rem", color: "#4d4d4d" }}
              onClick={() => setIsOpenSearch(false)}
            />
          </div>
          {/* <div className="w-5 h-5 mr-3"> */}
          {/*   <img */}
          {/*     className="w-full h-auto" */}
          {/*     src="/assets/sidemenu-logo/search-icon.svg" */}
          {/*   /> */}
          {/* </div> */}
        </div>
      )}
      {!isOpenSearch && (
        <>
          <div className="flex items-center">
            {isOpenMobileMenu ? (
              <CloseIcon onClick={() => setIsOpenMobileMenu(false)} />
            ) : (
              <>
                {!isLogin && (
                  <Link href="/m/dang-nhap">
                    <PersonIcon
                      style={{ fontSize: "1.25rem", color: "#808080" }}
                    />
                  </Link>
                )}
                {isLogin && (
                  <>
                    <div className="flex items-center" onClick={onAvatarClick}>
                      {!userAvatar && (
                        <PersonIcon
                          style={{ fontSize: "1.25rem", color: "#808080" }}
                        />
                      )}
                      {userAvatar && (
                        <div className="w-6 h-6 rounded-lg overflow-hidden">
                          <img className="w-full h-auto" src={userAvatar} />
                        </div>
                      )}
                    </div>
                    <Menu
                      id="user-menu-dropdown"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      maxHeight={"220px"}
                    >
                      {navItems.map((i) => (
                        <MenuItem
                          key={i.action}
                          onClick={handleClick.bind(null, i.action)}
                        >
                          <div className="flex items-center">
                            <Icon
                              type={i.icon}
                              style={{
                                fontSize: "1.25rem",
                                marginRight: "0.25rem",
                              }}
                            />
                            {i.label}
                          </div>
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </>
            )}
          </div>
          <Link href="/">
            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
              <Logo />
            </div>
          </Link>
          <div className="flex items-center">
            <div className="w-5 h-5 mr-4 last:mr-0">
              <img
                className="w-full h-auto"
                src="/assets/sidemenu-logo/search-icon.svg"
                onClick={() => setIsOpenSearch(true)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileTopMenu;
