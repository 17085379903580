export const API_DOMAIN = process.env.NEXT_PUBLIC_API_URL;
export const WEB_NAME = "Influzee";
export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
export const DEBUG_GA =
  (process.env.NEXT_PUBLIC_DEBUG_GA || "false") === "true";
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID || "";
export const ENC_KEY = process.env.NEXT_PUBLIC_ENC_KEY;
export const DEFAULT_BIO = process.env.NEXT_PUBLIC_DEFAULT_BIO || "";
export const DEFAULT_SEO_TITLE = "Influzee - Making For Influencers";
export const DEFAULT_SEO_DESCRIPTION =
  "Influzee là nền tảng xây dựng và kết nối thương hiệu được phát triển dành riêng cho Influencers.";
export const DEFAULT_SEO_IMAGE_URL = `https://${DOMAIN}assets/seo/social_thumbnail.jpg`;
export const DEFAULT_SEO_IMAGE = [
  {
    url: DEFAULT_SEO_IMAGE_URL,
    width: 2048,
    height: 1075,
    type: "image/jpeg",
    alt: "Influzee Thumbnail",
  },
];
export const URL_HIDE_BOTTOM_MENU = ["/profile-m/[pid]"];
export const SIDE_MENU = [
  {
    icon: "/assets/sidemenu-logo/home-icon.svg",
    label: "Trang chủ",
    path: "/",
  },
  {
    icon: "/assets/sidemenu-logo/category-icon.svg",
    label: "Lĩnh vực",
    path: "/linh-vuc",
  },
  {
    icon: "/assets/sidemenu-logo/facebook-icon.svg",
    label: "Facebook",
    path: "/social/facebook",
  },
  {
    icon: "/assets/sidemenu-logo/tiktok-icon.svg",
    label: "Tiktok",
    path: "/social/tiktok",
  },
  {
    icon: "/assets/sidemenu-logo/instagram-icon.svg",
    label: "Instagram",
    path: "/social/instagram",
  },
  {
    icon: "/assets/sidemenu-logo/youtube-icon.svg",
    label: "Youtube",
    path: "/social/youtube",
  },
  {
    icon: "/assets/sidemenu-logo/affiliate-icon.svg",
    label: "Affiliate",
    path: "/affiliate",
  },
  {
    icon: "/assets/sidemenu-logo/booking-icon.svg",
    label: "Booking",
    path: "/booking",
  },
  {
    icon: "/assets/sidemenu-logo/search-icon.svg",
    label: "Tìm kiếm",
    path: "/tim-kiem",
  },
];

export const BOTTOM_MENU = [
  {
    icon: "home",
    label: "Trang chủ",
    path: "/",
  },
  {
    icon: "category",
    label: "Lĩnh vực",
    path: "/linh-vuc",
  },
  {
    icon: "chat",
    label: "Trò chuyện",
    path: "/chat",
  },
  {
    icon: "profile",
    label: "Cá nhân",
    path: "account",
  },
  {
    icon: "menu",
    label: "Menu",
    path: "menu",
  },
];

export const USER_MENU = [
  // {
  //   icon: "/assets/sidemenu-logo/analytics-icon.svg",
  //   label: "Thống kê",
  //   path: "/analytic",
  // },
  {
    icon: "/assets/sidemenu-logo/dashboard-icon.svg",
    label: "Quản lý trang",
    path: "/quan-ly",
  },
  {
    icon: "/assets/sidemenu-logo/profile-icon.svg",
    label: "Tài khoản",
    path: "/tai-khoan",
  },
  {
    icon: "/assets/sidemenu-logo/chat-icon.svg",
    label: "Trò chuyện",
    path: "/chat",
  },
];

export const TOP_MENU = [
  {
    icon: "DashboardIcon",
    label: "Quản lý trang",
    action: "profile",
  },
  {
    icon: "ExitToAppIcon",
    label: "Đăng xuất",
    action: "logout",
  },
];

export const TOP_MOBILE_MENU = [
  {
    icon: "DashboardIcon",
    label: "Quản lý trang",
    action: "profile",
  },
  {
    icon: "ChatIcon",
    label: "Trò chuyện",
    action: "chat",
  },
  {
    icon: "ProfileIcon",
    label: "Tài khoản",
    action: "account",
  },

  {
    icon: "ExitToAppIcon",
    label: "Đăng xuất",
    action: "logout",
  },
];

export const PROFILE_MORE_MENU = [
  {
    icon: "/assets/sidemenu-logo/heart-icon.svg",
    label: "Yêu thích",
    action: "favorite",
  },
  {
    icon: "/assets/sidemenu-logo/share-icon.svg",
    label: "Chia sẻ",
    action: "share",
  },
  {
    icon: "/assets/sidemenu-logo/report-icon.svg",
    label: "Báo cáo",
    action: "report",
  },
];

export const LOGIN_METHODS = [
  {
    method: "facebook",
    icon: "/assets/sidemenu-logo/facebook-logo.webp",
    label: "Đăng nhập bằng Facebook",
    name: "Facebook",
    isDisabled: false,
  },
  {
    method: "google",
    icon: "/assets/sidemenu-logo/google-icon.svg",
    label: "Đăng nhập bằng Google",
    name: "Google",
    isDisabled: false,
  },
  // {
  //   method: "instagram",
  //   icon: "/assets/sidemenu-logo/instagram-icon.svg",
  //   label: "Đăng nhập bằng Instagram",
  //   name: "Instagram",
  // },
  {
    method: "apple",
    icon: "/assets/sidemenu-logo/apple-icon.svg",
    label: "Đăng nhập bằng Apple",
    name: "Apple",
    isDisabled: true,
  },
  {
    method: "tiktok",
    icon: "/assets/sidemenu-logo/tiktok-icon.svg",
    label: "Đăng nhập bằng Tiktok",
    name: "Tiktok",
    isDisabled: true,
  },
];

type OPTION = { label: string; value: string };
const _optionToPair = (options: OPTION[]) => {
  const result: { [key: string]: string } = {};
  options.forEach((i) => {
    result[i.value] = i.label;
  });
  return result;
};

export const HOMETOWN_OPTIONS = [
  "Hà Nội",
  "Hà Giang",
  "Cao Bằng",
  "Bắc Kạn",
  "Tuyên Quang",
  "Lào Cai",
  "Điện Biên",
  "Lai Châu",
  "Sơn La",
  "Yên Bái",
  "Hoà Bình",
  "Thái Nguyên",
  "Lạng Sơn",
  "Quảng Ninh",
  "Bắc Giang",
  "Phú Thọ",
  "Vĩnh Phúc",
  "Bắc Ninh",
  "Hải Dương",
  "Hải Phòng",
  "Hưng Yên",
  "Thái Bình",
  "Hà Nam",
  "Nam Định",
  "Ninh Bình",
  "Thanh Hóa",
  "Nghệ An",
  "Hà Tĩnh",
  "Quảng Bình",
  "Quảng Trị",
  "Thừa Thiên Huế",
  "Đà Nẵng",
  "Quảng Nam",
  "Quảng Ngãi",
  "Bình Định",
  "Phú Yên",
  "Khánh Hòa",
  "Ninh Thuận",
  "Bình Thuận",
  "Kon Tum",
  "Gia Lai",
  "Đắk Lắk",
  "Đắk Nông",
  "Lâm Đồng",
  "Bình Phước",
  "Tây Ninh",
  "Bình Dương",
  "Đồng Nai",
  "Bà Rịa - Vũng Tàu",
  "TP. Hồ Chí Minh",
  "Long An",
  "Tiền Giang",
  "Bến Tre",
  "Trà Vinh",
  "Vĩnh Long",
  "Đồng Tháp",
  "An Giang",
  "Kiên Giang",
  "Cần Thơ",
  "Hậu Giang",
  "Sóc Trăng",
  "Bạc Liêu",
  "Cà Mau",
]
  .sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  })
  .map((i) => ({ value: i, label: i }));

export const GENDER_OPTIONS = [
  { value: "male", label: "Nam" },
  { value: "female", label: "Nữ" },
  { value: "other", label: "Khác" },
];

export const SEARCH_PLATFORM_OPTIONS = [
  { label: "Tất cả", value: "all" },
  { label: "Facebook", value: "facebook" },
  { label: "Facebook group", value: "facebook_group" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Instagram", value: "instagram" },
  { label: "Youtube", value: "youtube" },
];

export const SEARCH_PLATFORM_PAIR = _optionToPair(SEARCH_PLATFORM_OPTIONS);

export const SUPPORTED_PLATFORM_OPTIONS = [
  { label: "Facebook", value: "facebook" },
  { label: "Facebook group", value: "facebook_group" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Instagram", value: "instagram" },
  { label: "Youtube", value: "youtube" },
];

export const SUPPORTED_PLATFORM_PAIR = _optionToPair(
  SUPPORTED_PLATFORM_OPTIONS
);

export const FILTER_PLATFORM_OPTIONS = [
  { label: "Tất cả nền tảng", value: "all" },
  { label: "Facebook", value: "facebook" },
  { label: "Facebook group", value: "facebook_group" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Instagram", value: "instagram" },
  { label: "Youtube", value: "youtube" },
];
export const FILTER_PLATFORM_PAIR = _optionToPair(FILTER_PLATFORM_OPTIONS);

export const FILTER_PLATFORM_MOBILE_OPTIONS = [
  { label: "Tất cả", value: "all" },
  { label: "Facebook", value: "facebook" },
  { label: "Facebook group", value: "facebook_group" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Instagram", value: "instagram" },
  { label: "Youtube", value: "youtube" },
];
export const FILTER_PLATFORM_MOBILE_PAIR = _optionToPair(
  FILTER_PLATFORM_MOBILE_OPTIONS
);

export const FILTER_FOLLOWER_OPTIONS = [
  { label: "Tất cả lượt theo dõi", value: "all" },
  { label: "Dưới 10.000", value: "less_than_10000" },
  { label: "10.000 - 50.000", value: "10000_to_50000" },
  { label: "50.000 - 200.000", value: "50000_to_200000" },
  { label: "200.000 - 500.000", value: "200000_to_500000" },
  { label: "500.000 - 1.000.000", value: "500000_to_1000000" },
  { label: "Trên 1.000.000", value: "more_than_1000000" },
];
export const FILTER_FOLLOWER_PAIR = _optionToPair(FILTER_FOLLOWER_OPTIONS);

export const SORTING_OPTIONS = [
  // TODO: Tạm ẩn Sorting
  // { label: "Lượt xem (Cao - Thấp)", value: "view" },
  // { label: "Lượt xem (Thấp - Cao)", value: "-view" },
  { label: "Mặc định", value: "none" },
  { label: "Lượt theo dõi (Cao  - Thấp)", value: "follow" },
  { label: "Lượt theo dõi  (Thấp - Cao)", value: "-follow" },
  { label: "Theo tên (A-Z)", value: "-full_name" },
  { label: "Theo tên (Z-A)", value: "full_name" },
];

export const SORTING_PAIR = _optionToPair(SORTING_OPTIONS);

export const TAB_POSITION_OPTIONS = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  { label: "4", value: 4 },
  // {
  //   label: "04",
  //   value: 4,
  // },
];

export const STATS_EVENT = {
  profileView: "profile_view",
  linkClick: "link_click",
  socialClick: "social_link_click",
};
export const ANALYTICS_DATE_OPTIONS = [
  {
    label: "7 ngày",
    value: "week",
  },
  {
    label: "30 ngày",
    value: "month",
  },
  // {
  //   label: "Tất cả",
  //   value: "lifetime",
  // },
];
