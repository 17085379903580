import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Mail: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <g
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M18 6.667v8.889c0 .49-.398.888-.889.888H2.89A.889.889 0 012 15.556v-8.89l8 4.445 8-4.444z"
          stroke="currentColor"
        />
        <path
          d="M18 4.889v1.778l-8 4.444-8-4.444V4.889C2 4.398 2.398 4 2.889 4H17.11c.491 0 .889.398.889.889z"
          stroke="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
export default Mail;
