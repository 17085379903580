import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

const Menu: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <g
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="#C6C6C6" d="M4 17.966h16M4 6.479h16" />
        <path stroke="gray" d="M4 12.222h16" />
      </g>
    </SvgIcon>
  );
};
export default Menu;
