import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory } from "./type";

const initialState = {
  list: [] as ICategory[],
};

export const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    updateCategories: (state, action: PayloadAction<ICategory[]>) => {      
      state.list = action.payload;
    },
    concatCategories: (state, action: PayloadAction<ICategory[]>)=> {
        state.list = [...state.list, ...action.payload];
    }
  },
  extraReducers: {}
});
export const {
    updateCategories,
    concatCategories,
  } = categories.actions;
export default categories.reducer;
