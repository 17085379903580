import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiToggleButton, {
  ToggleButtonProps as MuiToggleButtonProps,
} from "@material-ui/lab/ToggleButton";

interface Props extends MuiToggleButtonProps {}
const useStyles = makeStyles({
  root: {
    background: "transparent",
    color: "#808080",
    height: "2.375rem",
    border: "none",
    borderRadius: "0.75rem",
    "&.Mui-selected": {
      backgroundColor: "#01334c",
      color: "#fff",
      borderRadius: "0.75rem",
      "&:hover": {
        backgroundColor: "#012435",
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  label: {
    textTransform: "none",
  },
});

const ToggleButton: React.FC<Props> = ({ ...others }) => {
  const classes = useStyles();
  return (
    <MuiToggleButton
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      {...others}
    />
  );
};

export default ToggleButton;
