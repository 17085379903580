import FingerPrint from "assets/js/fingerprint";
import get from "lodash/get";
import AlertModal from "../components/Dialog/AlertModal";

export const PHONE_REGEX = /(84|0)+([3|5|7|8|9]{1})+([0-9]{8})\b/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEX = /(?=.*[a-zA-Z])/;

export function getComponentDisplayName(WrappedComponent: React.FC) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

const makeId = () => {
  const fp = new FingerPrint({
    canvas: true,
    ie_activex: true,
    screen_resolution: true,
  });
  return fp.get();
};

// Create fake DUID if can not detect device-unique-id
// Store it in LocalStorage for use later
export const getDeviceID = () => {
  if (!localStorage.getItem("")) {
    localStorage.setItem("deviceID", makeId());
  }
  return localStorage.getItem("deviceID") ?? "";
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let phone = "";
  if (phoneNumber.indexOf("0") === 0) {
    phone = phoneNumber.replace("0", "+84");
  } else if (phoneNumber.indexOf("84") === 0) {
    phone = "+" + phoneNumber;
  } else {
    phone = phoneNumber;
  }
  return phone;
};

export const getNullDefault = (object: any, key: string, defaultValue: any) => {
  return get(object, key, defaultValue) ?? defaultValue;
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const checkFileUpload = (file?: File, isShowDialogError = false) => {
  if (!file) return false;
  if (file.type.includes("image/")) {
    const isImageAllowType = (
      process.env["NEXT_PUBLIC_ALLOWED_IMAGE_TYPES"] ?? ""
    ).includes(file.type);
    if (!isImageAllowType) {
      if (isShowDialogError) {
        const { close } = AlertModal({
          title: "Upload thất bại!",
          description:
            "Hình ảnh chỉ hỗ trợ định dạng JPG, PNG, WEBP hoặc HEIC. Vui lòng thử lại.",
          type: "error",
          bottomText: "Thử lại",
          onBottomClick: () => {
            close();
          },
        });
      }
      return false;
    }
    const isImageAllowSize =
      +(process.env["NEXT_PUBLIC_MAX_UPLOADED_IMAGE_SIZE"] ?? "2") *
        1024 *
        1024 >=
      file.size;
    if (!isImageAllowSize) {
      if (isShowDialogError) {
        const { close } = AlertModal({
          title: "Upload thất bại!",
          description: `Dung lượng hình ảnh tối đa ${
            process.env["NEXT_PUBLIC_MAX_UPLOADED_IMAGE_SIZE"] ?? "2"
          }MB. Vui lòng thử lại.`,
          type: "error",
          bottomText: "Thử lại",
          onBottomClick: () => {
            close();
          },
        });
      }
      return false;
    }
    return true;
  }

  if (file.type.includes("video/")) {
    const isVideoAllowType = (
      process.env["NEXT_PUBLIC_ALLOWED_VIDEO_TYPES"] ?? ""
    ).includes(file.type);
    if (!isVideoAllowType) {
      if (isShowDialogError) {
        const { close } = AlertModal({
          title: "Upload thất bại!",
          description:
            "Video chỉ hỗ trợ định dạng MP4, MPEG, WEBM hoặc MOV. Vui lòng thử lại.",
          type: "error",
          bottomText: "Thử lại",
          onBottomClick: () => {
            close();
          },
        });
      }
      return false;
    }
    const isVideoAllowSize =
      +(process.env["NEXT_PUBLIC_MAX_UPLOADED_VIDEO_SIZE"] ?? "20") *
        1024 *
        1024 >=
      file.size;
    if (!isVideoAllowSize) {
      if (isShowDialogError) {
        const { close } = AlertModal({
          title: "Upload thất bại!",
          description: `Dung lượng video tối đa ${
            process.env["NEXT_PUBLIC_MAX_UPLOADED_VIDEO_SIZE"] ?? "2"
          }MB. Vui lòng thử lại.`,
          type: "error",
          bottomText: "Thử lại",
          onBottomClick: () => {
            close();
          },
        });
      }
      return false;
    }
    return true;
  }
  if (isShowDialogError) {
    const { close } = AlertModal({
      title: "Upload thất bại!",
      description: "File upload không được hỗ trợ. Vui lòng sử dụng file khác!",
      type: "error",
      bottomText: "Thử lại",
      onBottomClick: () => {
        close();
      },
    });
  }
  return false;
};
