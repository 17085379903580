import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { recaptchaVerifierInvisible } from "lib/firebase";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import get from "lodash/get";
import { formatPhoneNumber, PHONE_REGEX } from "lib/common";
import Input from "components/input";
import Button from "components/button";
import CountdownButton, { CountState } from "components/countdown-button";
import AlertModal from "components/Dialog/AlertModal";
import {
  sendOTP,
  verifyOTP,
  userCheckPhoneExists,
  userPhoneLogin,
  verifyCode,
} from "features/user/api";

/* eslint-disable */
interface Props {
  onSuccess: (I: RegisterValues) => void;
  defaultValue?: Partial<RegisterValues>;
}

type RegisterValues = {
  phone: string;
  otp: string;
};

const RegisterSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(PHONE_REGEX, "Vui lòng nhập số điện thoại")
    .required("Vui lòng điền số điện thoại của bạn"),
  otp: yup
    .string()
    .min(6, "Mã OTP gồm 6 ký tự")
    .max(6, "Mã OTP gồm 6 ký tự")
    .required("Vui lòng điền OTP"),
});

const RegisterStepOneForm: React.FC<Props> = ({
  onSuccess,
  defaultValue = {},
}) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<RegisterValues>({
    mode: "onSubmit",
    resolver: yupResolver(RegisterSchema),
  });
  const watchPhone = watch("phone", ""); // you can supply default value as second argument
  const watchOtp = watch("otp", "");
  // clearErrors
  React.useEffect(() => {
    clearErrors();
  }, [watchPhone]);

  const [countState, setCountState] = React.useState<CountState>(
    CountState.INIT
  );
  const [phoneExist, setPhoneExist] = React.useState(new Set());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const onSubmit: SubmitHandler<RegisterValues> = (data) => {
    setIsLoading(true);
    verifyOTP(watchPhone, "REGISTER", watchOtp)
      .then((res) => {
        console.log("Verify Code Success", res);
        setIsLoading(false);
        onSuccess(data);
      })
      .catch((err) => {
        console.log("Verify Code Error: ", err);
        setError("otp", {
          type: "manual",
          message: "Mã OTP không chính xác!",
        });
        setIsLoading(false);
      });
  };

  const onChangeCountState = (state: CountState) => {
    setCountState(state);
  };

  const handleClickSendOtp = () => {
    if (PHONE_REGEX.test(watchPhone)) {
      setCountState(CountState.START);
      const phoneNumber = formatPhoneNumber(watchPhone);
      (async () => {
        try {
          const { is_existed: isExists, is_verified: isVerified } =
            await userCheckPhoneExists(phoneNumber);
          if (isExists && isVerified) {
            setCountState(CountState.RELOAD);
            setPhoneExist(phoneExist.add(watchPhone));
            const { close } = AlertModal({
              title: "Tài khoản đã tồn tại!",
              description:
                "Tài khoản sử dụng số điện thoại trên đã tồn tại.\n" +
                "Vui lòng sử dụng số điện thoại khác để đăng ký.",
              type: "error",
              bottomText: "Quay lại",
              onBottomClick: () => {
                close();
              },
            });
          } else {
            await sendOTP(phoneNumber, "REGISTER");
          }
        } catch (err) {
          //TODO: handle send sms erorr
          console.dir(err);
          setCountState(CountState.RELOAD);
          setError("otp", {
            type: "manual",
            message: "Có lỗi xảy ra. Vui lòng thử lại sau.",
          });
        }
      })();
    } else {
      setError("phone", {
        type: "manual",
        message: "Vui lòng nhập đúng định dạng số điện thoại",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="phone"
        control={control}
        defaultValue={defaultValue.phone || ""}
        render={({ field: { ref, ...others } }) => (
          <Input
            label="Số điện thoại"
            className="w-full mb-3"
            errorMsg={get(errors, "phone.message", "")}
            {...others}
          />
        )}
      />
      <Controller
        name="otp"
        control={control}
        defaultValue=""
        render={({ field: { ref, ...others } }) => (
          <Input
            label="OTP"
            className="w-full col-span-2"
            errorMsg={get(errors, "otp.message", "")}
            endAdornment={
              <div className="w-52">
                <CountdownButton
                  id="btn-submit-otp"
                  infType="tertiary"
                  countFrom={120}
                  completeText="Gửi lại mã"
                  countState={countState}
                  disabled={phoneExist.has(watchPhone)}
                  onChangeCountState={onChangeCountState}
                  onClick={handleClickSendOtp}
                >
                  Gửi mã
                </CountdownButton>
              </div>
            }
            {...others}
          />
        )}
      />
      <div className="mb-2 mt-4">
        <Button type="submit" loading={isLoading}>
          Tiếp tục
        </Button>
      </div>
    </form>
  );
};

export default RegisterStepOneForm;
