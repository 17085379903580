import React from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import HomeIcon from "components/icons/colored/Home";
import HomeActiveIcon from "components/icons/colored/HomeActive";
import CategoryIcon from "components/icons/colored/Category";
import CategoryActiveIcon from "components/icons/colored/CategoryActive";
import ChatIcon from "components/icons/colored/Chat";
import ChatActiveIcon from "components/icons/colored/ChatActive";
import ProfileIcon from "components/icons/colored/Profile";
import ProfileActiveIcon from "components/icons/colored/ProfileActive";
import MenuIcon from "components/icons/colored/Menu";
import MenuActiveIcon from "components/icons/colored/MenuActive";

type NavItem = {
  icon: string;
  label: string;
  path: string;
};

interface MenuIconProps {
  type: string;
  isActive: boolean;
}

const Icon: React.FC<MenuIconProps> = ({ type, isActive }) => {
  if (!isActive) {
    switch (type) {
      case "home":
        return <HomeIcon />;
      case "category":
        return <CategoryIcon />;
      case "chat":
        return <ChatIcon />;
      case "profile":
        return <ProfileIcon />;
      case "menu":
        return <MenuIcon />;
      default:
        return null;
    }
  }
  switch (type) {
    case "home":
      return <HomeActiveIcon />;
    case "category":
      return <CategoryActiveIcon />;
    case "chat":
      return <ChatActiveIcon />;
    case "profile":
      return <ProfileActiveIcon />;
    case "menu":
      return <MenuActiveIcon />;
    default:
      return null;
  }
};

interface Props {
  navItems: NavItem[];
  activePath: string;
  onNavClick: (I: string) => void;
}

const calcActivePath = (currentRouter: string, itemPath: string) => {
  if (itemPath === "account") {
    if (currentRouter === "/m/quan-ly") return true;
    if (currentRouter === "/m/dang-nhap") return true;
    return false;
  }
  if (itemPath === currentRouter) return true;
  return false;
};

const BottomMenu: React.FC<Props> = ({ navItems, activePath, onNavClick }) => {
  return (
    <div className="flex w-full justify-between">
      {navItems.map((i) => {
        const isActive = calcActivePath(activePath, i.path);
        return (
          <div
            key={i.path}
            className="flex flex-col items-center w-16"
            onClick={onNavClick.bind(null, i.path)}
          >
            <Icon type={i.icon} isActive={isActive} />
            <span
              className={classnames("text-xs", isActive ? "" : "text-grey-80")}
            >
              {i.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default BottomMenu;
